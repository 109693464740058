import IndexHtml from "static/code_pen_replica/index";
import BasicCss from "static/code_pen_replica/styles/basic";
import ButtonsCss from "static/code_pen_replica/styles/buttons";
import LandingCss from "static/code_pen_replica/styles/landing-pg";
import LandingMobileCss from "static/code_pen_replica/styles/landing-mobile";
import MQ975Css from "static/code_pen_replica/styles/media-queries-975";
import MQ1090Css from "static/code_pen_replica/styles/media-queries-1090";

const routes = [
    {
            name: "HTML",
            collapse: [
              {
                name: "about.html",
                route: "/about.html",
                component: IndexHtml,
              },
             
            ],
        },
        {
            name: "CSS",
            collapse: [
                {
                  name: "basic.css",
                  route: "/basic.css",
                  component: BasicCss,
                },
              {
                name: "buttons.css",
                route: "/buttons.css",
                component: ButtonsCss,
              },
              {
                name: "landing page.css",
                route: "/landing-pg.css",
                component: LandingCss,
              },
              {
                name: "landing mobile.css",
                route: "/landing-mobileg.css",
                component: LandingMobileCss,
              },
              {
                name: "media queries_975.css",
                route: "/media-queries-975.css",
                component: MQ975Css,
              },
              {
                name: "media queries_1090.css",
                route: "/media-queries-1090.css",
                component: MQ1090Css,
              },
            ],
        },

        

]

export default routes;
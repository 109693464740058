const indexHtml = `<!DOCTYPE html>
<html lang="en">
<head>
    <meta charset="UTF-8">
    <meta http-equiv="X-UA-Compatible" content="IE=edge">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <link rel="preconnect" href="https://fonts.googleapis.com">
    <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
    <link href="https://fonts.googleapis.com/css2?family=Architects+Daughter&family=Caveat:wght@400;500;600;700&family=Chakra+Petch:ital,wght@0,300;0,400;1,300;1,400&family=Cormorant+Infant:wght@300;400;500&family=Grandstander:ital,wght@0,100;0,200;0,300;0,400;1,100;1,200;1,300&family=Handlee&family=Oregano:ital@0;1&family=Rajdhani:wght@300;400;500;600;700&family=Stint+Ultra+Condensed&display=swap" rel="stylesheet">
    <title>Kool Runnings Resturant - JavaScript Menu</title>
    <script src="./index.js"></script>
</head>
<body>
    
</body>

<script>
JS_Menu.autoMenu();
</script>
</html>`

export default indexHtml;
// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

function Information(item) {
  const post = item.item;
  // console.log("INFORMATION POST ---> ", post);
  const contentArr = post.content_array;
  const content = []
  contentArr.forEach(paragraph => {
    let firstW = paragraph.split(" ")[0];
    let oneline = <br/>
    let twolines = <><br/> <br/></>;

    // console.log("First WORD in PARAGRAPH ===== ", firstW);
    if(firstW === "Subtitle:"){
      content.push(
        <>
        
        <MKTypography
            component="h5"
            // variant="button"
            opacity={0.7}
            textTransform="capitalize"
            fontWeight="bold"
          >{paragraph.replace("Subtitle: ", "") }
          </MKTypography>
        {oneline}
        </>
      )
    }else{
      if(firstW === "*Bullet:"){
        content.push(
          <>
            <li style={{marginLeft:"3px", marginTop:"-20px"}}>
              {paragraph.replace("*Bullet: ", "      ") }
            </li>
          {oneline}
          </>
        )
      }else{
        if(firstW === "*Bullet:*Bullet:"){
          content.push(
            <>
            <li style={{marginLeft:"30px", marginTop:"-20px"}}>
              {paragraph.replace("*Bullet:*Bullet: ", "        ") }
            </li>
            
            {oneline}
            </>
          )
        }else{
          if(firstW === "*Number:"){
            content.push(
              <>
              <li>{paragraph.replace("*Number: ", "     ") }</li>
              {oneline}
              </>
            )
          }else{
            content.push(
              <>
              {paragraph}
              {twolines}
              </>
            )
          }
        }        
      }
    }
  })
  return (
    <MKBox component="section" py={6} mt={6}>
      <Container>
        <Grid container spacing={3} item xs={12} lg={8} mx="auto">
          <MKTypography variant="h3" mb={3}>
            {post.title}
          </MKTypography>
          <MKTypography
            component="h6"
            variant="button"
            opacity={0.7}
            textTransform="capitalize"
            fontWeight="bold"
          >
            Steven Anyansi 
            <br/>
            {post.date_created}
          </MKTypography>
          <br/><br/>
          <MKTypography variant="body2">
            {content}
          </MKTypography>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Information;

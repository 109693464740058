import IndexHtml from "static/css_grid/index";

const routes = [
    {
            name: "HTML",
            collapse: [
              {
                name: "index.html",
                route: "/index.html",
                component: IndexHtml,
              },
             
            ],
        },

        

]

export default routes;
const Menu = `<!DOCTYPE html>
<html lang="en">
<head>
    <meta charset="UTF-8">
    <meta http-equiv="X-UA-Compatible" content="IE=edge">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <script 
    src="https://kit.fontawesome.com/a6874c76aa.js" 
    crossorigin="anonymous"></script>
    <link rel="preconnect" href="https://fonts.googleapis.com">
    <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
    <link href="https://fonts.googleapis.com/css2?family=Roboto+Condensed:wght@300&display=swap" rel="stylesheet">
    <link href="https://fonts.googleapis.com/css2?family=Ubuntu+Condensed&display=swap" rel="stylesheet">
    <link href="https://fonts.googleapis.com/css2?family=Roboto+Slab&display=swap" rel="stylesheet">

    <link rel="stylesheet" href="./styles/common.css"/>
    <link rel="stylesheet" href="./styles/nav.css"/>
    <link rel="stylesheet" href="./styles/footer.css"/>
    <link rel="stylesheet" href="./styles/skewed-header.css"/>
    <link rel="stylesheet" href="./styles/square-grid.css"/>
    <link rel="stylesheet" href="./styles/helpers.css"/>
    <link rel="stylesheet" href="./styles/media-queries.css"/>
    
    <title>Menu</title>
</head>
<body>
<div class="skewed-header">
    <div class="header-bg" style="background-image: url('/images/backgrounds/menu.jpg');"></div>

    <div class="skewed-header-wrapper">
        <div class="skewed-header-content">
            <div class="heading-wrapper">
                <h1>Menu</h1>
            </div>

            <div class="links-wrapper">
                <div class="nav-link">
                    <a href="index.html">Home</a> 
                </div>
                <div class="nav-link">
                    <a href="about.html">About</a>
                </div>
                <div class="nav-link">
                    <a href="menu.html">Menu</a>
                </div>
                <div class="nav-link">
                    <a href="contact.html">Contact</a>  
                </div>                 
            </div>

        </div>
    </div>
</div>

<div class="squares-wrapper">
    <div class="squares">
        <div class="square">
            <div class="img-wrapper">
                <img src="./images/squares/fries-sq-1.jpg" alt="Fries WON">
            </div>
            <div class="square-text-wrapper">
                <a href="#spicy" name="spicy" class="anchor-link">
                    <h1>Spicy</h1>
                </a>
                
                <ul>
                    <li>Lorem ipsum dolor sit amet, consectetur adipisicing elit.</li>
                    <li>Eveniet vero tenetur hic itaque velit veniam accusamus?</li>
                    <li>Similique temporibus non, unde veritatis recusandae tempora minima.</li>
                    <li>Amet repudiandae distinctio, voluptatem nobis ullam enim incidunt.</li>
                </ul>
            </div>
        </div>
        <div class="square">
            <div class="square-text-wrapper">                
                <a href="#medium" name="medium" class="anchor-link">
                    <h1>Medium</h1>
                </a>
                
                <ol>
                    <li>Lorem ipsum dolor sit amet, consectetur adipisicing elit.</li>
                    <li>Eveniet vero tenetur hic itaque velit veniam accusamus?</li>
                    <li>Similique temporibus non, unde veritatis recusandae tempora minima.</li>
                    <li>Amet repudiandae distinctio, voluptatem nobis ullam enim incidunt.</li>
                </ol>
            </div>
            <div class="img-wrapper">
                <img src="./images/squares/fries-sq-2.jpg" alt="Fries TOO">
            </div>
        </div>
        <div class="square">
            <div class="img-wrapper">
                <img src="./images/squares/fries-sq-1.jpg" alt="Fries WON">
            </div>
            <div class="square-text-wrapper">                             
                <a href="#mild" name="mild" class="anchor-link">
                    <h1>Mild</h1>
                </a>
                
                <ul>
                    <li>Lorem ipsum dolor sit amet, consectetur adipisicing elit.</li>
                    <li>Eveniet vero tenetur hic itaque velit veniam accusamus?</li>
                    <li>Similique temporibus non, unde veritatis recusandae tempora minima.</li>
                    <li>Amet repudiandae distinctio, voluptatem nobis ullam enim incidunt.</li>
                </ul>
            </div>
        </div>
        <div class="square">
            <div class="square-text-wrapper">
                             
                <a href="#no-flavor" name="no-flavor" class="anchor-link">
                    <h1>No Flavor</h1>
                </a>
                
                <ul>
                    <li>Lorem ipsum dolor sit amet, consectetur adipisicing elit.</li>
                    <li>Eveniet vero tenetur hic itaque velit veniam accusamus?</li>
                    <li>Similique temporibus non, unde veritatis recusandae tempora minima.</li>
                    <li>Amet repudiandae distinctio, voluptatem nobis ullam enim incidunt.</li>
                </ul>
            </div>
            <div class="img-wrapper">
                <img src="./images/squares/fries-sq-2.jpg" alt="Fries TOO">
            </div>
        </div>
    </div>
</div>
<div class="footer">
    <div class="logo-footer">
        <img src="images/logos/decamp-fantastic-fries-logo-white.png" alt="Logo">
    </div>
    <div class="footer-phone-hours">
        <span class="phone">
            555 555 5555
        </span>
        <span class="hours">
            10 am - midnight
        </span>
    </div>
    <div class="links-wrapper">
        <div class="nav-link">
            <a href="index.html">Home</a> 
        </div>
        <div class="nav-link">
            <a href="about.html">About</a>
        </div>
        <div class="nav-link">
            <a href="menu.html">Menu</a>
        </div>
        <div class="nav-link">
            <a href="contact.html">Contact</a>  
        </div>
    </div>
    <div class="social-media-icons-wrapper">
        <a href="#">
            <i class="fa-brands fa-instagram"></i>
        </a>
        <a href="#">
            <i class="fa-brands fa-twitter"></i>
        </a>
        <a href="#">
            <i class="fa-brands fa-facebook"></i>
        </a>
    </div>
    <div class="copyright-wrapper">
        &copy; 2022 DevCamp &#124; All rights reserved
    </div>
</div>
</body>
</html>`

export default Menu;
const ErrorPg = `<!DOCTYPE html>
<html lang="en">
<head>
    <meta charset="UTF-8">
    <meta http-equiv="X-UA-Compatible" content="IE=edge">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>Error Page</title>
    <link rel="preconnect" href="https://fonts.googleapis.com">
    <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
    <link href="https://fonts.googleapis.com/css2?family=Comic+Neue:ital,wght@0,700;1,400&family=Lato:wght@300;400;700;900&display=swap" rel="stylesheet">
    <style>
        body {
            background-color: lightgoldenrodyellow;
            font-family: 'Comic Neue', cursive;
            font-weight: 700;
            color: rgb(24, 46, 116);
        }
        .error_wrapper {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 100vh;
            flex-direction: column;
        }
        .error_wrapper > .error_heading {
            margin-bottom: 42px;

        }
        img {
            width: 50%;
        }

    </style>
</head>
<body>
    <div class="error_wrapper">
        <div class="error_heading">
            <h2>OOHooooo ...... Who knows when that page will be available.. Go try another page!</h2>
        </div>
        <img src="./images/tbot.png" alt="error_image">
    </div>    
</body>
</html>`

export default ErrorPg;
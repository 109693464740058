const common = `body {
    margin: 0px;
    font-family: 'Roboto Condensed', sans-serif;
}

h1 h2 h3 h4 h5 h6 {
    font-family: 'Roboto Slab', serif;

}`

export default common;


import { useRef, useState } from "react";

// SwiperJS
import SwiperCore, { Autoplay, Navigation } from "swiper";

// SwiperJS react components
import { Swiper, SwiperSlide } from "swiper/react";

// SwiperJS styles
import "swiper/swiper.min.css";
import "swiper/css/navigation";

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

import fsdpImage from "assets/awards/bottega_fsdp.png";
import clImage from "assets/awards/chancellors_list.png";
import itfImage from "assets/awards/it_fundamentals.png";


import colors from "assets/theme/base/colors";
const { dark } = colors;

function Steps() {
  // install SwiperJS modules
  SwiperCore.use([Autoplay, Navigation]);

  // SwiperJS navigation buttons ref
  const navigationPrevRef = useRef(null);
  const navigationNextRef = useRef(null);

  const [swiperEl, setSwiperEl] = useState(null);

  const slideTo = (index) => swiperEl && swiperEl.slideTo(index);

  const slides = [
    {
      image: itfImage,
      label: "Awarded",
      title: "IT Fundamentals Micro-credential",
      description: 
      `The IT fundamentals micro-credential provides you with the foundational skills 
      necessary to enter the IT workforce in a support role. 
      The credential prepares you with essential desktop, website, 
      and database software support skills.`,
    },
    {
      image: fsdpImage,
      label: "Awarded",
      title: "Full Stack Development Certificate",
      description: 
      `The Full Stack Development Certificate program focuses on 
      the use of front-end libraries or frameworks, programming website 
      backend content management, managing deployment and installation on servers, 
      and running SQL queries on databases.`,
    },
    {
      image: clImage,
      label: "Awarded",
      title: "Chancellor's List",
      description: 
      `Academic achievement for successfully earning a grade point average of 4.0 
      at Purdue University Global`,
    },
  ];

  const steps = [
    {
      number: "1",
      label: "Fundamentals",
    },
    {
      number: "2",
      label: "Full Stack",
    },
    {
      number: "3",
      label: "Distinction",
    },
  ];

  // Swiper navigation buttons styles
  const navigationStyles = {
    opacity: 0.5,
    cursor: "pointer",
    transition: "opacity 0.15s ease",
    color: dark.main,

    "&:hover, &:focus": {
      opacity: 1,
    },
  };
  const stepStyles = { 
    fontFamily: ({ typography: { h1 } }) => h1.fontFamily, 
    cursor: "pointer",
    color: "rgb(95,15,200)",
    opacity: 1,
    // border: "1px black solid",
    
    "&:hover, &:focus": {
      opacity: 0.5,
    },
  }

  return (
    <MKBox component="section" py={12} position="relative">
      <Container>
        <Swiper
          onInit={(swiper) => {
            setSwiperEl(swiper);

            const { navigation: nav } = swiper.params;
            const { navigation } = swiper;

            nav.prevEl = navigationPrevRef.current;
            nav.nextEl = navigationNextRef.current;
            navigation.init();
            navigation.update();
          }}
          autoplay={{ delay: 5000 }}
          speed={800}
          spaceBetween={0}
          slidesPerView={1}
          allowTouchMove={false}
          loop
        >
          {slides.map(({ image, label, title, description }, index) => (
            <SwiperSlide key={index}>
              <Grid container spacing={3} alignItems="center" sx={{ mb: { xs: 6, md: 0 } }}>
                <Grid item xs={12} md={5} ml={{ xs: 0, lg: "auto" }}>
                  <MKBox p={2}>
                    <MKBox
                      component="img"
                      src={image}
                      alt={title}
                      width="100%"
                      borderRadius="xl"
                      maxHeight="37.5rem"
                    />
                  </MKBox>
                </Grid>
                <Grid item xs={12} md={5} mr={{ xs: 0, lg: "auto" }} position="relative">
                  <MKTypography
                    component="h6"
                    variant="button"
                    opacity={0.7}
                    textTransform="uppercase"
                    fontWeight="bold"
                  >
                    {label}
                  </MKTypography>
                  <MKTypography
                    variant="h1"
                    fontWeight="bold"
                    sx={{
                      fontSize: ({ typography: { d3, d4 } }) => ({
                        xs: d4.fontSize,
                        lg: d3.fontSize,
                      }),
                    }}
                  >
                    {title}
                  </MKTypography>
                  <MKTypography variant="body1" my={3}>
                    {description}
                  </MKTypography>
                </Grid>
              </Grid>
            </SwiperSlide>
          ))}
          <MKBox
            display="flex"
            position="absolute"
            bottom={{ xs: 0, lg: "10%" }}
            right="5%"
            zIndex={2}
          >
            <MKTypography
              variant="h2"
              ref={navigationPrevRef}
              color="dark"
              mr={{ xs: 8, md: 2, lg: 8 }}
              sx={navigationStyles}>
              <MKBox className="fas fa-chevron-left" />
            </MKTypography>
            <MKTypography 
              variant="h2" 
              ref={navigationNextRef} 
              color="dark" 
              sx={navigationStyles}>
              <MKBox className="fas fa-chevron-right" />
            </MKTypography>
          </MKBox>
        </Swiper>
            <Grid container mt={0} >
              {steps.map(({ label }, index) => (
                <Grid key={index} mt={2} container item xs={12} md={4} lg={4} textAlign="center" justifyContent={"center"}>
                  <MKTypography
                    variant="body1"
                    color="info"
                    fontWeight="bold"
                    pl={2}
                    textGradient
                    sx={stepStyles}
                    onClick={() => {
                      console.log("STEPS INDEX = ----> ", index);
                      slideTo(index)
                    }}
                  >
                    <MKBox component="span">{}</MKBox>
                    <MKBox component="span" ml={1}>
                      {label}
                    </MKBox>
                  </MKTypography>
                </Grid>
              ))}
            </Grid>
      </Container>
    </MKBox>
  );
}

export default Steps;

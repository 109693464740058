import { useEffect } from "react";

import { Routes, Route, Navigate, useLocation } from "react-router-dom";

// @mui material components
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";

// Material Kit 2 React themes
import theme from "assets/theme";
import HomePage from "layouts/pages/home";
import BlogPost from "layouts/pages/myblog";
import CodeView from "layouts/pages/projectview";

// Material Kit 2 PRO React routes
import routes from "routes";


export default function App() {
  const { pathname } = useLocation();

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  const getRoutes = (allRoutes) =>
    allRoutes.map((route) => {
      if (route.collapse) {
        return getRoutes(route.collapse);
      }

      if (route.route) {
        return <Route 
                  exact 
                  path={route.route} 
                  element={route.component} 
                  key={route.key} 
                  />;
      }

      return null;
    });

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Routes>
        {getRoutes(routes)}
        <Route path="/home" element={<HomePage/>} />
        <Route path="*" element={<Navigate to="/home" />}/>
        <Route path="/blog-post" element={<BlogPost/>} />
        <Route path="/project/view" element={<CodeView/>} />
      </Routes>
    </ThemeProvider>
  )
}
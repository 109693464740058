import { useEffect, useRef, useState } from "react";

// rellax
import Rellax from "rellax";

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import Footer from "components/Footer";

// Material Kit 2 PRO React examples
import DefaultNavbar from "examples/Navbars/DefaultNavbar";

// About Us page sections
import Information from "pages/MyBlogs/blogpost/sections/Information";
// import Posts from "pages/MyBlogs/blogpost/sections/Posts";

// Routes
import routes from "routes";

// Images
import Bvd1 from "assets/blogs/Breadth_vs_Depth01.jpeg";
import Bvd2 from "assets/blogs/Breadth_vs_Depth02.jpeg";
import Bvd3 from "assets/blogs/Breadth_vs_Depth03.jpeg";
import Bvd4 from "assets/blogs/Breadth_vs_Depth04.jpeg";
// import bgImage from "assets/images/bg5.jpg";

import {BFS_vs_DFS} from "pages/MyBlogs/blogPosts/mock/content";
import Breadcrumbs from "examples/Breadcrumbs";

function BlogPost() {
  const headerRef = useRef(null);

  const [blogPost, setBlogPost] = useState({
    "active": true,
        "blog_status": "published",
        "category": ["Software Development"], 
        "content": BFS_vs_DFS,
        "content_array": [],
        "date_created": "01/05/2024",
        "description": "When it comes to searching through data structures like trees and graphs, two primary techniques often come into play: Breadth-First Search (BFS) and Depth-First Search (DFS). Understanding the differences between these two methods is crucial for selecting the right approach based on the context of your problem. Let’s dive into what each technique entails, when to use them, and explore additional search strategies that might enhance your search capabilities.",
        "featured_image": Bvd1,
        "id": "01",
        "images": [Bvd1,Bvd2,Bvd3,Bvd4],
        "title": "Understanding Breadth vs. Depth in Search Techniques",
  })

  // Setting up rellax
  useEffect(() => {
    const parallax = new Rellax(headerRef.current, {
      speed: -6,
    });

    return () => parallax.destroy();
  }, []);

  let getSelectedPost = localStorage.getItem("selected_blog");
  useEffect(() => {
    getBlog();
  }, [getSelectedPost]);

  useEffect(() => {
    console.log("BLOG RETRIVED -----> ", blogPost);
  }, [blogPost]);

  const getBlog = () => {
    if(getSelectedPost === undefined || getSelectedPost === null){
      console.log("BLOG NOT FOUND!...... Retrying");
      // getBlog();
    }else{
      let selectedPost = JSON.parse(getSelectedPost); 
      console.log("POST FOUND...... Title: ", selectedPost.title);
      setBlogPost(selectedPost)
    }

  }

  let crumbs = 
  [
    { label: "Blog", route: "/blog-posts" },
    { label: "Post" },
  ]

  return (
    <>
      <MKBox bgColor="white" py={0.25}>
        <DefaultNavbar
          routes={routes}
          transparent
          relative
          brand={"SJA"}
        />
      </MKBox>
      <MKBox
        ref={headerRef}
        minHeight="85vh"
        width="100%"
        sx={{
          backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
            `${linearGradient(
              rgba(gradients.dark.main, 0.8),
              rgba(gradients.dark.state, 0.8)
            )}, url(${blogPost.featured_image})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          display: "grid",
          placeItems: "center",
        }}
      >
        <Container>
          <Grid container item xs={12} lg={7} justifyContent="center" flexDirection="column">
            <MKTypography
              variant="h1"
              color="white"
              sx={({ breakpoints, typography: { size } }) => ({
                [breakpoints.down("md")]: {
                  fontSize: size["3xl"],
                },
              })}
            >
             {blogPost.title}
            </MKTypography>
            <MKTypography
              variant="body1"
              color="white"
              opacity={0.8}
              mb={2}
              mr={{ xs: 0, sm: 6 }}
              pr={{ xs: 0, sm: 6 }}
            >
              {blogPost.date_created}
            </MKTypography>
            <MKTypography variant="h5" color="white" mt={2} mb={1}>
              
            </MKTypography>
            <MKBox display="flex" alignItems="center">
              <MKTypography component="a" variant="body1" color="white" href="#" mr={3}>
                <i className="fab fa-facebook" />
              </MKTypography>
              <MKTypography component="a" variant="body1" color="white" href="#" mr={3}>
                <i className="fab fa-instagram" />
              </MKTypography>
              <MKTypography component="a" variant="body1" color="white" href="#" mr={3}>
                <i className="fab fa-twitter" />
              </MKTypography>
              <MKTypography component="a" variant="body1" color="white" href="#">
                <i className="fab fa-google-plus" />
              </MKTypography>
            </MKBox>
          </Grid>
        </Container>
      </MKBox>
      <Card
        sx={{
          p: 2,
          mx: { xs: 2, lg: 3 },
          mt: -8,
          mb: 4,
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
        }}
      >
        <Breadcrumbs routes={crumbs} />
        <Information item={blogPost}/>
        {/* <Posts /> */}
      </Card>
      <MKBox pt={6} px={1} mt={6}>
        <Footer />
      </MKBox>
    </>
  );
}

export default BlogPost;

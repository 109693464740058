    const MQMenu = `.container > .nav-bar-mobile-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-direction: column;
        /* height: 275px; */
        height: 50vh;
        border: 1px solid transparent;
        border-bottom-right-radius: 15px;
        border-bottom-left-radius: 15px;
        margin: -5px -7px;
        background-color: rgb(6, 14, 106, 0.9);
    }
        
        .container > .nav-bar-mobile-container .mobile-nav-button-container {
            display: flex;
            flex: 0.4;
            justify-content: flex-start;
            align-items: center;
            align-self: flex-start;
            /* order: 2; */
            opacity: 1;
            visibility: visible;
            /* border: 1px solid rgba(47, 232, 56, 0.482); */
            padding-left: 20px;
        }

        /* .container > .nav-bar-container .mobile-nav-button-container i:hover {
            cursor: pointer;
        } */

        .container > .nav-bar-mobile-container .logo-container {
            margin-top: 20px;
            display: flex;
            flex: 0.2;
            justify-content: center;
            align-items: center;
            align-self: flex-end;
            padding-right: 10px;        
            width:fit-content;
            position: absolute;
            /* order: 1; */
            /* border: 1px solid rgba(132, 52, 52, 0.482); */
        }
        .container > .nav-bar-mobile-container > .logo-container > .logo  {
            cursor: pointer;
            flex: 0;
            font-family: 'Rubik Maze', cursive;
            /* border: 1px solid rgba(132, 52, 52, 0.482); */
        
        }

        .container > .nav-bar-mobile-container .logo-container > .logo:hover {
            cursor: pointer;
            opacity: 0.8;
        }

        .container > .nav-bar-mobile-container .links-container {
            display: flex;
            flex-direction: column;
            flex: 1;
            /* order: 3; */
            justify-content: space-between;
            /* height: 75px; */
            /* display: none; */
            /* opacity: 0; */
            /* visibility: hidden; */
            width: 100%;
            /* border: 1px solid rgba(47, 232, 56, 0.482); */
        }
        .container > .nav-bar-mobile-container .links-container > .link-a-container {
            display: flex;
            flex-direction: column;
            margin-bottom: 10px;
            /* flex: 1; */
        }
        .container > .nav-bar-mobile-container .links-container > .link-b-container {
            display: flex;
            flex-direction: column;
            margin-top: 10px;
        }


        .link a {
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            text-decoration: none;
            color: aliceblue;       
            /* font-family: 'Akronim', cursive;   */
            font-family: 'Comic Neue', cursive;
            font-size: 20px;
            /* border: 1px solid rgb(123, 23, 181);    */
            margin-bottom: 10px;
        }
        .container > .nav-bar-mobile-container .links-container > .link-a-container a i {
            font-size: 14px;
        }

        .container > .nav-bar-mobile-container .links-container > .link-b-container a {
            font-size: 15px;            
        }`

        export default MQMenu;


      
import React, { Component } from 'react'
// import {connect, useSelector} from "react-redux";

import PropTypes from 'prop-types';


import Card from "@mui/material/Card";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Material Kit 2 PRO React examples
import DefaultNavbar from "examples/Navbars/DefaultNavbar";


// Author page sections
import Footer from "components/Footer";


// Routes
import routes from "routes";

import DefaultBlogCard from "examples/Cards/PrototypeCards/index";

// Images
// import image from "assets/images/annie-spratt.jpg";
import author from "assets/about/small.jpg";


// API 
import {ProjectsArr} from 'pages/Projects/load';


 function Projects(propsObj) {
  const { projects } = propsObj.propsObj
      // console.log("RETURN PROPS Function ... ---> ", projects);
  
  return projects.map((v) => {
      //  console.warn(` Index : ${i} - Total : ${a.length}`)
       const {name, details, image, url, categories, year} = v;
      //  console.log("ID: ", id)
       let language_code = categories.join(", ")
      return (
        <>
           <Card
              sx={{
                p: 2,
                mx: { xs: 2, lg: 3 },
                mt: -8,
                mb: 4,
                backgroundColor: ({ palette: { white }, functions: { rgba } }) => rgba(white.main, 0.8),
                backdropFilter: "saturate(200%) blur(30px)",
                boxShadow: ({ boxShadows: { xxl } }) => xxl,
              }}
            >
          
          <MKBox pt={6} pb={3} px={3}>
            <Grid container item xs={12} lg={9} sx={{ mx: "auto", px: { xs: 0, lg: 6 } }}>
              <DefaultBlogCard
                image={image}
                category={{ color: "warning", label: language_code}}
                title={name}
                description={details}
                author={{
                  image: author,
                  name: "Steven Anyansi",
                  date: `${url}`,
                }}
                action={{
                  type: "internal",
                  route: "/project/view",
                }}
                raised={false}
                year={year}
                item={v}
              />
            </Grid>
          </MKBox>
            </Card>
        </>
      ); 
  })
}


function ProjectsPage(propsObj) {
  return (
    <>
      <DefaultNavbar
        routes={routes}
        transparent
        light
        brand={"SJA"}
      />
      <MKBox bgColor="white">
        <MKBox
          minHeight="25rem"
          width="100%"
          sx={{
            backgroundColor: "lightgray",
            backgroundSize: "cover",
            backgroundPosition: "center",
            display: "grid",
            placeItems: "center",
          }}
        >
        <Container>
          <Grid container item xs={12} lg={7} justifyContent="center" flexDirection="column">
            <MKTypography
              variant="h1"
              color="white"
              sx={({ breakpoints, typography: { size } }) => ({
                [breakpoints.down("md")]: {
                  fontSize: size["3xl"],
                },
              })}
            >
              Prototypes
            </MKTypography>
            <MKTypography
              variant="body1"
              color="white"
              opacity={0.8}
              mb={2}
              mr={{ xs: 0, sm: 6 }}
              pr={{ xs: 0, sm: 6 }}
            >
              
            </MKTypography>
            <MKTypography 
              variant="h5" color="white" mt={2} mb={1} 
            //   sx={contactmeBtnStyles}
              >
              Demo Projects
            </MKTypography>
          </Grid>
        </Container>
            </MKBox>
        <Projects propsObj={propsObj.propsObj}/>
        <Footer />
      </MKBox>
    </>
  ); 
}


class ProjectsClass extends Component {

  constructor (props) {
    super(props)
    this.state = {
      pList: [],
      projects: [],
    }
  }

  async componentDidMount () {
    this.getProjects()
    
  }

  componentDidUpdate () {
    let projects = this.state.projects;
    // console.log("UPDATED COMPONENT COUNT : ", projects.length);
    if(projects.length === 0){
      this.getProjects()
    }
  }

  async getProjects() {
    let protoList = await ProjectsArr();
    // console.log("PROJECT LIST LOADED ----> ", protoList);
    function sortList(a,b) {
      return b.year - a.year;
    }
    let projects = protoList.sort(sortList);
    this.setState({projects:projects})
    localStorage.setItem("selected_code", "null");
  }
  

   render() {
    // console.log("PROJECT State ----> ", this.state.projects);

    return (
      <ProjectsPage propsObj = {{projects:this.state.projects}}/>
    )
  }
}

Projects.propTypes = {
  projects: PropTypes.array,
  getProjects: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.element,
    PropTypes.elementType,
  ]),
}



export default ProjectsClass;
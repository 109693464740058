
import { getStaticSitesCache } from "api/firebase-api";

export const ProjectsArr = async () => {
    
    let ProjectsList = [];
    let getSites = await getStaticSitesCache();
    getSites.forEach((site) => {
        ProjectsList.push(site);
    })
    return ProjectsList;

}


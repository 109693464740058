const index = `<!DOCTYPE html>
<html lang="en">
<head>
    <meta charset="UTF-8">
    <meta http-equiv="X-UA-Compatible" content="IE=edge">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>Time - Click Event Listener</title>
</head>
<body>
    <div id="dateComponent"></div>  
    <button id="dateBtn">Listen For Date</button>  
    <!-- <button onclick="renderDate()">Click For Date</button>   -->
</body>

<script>
    const dateElement = document.getElementById('dateComponent');
    const dateBtn = document.getElementById('dateBtn');

    dateBtn.addEventListener('click', (e) => {
        dateElement.innerHTML = Date();
        dateBtn.innerHTML = "Update"
    })

    
</script>
</html>`
export default index;
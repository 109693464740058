const landing_mobile = `@media (max-width: 638px) {
    /* ************** LANDING-PG *************** */
    .landing-pg-container > .two-column-container {
        display: grid;
        grid-template-columns: 3fr 1fr;
        gap: 1px;
        justify-content: center;
        align-items: center;  
        background-color: black;
        box-shadow: inset 585px 25px 280px rgba(182, 192, 217, 0.32);
        /* border: 2px solid rgb(70, 174, 25); */
    
    }
        /* LANDING-PG > INFO Content */
        .landing-pg-container > .two-column-container > .info-content {
            margin-left: 10px;
            padding-left: 5px;
            /* border: 2px solid rgb(25, 70, 174); */
    
        }
    
        .landing-pg-container > .two-column-container > .info-content :nth-child(1) {
            color: rgb(230, 230, 230);
            font-family: 'Open Sans', sans-serif;
            font-size: 20px;
            font-weight: 500;
        }
        .landing-pg-container > .two-column-container > .info-content :nth-child(1) span {
            color: white;
            font-family: 'Open Sans', sans-serif;
            font-size: 22px;
            font-weight: 700;
            filter: brightness(100%);
        
        }
        .landing-pg-container > .two-column-container > .info-content :nth-child(2) {
            color: white;
            font-family: 'Open Sans', sans-serif;
            font-size: 20px;
            font-weight: 400;
            filter: brightness(80%);
            /* border: 2px solid rgb(25, 174, 65); */
        }
        .landing-pg-container > .two-column-container > .info-content > .info-btn-wrapper {
            display: flex;
            justify-content: left;
            width: 100%;
            height: 60px;
            font-family: 'Open Sans', sans-serif;
            margin-bottom: 10px;
            /* border: 2px solid rgb(174, 171, 25); */
        
        }
        .landing-pg-container > .two-column-container > .info-content > .info-btn-wrapper :nth-child(1) {
            background-color: rgba(56, 255, 156, 1);
            color: black;
            font-family: 'Open Sans', sans-serif;
            font-size: 12px;
            font-weight: 600;
            margin-right: 20px;
            padding: 5px 15px;
        }
        .landing-pg-container > .two-column-container > .info-content > .info-btn-wrapper :nth-child(2) {
            background-color: rgb(21, 20, 20);
            color: white;
            filter: brightness(100%);
            font-family: 'Open Sans', sans-serif;
            font-weight: 600;
            font-size: 12px;
            margin-left: 20px;
            padding: 5px 15px
        }
    
        /* IMG Content */
        .landing-pg-container > .two-column-container > .img-content {
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            align-items: flex-end;
            height: 90%;
            margin-bottom: 15px;
            /* margin-right: -10px; */
            /* background-color: black; */
            /* opacity: 0.8; */
            /* filter: brightness(80%); */
            /* border: 2px solid rgb(174, 25, 28); */
        
        }
        .landing-pg-container > .two-column-container > .img-content .img-caption {
            display: flex;
            justify-content: left;
            align-items: flex-end;
            text-align: left;
            color: white;
            font-family: 'Open Sans', sans-serif;
            font-weight: 600;
            width: 250px;
            margin-bottom: -15px;
            /* filter: brightness(150%) contrast(150%); */
            /* border: 2px solid rgb(25, 72, 174); */
        }
        .landing-pg-container > .two-column-container > .img-content > .img-container img {
            /* height: 400px; */
            width: 250px;
            /* opacity: 0; */
        }
        
        /* Button Ribbon */
        /* .landing-pg-container > .links-ribbon {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr 1fr;
            gap: 0px;
            width: 100%;
            height: 55px;
            margin: -30px 1.5px 0px 1.5px;
            background-color: black;
        }    */
        .landing-pg-container > .links-ribbon > .link-btn a {
            text-decoration: none;
            text-align: center;
            color: white;
            font-size: 15px;
            font-family: 'Open Sans', sans-serif;
        }
        .landing-pg-container > .links-ribbon > .link-btn a span {
            font-size: 20px;
            font-weight: 700;
        }
        
    }`

    export default landing_mobile
    
const About_mobile = `@media(max-width:700px) {

    .page-container {
        padding-left: 21px;
        padding-right: 21px;
    }

    .content-wrapper > #chef {
        margin: 0px 25px 0px !important;
        width: 100 !important;
    }

    .square {
        grid-template-columns: 1fr !important;
    }


    .square > .square-text-wrapper {
        order: 1
    }

    .square > .img-wrapper {
        order: 2
    }

}`

export default About_mobile
const Categories = `function populateGrid() {
    const gridContainer = document.querySelector('.grid-list-container');
    categories.forEach(category => {
        const gridItem = document.createElement('div');
        gridItem.className = 'grid-item';
        gridItem.innerHTML = 
            <img src="$ {category.image}" alt="$ {category.name}" />
            <div class="category-name">$ {category.name}</div>
        ;
        gridItem.addEventListener("click", function(e){
            loadProductsPage(e.target)
    
        })
        gridContainer.appendChild(gridItem);
    });
}

document.addEventListener('DOMContentLoaded', populateGrid);

function loadProductsPage (target) {
    console.log("LOADER REC ---> ", target);
    let str_target = target.innerHTML;
    let alt_target = target.alt;
    console.log("PARSED ? ---> ", str_target);
    console.log("PARSED ALT? ---> ", alt_target);
    
    if(str_target === undefined || str_target === null || str_target === ""){
        var category =  alt_target
    }else{
        var category = str_target
    }

    localStorage.setItem("target_category_name", category);
    
    window.open("../html/products.html", "_Self") 

}


const products = [
    {
        "product_id": 1,
        "product_name": "Premium Solar Panel 300W",
        "part_number": "SP300-01",
        "product_image": "https://example.com/images/panel300.jpg",
        "product_size": "65 x 39 x 1.4 in",
        "product_quantity": "10",
        "product_price": 250.00,
        "product_description": "The Premium Solar Panel 300W offers high efficiency and durability, making it an excellent choice for residential and commercial applications. With its monocrystalline design, it converts sunlight into electricity more effectively than traditional panels. This panel features a robust aluminum frame and is resistant to extreme weather conditions, ensuring a long lifespan. Ideal for off-grid systems, these panels are also compatible with various inverter technologies.",
        "product_color": "Blue/Black",
        "product_rating": 4.8,
        "category_name": "Solar Panels",
        "category_id": 1,
        "related_items": [2, 3, 4, 5, 6, 7, 8, 9, 10, 11]
    },
    {
        "product_id": 2,
        "product_name": "High-Efficiency Inverter 1500W",
        "part_number": "INV1500-02",
        "product_image": "https://example.com/images/inverter1500.jpg",
        "product_size": "12 x 8 x 3 in",
        "product_quantity": "5",
        "product_price": 350.00,
        "product_description": "This High-Efficiency Inverter 1500W converts DC power from solar panels into AC power for your home. It boasts a conversion efficiency of over 95%, minimizing energy loss. With built-in safety features like overload protection and short-circuit prevention, this inverter is a reliable choice for both on-grid and off-grid systems. Its compact design allows for easy installation in tight spaces.",
        "product_color": "Silver",
        "product_rating": 4.7,
        "category_name": "Inverters",
        "category_id": 2,
        "related_items": [1, 3, 4, 5, 6, 7, 8, 9, 10, 11]
    },
    {
        "product_id": 3,
        "product_name": "Adjustable Mounting Bracket",
        "part_number": "MB-01",
        "product_image": "https://example.com/images/mounting-bracket.jpg",
        "product_size": "Varies",
        "product_quantity": "20",
        "product_price": 45.00,
        "product_description": "The Adjustable Mounting Bracket is designed to securely hold solar panels in place, ensuring optimal angle and stability. Constructed from high-grade aluminum, it is lightweight yet robust, allowing for easy installation. The bracket is compatible with various solar panel sizes and can be adjusted for different roof angles. Its corrosion-resistant finish guarantees long-lasting performance, making it ideal for both residential and commercial setups.",
        "product_color": "Gray",
        "product_rating": 4.5,
        "category_name": "Mounting Systems",
        "category_id": 3,
        "related_items": [1, 2, 4, 5, 6, 7, 8, 9, 10, 11]
    },
    {
        "product_id": 4,
        "product_name": "Lithium-Ion Battery 12V 100Ah",
        "part_number": "BAT12V100-03",
        "product_image": "https://example.com/images/lithium-battery.jpg",
        "product_size": "13 x 6.8 x 8.5 in",
        "product_quantity": "15",
        "product_price": 600.00,
        "product_description": "The Lithium-Ion Battery 12V 100Ah is a high-capacity battery perfect for solar storage applications. With a lifespan of over 2000 cycles, it provides reliable energy storage for both off-grid and grid-tied systems. Its lightweight design allows for easy handling and installation, while the built-in battery management system ensures safe operation. This battery is perfect for powering home appliances, RVs, and other solar-powered devices.",
        "product_color": "Black",
        "product_rating": 4.9,
        "category_name": "Batteries",
        "category_id": 4,
        "related_items": [1, 2, 3, 5, 6, 7, 8, 9, 10, 11]
    },
    {
        "product_id": 5,
        "product_name": "PWM Charge Controller 40A",
        "part_number": "CC40A-04",
        "product_image": "https://example.com/images/charge-controller.jpg",
        "product_size": "6 x 4 x 2 in",
        "product_quantity": "25",
        "product_price": 75.00,
        "product_description": "The PWM Charge Controller 40A is designed to manage the charging of batteries in solar power systems, ensuring optimal battery performance and longevity. It features a user-friendly LCD display that provides real-time information on system status. This charge controller is compatible with various battery types and offers protections against overcharging, over-discharging, and short-circuits. Ideal for both DIY solar projects and professional installations.",
        "product_color": "Black",
        "product_rating": 4.6,
        "category_name": "Charge Controllers",
        "category_id": 5,
        "related_items": [1, 2, 3, 4, 6, 7, 8, 9, 10, 11]
    },
    {
        "product_id": 6,
        "product_name": "Microinverter 300W",
        "part_number": "MIV300-05",
        "product_image": "https://example.com/images/microinverter.jpg",
        "product_size": "7 x 6 x 2 in",
        "product_quantity": "30",
        "product_price": 200.00,
        "product_description": "The Microinverter 300W is a compact device that converts the DC output of solar panels into AC power for immediate use. Designed for high performance, it optimizes the output of individual panels, resulting in higher energy production. With built-in monitoring capabilities, users can track performance via a smartphone app. The microinverter is easy to install and ideal for both residential and commercial solar systems.",
        "product_color": "White",
        "product_rating": 4.7,
        "category_name": "Microinverters",
        "category_id": 6,
        "related_items": [1, 2, 3, 4, 5, 7, 8, 9, 10, 11]
    },
    {
        "product_id": 7,
        "product_name": "Solar Performance Monitor",
        "part_number": "SPM-01",
        "product_image": "https://example.com/images/performance-monitor.jpg",
        "product_size": "",
        "product_quantity": "12",
        "product_price": 150.00,
        "product_description": "The Solar Performance Monitor provides real-time data on your solar system's performance. With its intuitive interface, you can easily track energy production, consumption, and savings. This device is compatible with most inverter brands and connects to your home Wi-Fi for remote monitoring. It's an essential tool for optimizing your solar investment and ensuring maximum efficiency.",
        "product_color": "Black",
        "product_rating": 4.8,
        "category_name": "Performance Monitors",
        "category_id": 7,
        "related_items": [1, 2, 3, 4, 5, 6, 8, 9, 10, 11]
    },
    {
        "product_id": 8,
        "product_name": "Heavy-Duty Solar Racking System",
        "part_number": "RACKHD-02",
        "product_image": "https://example.com/images/racking-system.jpg",
        "product_size": "",
        "product_quantity": "8",
        "product_price": 600.00,
        "product_description": "The Heavy-Duty Solar Racking System is engineered for maximum stability and durability. Made from corrosion-resistant materials, it can withstand harsh weather conditions, making it suitable for both residential and commercial applications. The system is easy to install and adjustable, ensuring the perfect angle for solar panels. This racking system is compatible with various solar panel sizes.",
        "product_color": "Galvanized Steel",
        "product_rating": 4.7,
        "category_name": "Solar Racking Systems",
        "category_id": 8,
        "related_items": [1, 2, 3, 4, 5, 6, 7, 9, 10, 11]
    },
    {
        "product_id": 9,
        "product_name": "Solar Water Heater 200L",
        "part_number": "SWH200-01",
        "product_image": "https://example.com/images/water-heater.jpg",
        "product_size": "5 x 2 x 4 ft",
        "product_quantity": "10",
        "product_price": 1200.00,
        "product_description": "The Solar Water Heater 200L provides an eco-friendly solution for heating water using solar energy. This system is perfect for residential use and can reduce energy bills significantly. Equipped with high-efficiency solar collectors, it captures sunlight effectively and transfers heat to the water tank. Its durable design ensures longevity and reliability, making it a great investment for sustainable living.",
        "product_color": "Silver",
        "product_rating": 4.9,
        "category_name": "Solar Water Heaters",
        "category_id": 9,
        "related_items": [1, 2, 3, 4, 5, 6, 7, 8, 10, 11]
    },
    {
        "product_id": 10,
        "product_name": "Portable Solar Generator 1000W",
        "part_number": "PSG1000-03",
        "product_image": "https://example.com/images/portable-generator.jpg",
        "product_size": "12 x 8 x 6 in",
        "product_quantity": "15",
        "product_price": 800.00,
        "product_description": "The Portable Solar Generator 1000W is perfect for camping, outdoor activities, or emergency backup. It features multiple output options, including AC and USB ports, allowing you to power various devices simultaneously. This generator is lightweight and comes with a built-in solar charging option. Its robust battery ensures long-lasting power, making it an essential companion for any adventure.",
        "product_color": "Black",
        "product_rating": 4.6,
        "category_name": "Solar Generators",
        "category_id": 10,
        "related_items": [1, 2, 3, 4, 5, 6, 7, 8, 9, 11]
    },
    {
        "product_id": 11,
        "product_name": "Solar Garden Light",
        "part_number": "GL-01",
        "product_image": "https://example.com/images/garden-light.jpg",
        "product_size": "15 in height",
        "product_quantity": "50",
        "product_price": 20.00,
        "product_description": "The Solar Garden Light is an energy-efficient solution for illuminating your outdoor spaces. With its integrated solar panel, it charges during the day and provides bright LED light at night. This light is easy to install and requires no wiring, making it ideal for gardens, pathways, and patios. The weather-resistant design ensures it can withstand outdoor conditions year-round.",
        "product_color": "White",
        "product_rating": 4.5,
        "category_name": "Solar Lights",
        "category_id": 11,
        "related_items": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]
    },
    {
        "product_id": 12,
        "product_name": "Solar Air Heater 1000W",
        "part_number": "SAH1000-01",
        "product_image": "https://example.com/images/air-heater.jpg",
        "product_size": "4 x 2 x 1 ft",
        "product_quantity": "8",
        "product_price": 500.00,
        "product_description": "The Solar Air Heater 1000W utilizes solar energy to heat air for residential heating applications. This efficient heater reduces dependence on fossil fuels and lowers energy bills. Its sleek design integrates easily into your home and requires minimal maintenance. With a high-quality aluminum frame, it’s built to last while providing reliable heat during colder months.",
        "product_color": "Black",
        "product_rating": 4.7,
        "category_name": "Solar Air Heaters",
        "category_id": 12,
        "related_items": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]
    },
    {
        "product_id": 13,
        "product_name": "Polycrystalline PV Module 250W",
        "part_number": "PV250-02",
        "product_image": "https://example.com/images/pv-module.jpg",
        "product_size": "64 x 39 x 1.4 in",
        "product_quantity": "15",
        "product_price": 200.00,
        "product_description": "The Polycrystalline PV Module 250W is designed for efficiency and reliability in solar power generation. This module is perfect for residential setups and commercial installations. With its robust construction and weather-resistant features, it provides reliable energy output even in less-than-ideal conditions. Easy to install and compatible with various racking systems, it’s a solid choice for sustainable energy.",
        "product_color": "Blue",
        "product_rating": 4.4,
        "category_name": "PV Modules",
        "category_id": 13,
        "related_items": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]
    },
    {
        "product_id": 14,
        "product_name": "Solar Film 100W",
        "part_number": "SF100-01",
        "product_image": "https://example.com/images/solar-film.jpg",
        "product_size": "48 x 24 in",
        "product_quantity": "30",
        "product_price": 150.00,
        "product_description": "The Solar Film 100W offers a flexible and lightweight solution for generating solar power. Ideal for unconventional spaces, this solar film can be integrated into various surfaces, providing versatility in installations. It is designed to maximize energy capture while remaining unobtrusive. This product is particularly beneficial for mobile applications such as RVs and boats.",
        "product_color": "Transparent",
        "product_rating": 4.5,
        "category_name": "Solar Film",
        "category_id": 14,
        "related_items": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]
    },
    {
        "product_id": 15,
        "product_name": "Solar Tracking System",
        "part_number": "ST100-01",
        "product_image": "https://example.com/images/tracking-system.jpg",
        "product_size": "",
        "product_quantity": "5",
        "product_price": 1200.00,
        "product_description": "The Solar Tracking System enhances the efficiency of solar power generation by adjusting the position of solar panels throughout the day. By tracking the sun, this system can increase energy output by up to 30%. Suitable for both residential and commercial installations, it is constructed from durable materials designed to withstand harsh weather conditions. Its easy setup and low maintenance make it a valuable addition to any solar array.",
        "product_color": "Gray",
        "product_rating": 4.9,
        "category_name": "Solar Tracking Systems",
        "category_id": 15,
        "related_items": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]
    },
    {
        "product_id": 16,
        "product_name": "Grid-Tie Inverter 3000W",
        "part_number": "GTI3000-01",
        "product_image": "https://example.com/images/grid-tie-inverter.jpg",
        "product_size": "15 x 10 x 5 in",
        "product_quantity": "8",
        "product_price": 950.00,
        "product_description": "The Grid-Tie Inverter 3000W is engineered for seamless integration with utility power. It allows solar-generated energy to be fed back into the grid, providing energy credits to homeowners. This inverter features advanced safety and monitoring systems, ensuring optimal performance and reliability. Its compact design makes it easy to install, and it is compatible with a variety of solar panels.",
        "product_color": "Black",
        "product_rating": 4.8,
        "category_name": "Grid-Tie Systems",
        "category_id": 16,
        "related_items": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]
    },
    {
        "product_id": 17,
        "product_name": "Off-Grid Solar Kit 2000W",
        "part_number": "OGK2000-01",
        "product_image": "https://example.com/images/off-grid-kit.jpg",
        "product_size": "",
        "product_quantity": "10",
        "product_price": 1500.00,
        "product_description": "The Off-Grid Solar Kit 2000W includes everything needed for a complete solar setup, making it ideal for remote locations. This kit features high-efficiency solar panels, an inverter, and a battery bank, allowing for self-sufficient energy production. Designed for easy installation, it comes with comprehensive instructions and support. Perfect for cabins, RVs, and emergency preparedness.",
        "product_color": "N/A",
        "product_rating": 4.7,
        "category_name": "Off-Grid Systems",
        "category_id": 17,
        "related_items": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]
    },
    {
        "product_id": 18,
        "product_name": "Solar Power Cables 10AWG",
        "part_number": "CABLE10AWG-01",
        "product_image": "https://example.com/images/solar-cables.jpg",
        "product_size": "100 ft",
        "product_quantity": "50",
        "product_price": 100.00,
        "product_description": "The Solar Power Cables 10AWG are essential for connecting solar panels to inverters and batteries. These cables are designed to withstand harsh outdoor conditions and are UV-resistant, ensuring durability and longevity. With a flexible design, they are easy to handle and install. This set includes 100 feet of cable, providing ample length for most installations.",
        "product_color": "Black",
        "product_rating": 4.6,
        "category_name": "Solar Cables",
        "category_id": 18,
        "related_items": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]
    },
    {
        "product_id": 19,
        "product_name": "Solar Junction Box",
        "part_number": "JBOX-01",
        "product_image": "https://example.com/images/junction-box.jpg",
        "product_size": "",
        "product_quantity": "40",
        "product_price": 25.00,
        "product_description": "The Solar Junction Box is designed to protect electrical connections in solar panel systems. Its weatherproof design ensures reliable performance even in harsh environments. This junction box includes built-in bypass diodes to minimize energy loss during shading. Easy to install and compatible with most solar panels, it is an essential component for ensuring a safe and efficient solar installation.",
        "product_color": "Black",
        "product_rating": 4.7,
        "category_name": "Solar Junction Boxes",
        "category_id": 19,
        "related_items": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]
    },
    {
        "product_id": 20,
        "product_name": "Solar Thermal Collector 2m²",
        "part_number": "STC2M-01",
        "product_image": "https://example.com/images/thermal-collector.jpg",
        "product_size": "6.5 x 4.5 ft",
        "product_quantity": "10",
        "product_price": 800.00,
        "product_description": "The Solar Thermal Collector 2m² is designed for efficient heat capture for residential water heating. Utilizing advanced technology, it maximizes energy absorption, providing a sustainable and cost-effective solution for hot water needs. Its durable design withstands environmental challenges, ensuring long-lasting performance. Ideal for homes looking to reduce energy costs while harnessing renewable energy.",
        "product_color": "Blue",
        "product_rating": 4.8,
        "category_name": "Solar Thermal Collectors",
        "category_id": 20,
        "related_items": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]
    }
]
const categories = [
    {
        "Id": 1,
        "name": "Solar Panels",
        "image": "../assets/images/products/solar_panels_02.jpeg"
    },
    {
        "Id": 2,
        "name": "Inverters",
        "image": "../assets/images/products/solar_inverter_02.jpeg"
    },
    {
        "Id": 3,
        "name": "Mounting Systems",
        "image": "../assets/images/products/solar_inverter_mounted_04.jpeg"
    },
    {
        "Id": 4,
        "name": "Batteries",
        "image": "../assets/images/products/solar_batteries_01.jpeg"
    },
    {
        "Id": 5,
        "name": "Charge Controllers",
        "image": "../assets/images/products/solar_charge_controllers_01.jpeg"
    },
    {
        "Id": 6,
        "name": "Microinverters",
        "image": "../assets/images/products/solar_inverter_03.jpeg"
    },
    {
        "Id": 7,
        "name": "Performance Monitors",
        "image": "../assets/images/products/solar_inverter_mounted_03.jpeg"
    },
    {
        "Id": 8,
        "name": "Solar Racking Systems",
        "image": "../assets/images/products/solar_panels_03.jpeg"
    },
    {
        "Id": 9,
        "name": "Solar Water Heaters",
        "image": "../assets/images/products/solar_water_heater_01.jpeg"
    },
    {
        "Id": 10,
        "name": "Solar Generators",
        "image": "../assets/images/products/solar_batteries_02.jpeg"
    },
    {
        "Id": 11,
        "name": "Solar Lights",
        "image": "../assets/images/products/solar_lights_01.jpeg"
    },
    {
        "Id": 12,
        "name": "Solar Air Heaters",
        "image": "../assets/images/products/solar_air_hearters01.jpeg"
    },
    {
        "Id": 13,
        "name": "PV Modules",
        "image": "../assets/images/products/solar_panels_04.jpeg"
    },
    {
        "Id": 14,
        "name": "Solar Film",
        "image": "../assets/images/products/solar_lights_03.jpeg"
    },
    {
        "Id": 15,
        "name": "Solar Tracking Systems",
        "image": "../assets/images/products/solar_panels_02.jpeg"
    },
    {
        "Id": 16,
        "name": "Grid-Tie Systems",
        "image": "../assets/images/products/solar_panels_01.jpeg"
    },
    {
        "Id": 17,
        "name": "Off-Grid Systems",
        "image": "../assets/images/products/solar_lights_02.jpeg"
    },
    {
        "Id": 18,
        "name": "Solar Cables",
        "image": "../assets/images/products/solar_cables_01.jpeg"
    },
    {
        "Id": 19,
        "name": "Solar Junction Boxes",
        "image": "../assets/images/products/solar_charge_controllers_home_01.jpeg"
    },
    {
        "Id": 20,
        "name": "Solar Thermal Collectors",
        "image": "../assets/images/products/solar_water_heater_02.jpeg"
    },
    {
        "Id": 21,
        "name": "Solar Pumps",
        "image": "../assets/images/products/solar_water_heater_03.jpeg"
    },
    {
        "Id": 22,
        "name": "Hybrid Inverters",
        "image": "../assets/images/products/solar_inverter_04.jpeg"
    },
    {
        "Id": 23,
        "name": "Energy Management Systems",
        "image": "../assets/images/products/solar_charge_controllers_home_02.jpeg"
    },
    {
        "Id": 24,
        "name": "Solar Accessories",
        "image": "../assets/images/products/solar_cables_02.jpeg"
    },
    {
        "Id": 25,
        "name": "Solar Window Films",
        "image": "../assets/images/products/solar_charge_controllers_home_03.jpeg"
    },
    {
        "Id": 26,
        "name": "Portable Solar Chargers",
        "image": "../assets/images/products/solar_batteries_03.jpeg"
    },
    {
        "Id": 27,
        "name": "Solar Desalination Systems",
        "image": "../assets/images/products/solar_batteries_04.jpeg"
    },
    {
        "Id": 28,
        "name": "Building-Integrated Photovoltaics (BIPV)",
        "image": "../assets/images/products/solar_charge_controllers_02.jpeg"
    },
    {
        "Id": 29,
        "name": "Solar Tracking Controllers",
        "image": "../assets/images/products/solar_charge_controllers_04.jpeg"
    },
    {
        "Id": 30,
        "name": "Solar Field Components",
        "image": "../assets/images/products/solar_charge_controllers_03.jpeg"
    }
]
`

export default Categories
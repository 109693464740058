// import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";


// Image
// import bgImage from "assets/images/illustrations/illustration-reset.jpg";

function DisplayView(item) {
  // console.log("APPL ITEM ---> ", item.item);
  let minW = "";
  if(item.item.mobile === false){
    minW = "1225px"
  }
  return (
    <>
      <MKBox
          sx={{ overflowX: "scroll", overflowY: "scroll" }}
            >
            <iframe
              src={item.item.url}
              height={"100%"}
              width={"100%"}
              loading="eager"
              name={item.item.name}
              style={{
                border:"none",
                height: "1300px",
                minHeight:"500px",
                minWidth: minW,
                overflow: "hidden"
              }}
            ></iframe>            


      </MKBox>
    </>
  );
}

export default DisplayView

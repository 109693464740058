
/* Footer Styles */

const Footer = `.footer {
    background-color: #11122b;
    color: #cbcbcb;
    font-family: "Ubuntu Condensed" sans-serif;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 400px;
    margin-top: -4px;
}

.footer > .logo-footer img {
    width: 180px;
    height: 100%;
    /* Filter */
    /* filter: brightness(50%); */
    /* Opacity */
    opacity: 0.5;
}

.footer > .footer-phone-hours span {
    margin-left: 10px;
    margin-right: 10px;
    font-size: 1em;
    letter-spacing: 2px;
}

.footer > .footer-phone-hours > .hours {
    font-size: 0.8em;
    color: #cea135;
    text-transform: uppercase;
}

.footer > .links-wrapper {
    margin-top: 40px;
    margin-bottom: 40px;
    width: 400px;
}

.footer > .social-media-icons-wrapper {
    margin-top: 40px;
    margin-bottom: 40px;
    width: 300px;
    display: flex;
    justify-content: space-around;

}

.footer > .social-media-icons-wrapper a {
    font-size: 1.5em;
    color: #cbcbcb;
    transition: 0.5s;
}


.footer > .social-media-icons-wrapper a:hover {
    color: #cea135;
}


.footer > .copyright-wrapper {
    margin-bottom: 42px;
    font-size: 0.7em;
    color: #858585;
}`

export default Footer;

import {
    Prototype_Projects,
    Portfolio_Blog_Posts
} from '../types';

import {flatten} from 'lodash';

const initialState = {
    error: null,
    projects: [],
    blog_posts: [],
}


const Data_Base = (state = initialState, action) => {
    switch (action.type) {
        case Prototype_Projects:
            return {
                ...state,
                projects: flatten(action.payload),
                error: action.err,
            }
        case Portfolio_Blog_Posts:
            return {
                ...state,
                blog_posts: flatten(action.payload),
                error: action.err,
            }

        default:
            return state
    }
}
export default Data_Base;
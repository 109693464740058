/* Styles */
const Main = `html {
    font-family: 'Lato', sans-serif;
    box-shadow: border-box;
    font-size: 62.5%;
}

*,
*::before
*::after {
    margin: 0;
    padding: 0;
    box-sizing: inherit;
}

.container {
    display: grid;

    grid-template-rows: repeat(auto-fit, minmax(100px, 1fr));
    grid-template-columns: 1fr 1fr 1fr;
    justify-items: center;
}
.container-homepage {
    margin-top: 5em;
    align-items: center;
    height: 100vh;
}
.container-results {
    margin-top: 2em;
    align-items: center;
    height: 100vh;

}

.homepage-logo {
    grid-column: 2;
    grid-row: 1;
}

.homepage-logo img {
    width: 10.5rem;
}

.search-bar {
    grid-column: 1/-1;
    grid-row: 2;
}
.search-bar input {
    width: 66vw;
    padding-left: 0.75em;
}

.homepage-search-bar input{
    height: 11.8rem;
}
.results-search-bar input{
    height: 5rem;
    margin-bottom: 10em;
    margin-top: 3em;
}


.search-bar input[type="text"] {
    font-size: 2.5em;
    font-family: Arial, Helvetica, sans-serif,'Font Awesome 6 free';
    font-weight: 600;
    color: #585858;
    box-shadow: 2px 3px 20px 1px rgba(0, 0, 0, 0.1);
    border: 0px solid transparent;
    border-radius: 0.5rem;
}
.search-bar input[type="text"]::placeholder {
    font-family: Arial, Helvetica, sans-serif, 'Font Awesome 6 free';
    font-weight: 600;
    color: #a4a4a4;

}

.search-bar input[type=text]:focus {
    outline: none;
    box-shadow: 2px 3px 20px 1px rgba(0, 0, 0, 0.3);
}

.search-bar p {
    color: #b3b3b3;
    font-size: 1.4rem;
    line-height: 1.7rem;
    margin-top: 2.3rem;
    text-align: right;
} 



.recent-posts-wrapper {
    grid-column: 1/-1;
    grid-row: 3;
}

.recent-posts-heading {
    color: #2660f3;
    margin-bottom: 3rem;
    font-size: 1.4rem;
}

.recent-posts {
    display: grid;
    width: 66vw;
    grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
    gap: 2.5rem;
}
.recent-posts-title {
    color: #535353;
    font-size: 1.4rem;
}
.recent-post-category {
    color: #999999;
    font-size: 1.2rem;
    font-weight: 600;
}



/* Results PG */

.results-posts-wrapper {
    grid-row: 3;
    grid-column: 1/-1;
    width: 66vw;
}
.post {
    margin-top: 3em;
    margin-bottom: 3em;
}
.category-name {
    color: #2660f3;
    text-decoration: none;
    margin-bottom: 1.5rem;
    font-size: 1.4rem;
}

.result-post-title a {
    color: #535353;
    text-decoration: none;
    font-size: 1.8rem;
}

.result-post-links-wrapper {
    margin-top: 2rem;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
    gap: 2rem;
}
.result-post-links-wrapper > .result-post-link-wrapper a {
    text-decoration: none;
    color: #535353;
    font-size: 1.4rem;
}

.result-post-links-wrapper > .result-post-link-wrapper a:hover {
    text-decoration: underline;
}

.results-logo {
    grid-column: 2;
    grid-row: 1;
    display: flex;
    justify-content: center;
    align-items: center;
}
.results-logo img {
    width: 50px;
}
`
export default Main;

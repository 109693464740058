const index = `<!DOCTYPE html>
<html lang="en">
<head>
    <meta charset="UTF-8">
    <meta http-equiv="X-UA-Compatible" content="IE=edge">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <script 
    src="https://kit.fontawesome.com/a6874c76aa.js" 
    crossorigin="anonymous"></script>
    <link rel="preconnect" href="https://fonts.googleapis.com">
    <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
    <link href="https://fonts.googleapis.com/css2?family=Roboto+Condensed:wght@300&display=swap" rel="stylesheet">
    <link href="https://fonts.googleapis.com/css2?family=Ubuntu+Condensed&display=swap" rel="stylesheet">
    <link href="https://fonts.googleapis.com/css2?family=Roboto+Slab&display=swap" rel="stylesheet">

    <!-- <link rel="stylesheet" href="./styles/styles.css"/> -->
    <link rel="stylesheet" href="./styles/common.css"/>
    <link rel="stylesheet" href="./styles/nav.css"/>
    <link rel="stylesheet" href="./styles/homepage.css"/>
    <link rel="stylesheet" href="./styles/footer.css"/>
    <link rel="stylesheet" href="./styles/media-queries.css"/>
    
    
    <title>Homepage</title>
</head>
<body>
    <div class="navigation-wrapper">
        <div class="left-column">
            <div class="icon">
                <i class="fa-solid fa-phone-volume"></i>
            </div>
            <div class="contact-hours-wrapper">
                <div class="phone">555 555 5555</div>
                <div class="hours">10AM - MIDNIGHT</div>
                
            </div>
        </div>
        <div class="center-column">
            <div class="banner-image">
                <img src="images/logos/decamp-fantastic-fries-logo-white.png" alt="Logo">
            </div>

        <div class="links-wrapper">
            <div class="nav-link">
                <a href="index.html">Home</a> 
            </div>
            <div class="nav-link">
                <a href="about.html">About</a>
            </div>
            <div class="nav-link">
                <a href="menu.html">Menu</a>
            </div>
            <div class="nav-link">
                <a href="contact.html">Contact</a>  
            </div>
             
        </div>
        </div>
        <div class="right-column">
            <div class="address-wrapper">
                <a href="contact.html">
                    321 Any Ave <br>
                    Dromilly, TR, 000000
                </a>   
            </div>
            <div class="contact-icon">
                <a href="contact.html">
                    <i class="fa-solid fa-location-dot"></i>
                </a>
            </div>
        </div>


    </div>

    <div class="hero-section">
        <div class="top-heading">
            <h1>HTML Styled Fries</h1>
        </div>
        <div class="bottom-heading">
            <h3>Coding fuel</h3>
        </div>
    </div>
    <div class="features-section">
        <div class="columns-wrapper">
            <div class="column">
                <i class="fa-sharp fa-solid fa-truck-monster"></i>
                <p>We Deliver!</p>
                <p>Lorem ipsum dolor sit amet consectetur adipisicing elit.</p>
            </div>
            <div class="column">
                <i class="fa-solid fa-wifi"></i>
                <p>You can code from here!</p>
                <p>Lorem ipsum dolor sit amet consectetur adipisicing elit.</p>
            </div>
            <div class="column">
                <i class="fa-solid fa-chart-line"></i>
                <p>100+ types of fries!</p>
                <p>Lorem ipsum dolor sit amet consectetur adipisicing elit.</p>
            </div>
        </div>
    </div>

    <div>
        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d818.2572994773491!2d-77.65394740871974!3d18.491907006967136!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x665db42c44427f21!2sVibez%20Pizza%20Restaurant%20%26%20Bar!5e0!3m2!1sen!2sus!4v1673558377377!5m2!1sen!2sus" width="100%" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
    </div>
    <div class="footer">
        <div class="logo-footer">
            <img src="images/logos/decamp-fantastic-fries-logo-white.png" alt="Logo">
        </div>
        <div class="footer-phone-hours">
            <span class="phone">
                555 555 5555
            </span>
            <span class="hours">
                10 am - midnight
            </span>
        </div>
        <div class="links-wrapper">
            <div class="nav-link">
                <a href="index.html">Home</a> 
            </div>
            <div class="nav-link">
                <a href="about.html">About</a>
            </div>
            <div class="nav-link">
                <a href="menu.html">Menu</a>
            </div>
            <div class="nav-link">
                <a href="contact.html">Contact</a>  
            </div>
        </div>
        <div class="social-media-icons-wrapper">
            <a href="#">
                <i class="fa-brands fa-instagram"></i>
            </a>
            <a href="#">
                <i class="fa-brands fa-twitter"></i>
            </a>
            <a href="#">
                <i class="fa-brands fa-facebook"></i>
            </a>
        </div>
        <div class="copyright-wrapper">
            &copy; 2022 DevCampFries &#124; All rights reserved
        </div>
    </div>
    
</body>
</html>`

export default index
const index = `<!DOCTYPE html>
<html lang="en">
<head>
    <meta charset="UTF-8">
    <meta http-equiv="X-UA-Compatible" content="IE=edge">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>Generate Message</title>
</head>
<body>
    <div class="widget">
        <input type="text" id="chat-input">
        <button id="msgBtn" onClick="sendMessage()">Submit</button>
        <button onclick="clearMessages()">Clear Messages</button>
    </div>
</body>

<script>
    function sendMessage () {
        const newDiv = document.createElement('div');
        newDiv.classList.add('chatMsg');
        let chatInput = document.querySelector('#chat-input').value;
        console.log("CHAT INPUT = ", chatInput);
        const newContent = document.createTextNode(chatInput);
        newDiv.appendChild(newContent);

        const widget = document.querySelector('.widget');
        let chatWrapper = document.querySelector('#msgBtn');
        document.querySelector('#chat-input').value = '';

        // if(document.querySelectorAll('.chatMsg').length > 0 ){
        //     chatWrapper = document.querySelectorAll('.chatMsg')[0];
        // }

        widget.insertBefore(newDiv, chatWrapper);

    }

    function clearMessages () {
        const messages = document.querySelectorAll('.chatMsg');
        messages.forEach(message => {
            message.remove();
        })
    }
</script>
</html>`

export default index;
import randomImagePicker from "components/RandomImagePicker";

import image1 from "assets/JA/paul-mathew-mBV0xwwBdms-unsplash.jpg";
import image2 from "assets/JA/rock-staar-kbDSBL5NBkA-unsplash.jpg";
import image3 from "assets/JA/rock-staar-w3SKfOLpVwA-unsplash.jpg";
import image4 from "assets/JA/sebastien-jermer-n7DY58YFg9E-unsplash.jpg";
import image5 from "assets/JA/yves-alarie-BXk-N2fPLYo-unsplash.jpg";
import image6 from "assets/JA/yves-alarie-F64vhzvowNw-unsplash.jpg";
import image7 from "assets/JA/yves-alarie-jIk3I_e16uw-unsplash.jpg";
    let imageArray = [
        image1,
        image2,
        image3,
        image4,
        image5,
        image6,
        image7,
    ]
    const randomPic = randomImagePicker(imageArray)



export default randomPic
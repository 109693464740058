const MQ625 = `@media (max-width: 625px) {


/* Results PG */

.container {
    display: grid;

    grid-template-rows: repeat(auto-fit, minmax(100px, 1fr));
    grid-template-columns: 1fr 1fr 1fr;
    justify-items: center;
}
.container-homepage {
    margin-top: 5em;
    align-items: center;
    height: 100vh;
}
.container-results {
    margin-top: 2em;
    align-items: center;
    height: 130vh;

}

.homepage-logo {
    grid-column: 2;
    grid-row: 1;
}

.homepage-logo img {
    width: 10.5rem;
} 




.results-posts-wrapper {
    grid-row: 3;
    grid-column: 1/-1;
    width: 66vw;
}
.post {
    margin-top: 3em;
    margin-bottom: 3em;
}
.category-name {
    color: #2660f3;
    text-decoration: none;
    margin-bottom: 1.5rem;
    font-size: 1.4rem;
}

.result-post-title a {
    color: #535353;
    text-decoration: none;
    font-size: 1.8rem;
}

.result-post-links-wrapper {
    margin-top: 2rem;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(50px, 1fr));
    gap: 2rem;
}
.result-post-links-wrapper > .result-post-link-wrapper a {
    text-decoration: none;
    color: #535353;
    font-size: 1.4rem;
}

.result-post-links-wrapper > .result-post-link-wrapper a:hover {
    text-decoration: underline;
}

.results-logo {
    grid-column: 2;
    grid-row: 1;
    display: flex;
    justify-content: center;
    align-items: center;
}
.results-logo img {
    width: 50px;
}


}`

export default MQ625;
const styles = `body {
    /* font-family: 'Oxygen', sans-serif; */
    /* font-family: 'Architects Daughter', cursive; */
    /* font-family: 'Caveat', cursive; */
    /* font-family: 'Chakra Petch', sans-serif; */
    /* font-family: 'Cormorant Infant', serif; */
    font-family: 'Grandstander', cursive;
    /* font-family: 'Handlee', cursive; */
    /* font-family: 'Oregano', cursive; */
    /* font-family: 'Rajdhani', sans-serif; */
    /* font-family: 'Stint Ultra Condensed', cursive; */
}

.body-class {
    background-color: black;
    color: aliceblue;
}

.container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: 0px 15px 20px 15px;
    margin-bottom: 10px;
    height: 100vh;
    /* background-color: black;
    color: white; */
}

.calendar-header-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;

}
.calendar-year-header-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 5px;
    padding: 10px 5px;
    margin: 0px 10px;

}

.calendar-year-header-wrapper > i {
    color: rgb(55, 88, 153);
}

.calendar-year-header-wrapper i:hover {
    cursor: pointer;
}

.calendar-header-wrapper i {
    font-size: 45px;
    /* color: black; */

}

.calendar-header-wrapper > .month-name-wrapper {
    font-size: 45px;
}

.calendar-header-wrapper i:hover {
    cursor: pointer;
}

.week-days-wrapper {
    display: flex;
    /* flex: 1; */
    justify-content: space-evenly;
    align-items: center;
    padding: 5px 3px;
    margin: 1px 1px;
    height: 35px;
    width: 100%;
    border-radius: 5px;
    border: 1px solid transparent;
    /* background-color: rgba(134, 219, 224, 0.5); */
    /* border: 1px solid blue; */
}

.week-days-wrapper > .week-day {
    font-size: 18px;
    height: 35px;
    width: 13.5%;
    border-radius: 5px;
    border: 1px solid transparent;
    background-color: rgb(101, 180, 214, 0.2);
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    
    /* border-bottom: 1px solid blue; */
}

.month-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    width: 100%;
    border-radius: 5px;
    border: 1px solid transparent;
    /* background-color: rgba(134, 219, 224, 0.5); */
    margin: 0px 0px 10px 0px;
}

.month-week-wrapper {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    padding: 5px 3px;
    margin: 1px 1px;
    height: 70px;
    width: 100%;
    /* background-color: rgb(201, 236, 251, 0.7); */
    /* border: 1px solid green; */

}

.month-week-wrapper > .month-day-wrapper {
    border: 1px solid rgb(17, 34, 131, 0.3);
    border-radius: 3px;
    background-color: rgba(3, 181, 251, 0.7);
    height: 100%;
    width: 13.5%
}

.month-week-wrapper > .month-day-wrapper:hover {
    opacity: 0.7;
}

.day-num-wrapper {
    width: 20px;
    height: 20px;
    border-radius: 50px;
    margin: 3px;
    padding: 2px;
}

.month-week-wrapper > .month-day-wrapper >.day-notes-wrapper {
    font-size: 9px;
    height: 43px;
    margin-top: -10px;
    padding: 1px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    font-weight: bold;
    /* font-family: 'Oxygen', sans-serif; */
    /* font-family: 'Architects Daughter', cursive; */
    /* font-family: 'Caveat', cursive; */
    /* font-family: 'Chakra Petch', sans-serif; */
    /* font-family: 'Cormorant Infant', serif; */
    /* font-family: 'Grandstander', cursive; */
    /* font-family: 'Handlee', cursive; */
    /* font-family: 'Oregano', cursive; */
    /* font-family: 'Rajdhani', sans-serif; */
    /* font-family: 'Stint Ultra Condensed', cursive; */
    /* border: 1px solid rgb(17, 34, 131, 0.3); */
}


.todays-date {
    background-color: rgb(38, 24, 193, 0.7);
    width: 20px;
    height: 20px;
    border: 1px solid transparent;
    border-radius: 50px;
    margin: 3px;
    padding: 5px;
}

.month_over-flow {
    opacity: 0.4;
}


.day-schedual-wrapper {
    visibility: hidden;
    opacity: 0;
    height: 0px;
}

.day-schedual-wrapper-show {
    visibility: visible;
    opacity: 0.96;
    height: 150vh;
    width: 150vw;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    position: absolute;
    background-color: aliceblue;

}
.day-schedual-wrapper-show-dark {
    visibility: visible;
    opacity: 0.96;
    height: 150vh;
    width: 100vw;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    position: absolute;
    background-color: rgb(0, 0, 0);

}

.schedual-header {
    height: 10vh;
    width: 90vw;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    position: absolute;
    margin: 5px 25px;
    padding-right: 35px;
    /* background-color: rgb(30, 15, 15);     */

}

.schedual-header i {
    font-size: 45px;
    color: rgb(3, 53, 234);

}

.view-schedual-view {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 100px;
    width: 90vw;
    height: 20vh;
    /* background-color: rgb(30, 15, 15);   */
}





.theme-icon-wrapper {
    height: 40px;
    margin: 10px 3px;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
}

.theme-icon-wrapper i {
    color: rgb(240, 145, 21);
    font-size: 25px;
}

.theme-icon-wrapper :hover {
    color: rgb(238, 182, 108);
    cursor: pointer;
}


.spacer25 {
    height: 25px;
    width: 100%;
}`

export default styles;
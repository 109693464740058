const Cart = `const cartItems = [
    {
        "product_id": 1,
        "product_name": "Premium Solar Panel 300W",
        "part_number": "SP300-01",
        "product_image": "../assets/images/products/solar_panels_02.jpeg",
        "product_size": "65 x 39 x 1.4 in",
        "product_quantity": "10",
        "product_price": 250.00,
        "product_description": "The Premium Solar Panel 300W offers high efficiency and durability.",
        "product_color": "Blue/Black",
        "product_rating": 4.8,
        "category_name": "Solar Panels",
        "category_id": 1,
        "related_items": [2, 3, 4, 5, 6, 7, 8, 9, 10, 11]
    },
    {
        "product_id": 2,
        "product_name": "High-Efficiency Inverter 1500W",
        "part_number": "INV1500-02",
        "product_image": "../assets/images/products/solar_inverter_02.jpeg",
        "product_size": "12 x 8 x 3 in",
        "product_quantity": "5",
        "product_price": 350.00,
        "product_description": "This High-Efficiency Inverter 1500W converts DC power into AC power.",
        "product_color": "Silver",
        "product_rating": 4.7,
        "category_name": "Inverters",
        "category_id": 2,
        "related_items": [1, 3, 4, 5, 6, 7, 8, 9, 10, 11]
    },
    {
        "product_id": 81,
        "product_name": "Solar Charge Controller 20A",
        "part_number": "CHARGER-20A-01",
        "product_image": "../assets/images/products/solar_charge_controllers_01.jpeg",
        "product_size": "Standard",
        "product_quantity": "5",
        "product_price": 90.00,
        "product_description": "20A charge controller for efficient battery management.",
        "product_color": "Black",
        "product_rating": 4.6,
        "category_name": "Charge Controllers",
        "category_id": 6,
        "related_items": [82, 83, 84, 85, 86, 87, 88, 89, 90, 91]
    },
    {
        "product_id": 136,
        "product_name": "Microinverter 250W",
        "part_number": "MI-250-01",
        "product_image": "../assets/images/products/solar_inverter_03.jpeg",
        "product_size": "Standard",
        "product_quantity": "3",
        "product_price": 200.00,
        "product_description": "250W microinverter for optimized solar energy conversion.",
        "product_color": "Black",
        "product_rating": 4.8,
        "category_name": "Microinverters",
        "category_id": 6,
        "related_items": [137, 138, 139, 140, 141, 142, 143, 144, 145, 146]
    },
];

function renderCart() {
    const cartContainer = document.querySelector('.shop_cart');
    cartContainer.innerHTML = '';

    let total = 0;
    cartItems.forEach(item => {
        const row = document.createElement('tr');
        row.innerHTML = 
            <td class="row-item1">
                <img src="$ {item.product_image}" alt="$ {item.product_name}" class="product-image">
                <div>$ {item.product_name} ($ {item.product_color})</div>
            </td>
            <td class="row-item2">
                <input id="quantity_input" type="number" min="1" value="$ {item.product_quantity}" 
                onchange="updateQuantity($ {item.product_id}, this.value)">
            </td>
            <td class="row-item3">$$ {(item.product_price * item.product_quantity).toFixed(2)}</td>
            <td class="row-item4">
                <button onclick="removeItem($ {item.product_id})">Remove</button>
            </td>
        ;
        const rowItem = row.querySelector('.row-item1');
        rowItem.addEventListener('click', () => {            
            alert($ {item.product_name})
        });
        const rowItem2 = row.querySelector('.row-item2');
        rowItem2.addEventListener('change', (e) => {            
            updateQuantity(item.product_id, e.target.value);
        });

        const rowItem4 = row.querySelector('.row-item4');
        rowItem4.addEventListener('click', () => {            
            removeItem(item.product_id);
        });
        cartContainer.appendChild(row);
        total += item.product_price * item.product_quantity;

        // console.log("SHOPPING CART TOTAL ---> ", total)
    });

    document.getElementById('total-price').innerText = total.toFixed(2);
}

function updateQuantity(id, quantity) {
    const item = cartItems.find(i => i.product_id === id);
    if (item) {
        item.product_quantity = parseInt(quantity);
        renderCart();
    }
}

function removeItem(id) {
    const index = cartItems.findIndex(i => i.product_id === id);
    if (index > -1) {
        cartItems.splice(index, 1);
        renderCart();
    }
}

document.getElementById('checkout-button').addEventListener('click', () => {
    // alert('Proceeding to checkout...');
});

// Initial rendering of cart
renderCart();
`

export default Cart
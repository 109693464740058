import { useState, useEffect } from "react";

// prop-types is a library for type checking of props
import PropTypes from "prop-types";

// react-copy-to-clipboard components
import { CopyToClipboard } from "react-copy-to-clipboard";


// react-syntax-highlighter components
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { prism } from "react-syntax-highlighter/dist/esm/styles/prism";

// @mui material components
import Grid from "@mui/material/Grid";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Slide from "@mui/material/Slide";

import Link from "@mui/material/Link";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKAlert from "components/MKAlert";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";

// Material Kit 2 PRO React base styles
import colors from "assets/theme/base/colors";


import Drop from "pages/ProjectView/tools/drop";

import GetCode from "layouts/sections/components/CodeView/code_routes";

function View({ children, height, item, ...rest }) {
  const { grey } = colors;
  let selected = item.item;
  if(selected.name === ""){
    let getPrototype = localStorage.getItem("selected_proto");
    let prototype = JSON.parse(getPrototype);
    selected = prototype;
  }


    let routes = GetCode(selected)

  const [activeTab, setActiveTab] = useState(0);
  const [success, setSuccess] = useState(false);

  const [codeMenu, setCodeMenu] = useState(false);

  const [codeSelected, setSelectedCode] = useState("");

  const [itemSelected, setSelectedItem] = useState("");

    let getSelectedItem = localStorage.getItem("selected_code");
    // console.log("CODE VIEW ROUTES -->", routes)

  const openMenu = () => 
    setCodeMenu(!codeMenu)

  

  const handleTabType = (event, newValue) => setActiveTab(newValue);

  useEffect(() => {
    setTimeout(() => setSuccess(false), 3000);   
  }, [success]);


  // useEffect(() => {
  //   setCodeMenu(codeMenu)   
  // }, [codeMenu]);

  useEffect(() => {    
    if(getSelectedItem  !== null && getSelectedItem !== undefined){
    let selectedItem = JSON.parse(getSelectedItem);
      // console.log("CODE VIEW SELECTED COMPONENT:  ===> ", getSelectedItem)
      let getCode = selectedItem.component
      let code = getCode
      // console.log("CODE SELECTED:  ===> ", selectedItem.name)
      setSelectedCode(code)   
      setSelectedItem(selectedItem)
    }
  }, [getSelectedItem, localStorage]);

  useEffect(() => {
    // setCodeMenu(false)
    // console.log("EFFECT CODE MENU OPEN??? ---> ", codeMenu);
   
  }, [itemSelected]);

  return (
    <MKBox
      width="100%"
      height="100%"
      position="relative"
      borderRadius="xl"
      shadow="lg"
      mb={12}
      sx={{ overflow: "hidden" }}
      {...rest}
    >
      <MKBox
        px={3}
        sx={{
          borderBottom: ({ borders: { borderWidth, borderColor } }) =>
            `${borderWidth[1]} solid ${borderColor}`,
        }}
      >
        <Grid container spacing={2} justifyContent="space-between" py={1}>
          <Grid item xs={12} lg={5} mb={3}>
          <MKTypography
                  variant="button"
                  fontWeight="regular"
                  opacity={0.8}
                  color="info"
                  component={Link}
                  href={selected.url}
                  target="_blank"
                  sx={{
                    fontSize: "15px",
                    
                  }}
                  onMouseEnter={(target)=>{
                    target.target.style.color = "blue"
                    // console.log("TARGET ---> ", target.target.style);
                  }}
                  onMouseLeave={(target)=>{
                    target.target.style.color = ""
                  }}
                >
                  {"Open in new window "}
                </MKTypography>
            <MKTypography variant="body2" pt={0.5}>
              {selected.details}
            </MKTypography>
          </Grid>
          <Grid item xs={12} lg={3}>
            <AppBar position="static">
              <Tabs value={activeTab} onChange={handleTabType}>
                <Tab
                  icon={
                    <MKBox
                      component="i"
                      color="dark"
                      mr={1.25}
                      sx={{ fontSize: ({ typography: { size } }) => size.sm }}
                      className="fas fa-desktop"
                    />
                  }
                  label="Preview"
                />
                <Tab
                  icon={
                    <MKBox
                      component="i"
                      color="dark"
                      mr={1.25}
                      sx={{ fontSize: ({ typography: { size } }) => size.sm }}
                      className="fas fa-code"
                    />
                  }
                  label="Code"
                />
              </Tabs>
            </AppBar>
          </Grid>
        </Grid>
      </MKBox>
      <MKBox display={activeTab === 0 ? "block" : "none"}>
        <MKBox width="100%" p={3}>
          <MKBox
            bgColor="grey-100"
            width="100%"
            height={height}
            maxHeight="100%"
            borderRadius="xl"
            sx={{ overflowX: "hidden", overflowY: "hidden" }}
          >
            {children}
          </MKBox>
        </MKBox>
      </MKBox>
      <MKBox display={activeTab === 1 ? "block" : "none"} p={3}>
        <MKBox
          bgColor="grey-100"
          position="relative"
          width="100%"
          borderRadius="xl"
          sx={{ overflow: "hidden" }}
        >
          <CopyToClipboard text={codeSelected}>
            <MKButton
              variant="gradient"
              color="dark"
              size="small"
              sx={{ position: "absolute", top: "0.5rem", right: "0.5rem" }}
              onClick={() => setSuccess(true)}
            >
              <MKBox color="white" mr={0.5} className="fas fa-copy" /> Copy
            </MKButton>
          </CopyToClipboard>

          <MKBox>
            <MKButton
                variant="gradient"
                color="dark"
                size="small"
                onClick={openMenu}
                sx={{ position: "absolute", top: "0.5rem", right: "5.5rem" }}
              >
                <MKBox color="white" mr={0.5} className="fas fa-copy" /> File Menu
              </MKButton>
              <MKBox 
              sx={{ position: "absolute", top: "0.5rem", left: "5.5rem" }}
              color="black" mr={0.5} className="fas fa-copy" > {itemSelected.name}
              </MKBox>
            <MKBox
              bgColor={"white"}
              shadow={"lg"}
              borderRadius="xl"
              px={2}
              sx={{
                marginTop: "40px",
                // border:"2px solid red"
              }}
              onClick={()=>{
                setCodeMenu(false)

              }}
            >
              { codeMenu? <Drop routes={routes} open={codeMenu} navCollapse={codeMenu} menu={setCodeMenu}/>:""}
            </MKBox>
            </MKBox>
          <Slide direction="down" in={success} unmountOnExit>
            <MKBox position="absolute" top="0.5rem" left={0} width="100%">
              <MKAlert
                width="25%"
                mx="auto"
                color="success"
                sx={{ minHeight: "2.5rem !important", py: 1, justifyContent: "center" }}
              >

                <MKTypography variant="body2" color="white" fontWeight="regular">
                  Code successfully copied!
                </MKTypography>
              </MKAlert>
            </MKBox>
          </Slide>
          <SyntaxHighlighter
            language="jsx"
            style={prism}
            showLineNumbers
            customStyle={{
              height,
              maxHeight: "40rem",
              fontSize: "1rem",
              backgroundColor: grey[100],
              padding: "1rem 1rem 1rem 0.25rem",
              overflowY: "scroll",
              margin: 0,
            }}
          >
            {codeSelected}
          </SyntaxHighlighter>
        </MKBox>
      </MKBox>
    </MKBox>
  );
}

// Setting default props for the View
View.defaultProps = {
  height: "100%",
  item: {},
};

// Typechecking props for the View
View.propTypes = {
  children: PropTypes.node.isRequired,
  code: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  height: PropTypes.string,
  item: PropTypes.object,
};

export default View;

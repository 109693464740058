const About = `.page-container {
    display: flex;
    justify-content: center;
    margin: 25px;
    font-family: "Sunflower", sans-serif;
    font-size: 1rem;
}

.page-container > .content-wrapper {
    width: 1000px;
}

.page-container > .content-wrapper > #about_ass_img {
    width: 350px;
    margin: 23px 40px 20px 0px;
    float: left;
    border: 1px solid #402c89;
}

.page-container > .content-wrapper p {
    letter-spacing: 1px;
    line-height: 30px;
}



.squares-wrapper {
    display: flex;
    justify-content: center;
}

.squares-wrapper > .squares {
    width: 1000px;
    padding: 10px;
}

.squares-wrapper > .squares > .square {
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin-top: -4px;
}

.square > .img-wrapper img {
    width: 100%;
    height: 500px;
    object-fit: cover;
}

.square > .square-text-wrapper{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 30px;
    font-family: "Sunflower", sans-serif;
    font-size: 1rem;
}

.square > .square-text-wrapper h3{
    color: rgb(87, 48, 113);
    margin-top: -20px;
}

.square > .square-text-wrapper p{
    letter-spacing: 0.5px;
    line-height: 20px;
}

.spacer60 {
    height: 60px;
    width: 100%;
}
`

export default About


// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

function Information() {
const aboutmeBtnStyles = {
  opacity: 1,
  cursor: "pointer",

  "&:hover, &:focus": {
    opacity: 0.75,
    color: "rgb(95,15,200)",
  },

}

  return (
    <MKBox component="section" py={6} mt={6}>
      <Container>
        <Grid container spacing={3} item xs={12} lg={8} mx="auto">
          <MKTypography variant="h3" mb={3}>
            
          </MKTypography>
          <MKTypography variant="body2">
          I am a well-organized professional. 
          I can thrive in either an independent or collaborative role. 
          I constantly read and I am eager to learn.
            <br /> <br />
            I am self-motivated, and detail-oriented. 
            I possess strong technical, organizational, and planning skills.
            <br /> <br />
            I am an excellent communicator. 
            I interact effectively with peers, as well as with the general public. 
            I’m a dependable leader, and a great team player.
            <br /> <br />
          </MKTypography>
          <MKTypography
            component={Link}
            variant="button"
            opacity={0.7}
            textTransform="uppercase"
            fontWeight="bold"
            color="secondary"
            sx={aboutmeBtnStyles}
            href="/about"
            target="_self"
            rel="noreferrer"

          >
            More About Me
          </MKTypography>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Information;

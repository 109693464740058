import IndexHtml from "static/rock_paper_scissors/index_html";
import Styles from "static/rock_paper_scissors/style_css";
import IndexJS from "static/rock_paper_scissors/classChallenge";

const routes = [
        {
            name: "HTML",
            collapse: [
              {
                name: "index.html",
                route: "/index.html",
                component: IndexHtml,
              },
             
            ],
        },
        {
            name: "CSS",
            collapse: [
              {
                name: "styles.css",
                route: "/styles.css",
                component: Styles,
              },
             
            ],
        },
        {
            name: "JS",
            collapse: [
              {
                name: "index.js",
                route: "/classChallenge.js",
                component: IndexJS,
              },
             
            ],
        },

        

]

export default routes;
import IndexHtml from "static/js_menu/indexHTML";
import IndexJS from "static/js_menu/index";

const routes = [
        {
            name: "HTML",
            collapse: [
              {
                name: "index.html",
                route: "/index.html",
                component: IndexHtml,
              },
             
            ],
        },
        {
            name: "JavaScript",
            collapse: [
              {
                name: "index.js",
                route: "/index.js",
                component: IndexJS,
              },
             
            ],
        },

        

]

export default routes;
const complete = `body {
    font-family: Arial, sans-serif;
    margin: 0;
    padding: 20px;
    background-color: #f9f9f9;
}

.cart-pg-container {
    max-width: 800px;
    margin: auto;
    background: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

h1 {
    text-align: center;
}

table {
    width: 100%;
    border-collapse: collapse;
}

th, td {
    padding: 10px;
    border-bottom: 1px solid #ddd;
    text-align: left;
}

.product-image {
    width: 50px; /* Adjust size as needed */
    height: auto;
    margin-right: 10px;
}

input[type="number"] {
    width: 60px;
}

button {
    background-color: #28a745;
    color: white;
    border: none;
    padding: 10px;
    cursor: pointer;
    border-radius: 5px;
    margin-top: 25px;
}

button:hover {
    background-color: #218838;
}

.total {
    text-align: right;
    margin-top: 20px;
}


.row-item {
    cursor: pointer;
}


.row-item:hover {
    /* padding: 0px; */
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    position: relative;
    color: #28a745;
    

}




.split-pg-view {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-content: center;
    align-items: center;
    margin-top: 20px;
    padding: 30px;
    width: 90%;
    /* max-width: 800px; */
}

.checkout-pg-containter {
    display: block;
    flex-direction: column;
    flex-wrap: wrap;
    width: 100%;
    max-width: 800px;
    min-height: 500px;
    max-height: 600px;
    padding: 40px 20px 10px 10px;
    /* margin-right: 5px; */
    margin-bottom: 25px;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    background: white;

    
}

.shipping-display-block {

}
.shipping-display-block div{
    width: 50%;
}

.total-display-block {

}

.payment-info {
    /* border: 1px solid blue; */
    margin-top: 60px;
    margin-bottom: 25px;
    width: 90%;
}

.card-info-basic {
    display: flex;
    flex-direction: column;
    align-content: space-between;
    align-items: flex-start;
    padding: 5px;
    /* border: 1px solid green; */
}
.card-info-basic input {
    margin-top: 10px;
    height: 25px;
    width: 50%;
}

.card-info-more {
    position: relative;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    align-items: baseline;
    padding: 5px;
    /* border: 1px solid rgb(128, 70, 0); */
    height: 80px;
}

#selected_month {
    padding: 5px;
    margin-right: 5px;

}
#selected_year {
    padding: 5px;

}

.card-code-wrapper {
    padding: 15px;

}

.card-code-wrapper input {
    width: 80px;
}

.card-img-wrapper {
    margin-left: 40%;
    margin-top: 25px;
    /* border: 1px solid purple; */
}

.card-img-wrapper img {
    width: 60px;
}

.submit-btn-wrapper {
    margin-top: 25px;
    width: 50%;
}

.submit-btn-wrapper button {
    width: 100%;
}`

export default complete;
const Button = `/* Button */
.btn-wrapper {
    display: block;
    justify-content: center;
}
.btn {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgb(36, 35, 35);
    color: white;
    padding: 1px 15px;
    border: 1px solid rgb(36, 35, 35);
    border-radius: 5px;
}
.btn h3 {
    font-family: 'Open Sans', sans-serif;
    
}

/* Drop Down Button */
.drop-dwn-btn-wrapper {
    display: flex;
    justify-content: center;
}
.drop-dwn-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgb(36, 35, 35);
    color: white;
    padding: 1px 20px;
    border: 1px solid rgb(36, 35, 35);
    border-radius: 5px;
}
.drop-dwn-btn h3 {
    font-family: 'Open Sans', sans-serif;
    
}
.drop-dwn-btn i {
    margin-left: 5px;
    color: aquamarine;

}

/* Icon Button */
.icon-btn-wrapper {
    display: flex;
    justify-content: center;
}
.icon-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: transparent;
    color: rgb(46, 45, 45);
    padding: 1px 10px;
    border: 1px solid transparent;
    border-radius: 5px;
}
.icon-btn i {
    font-size: 2em;    
}
`
export default Button


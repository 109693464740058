import { initializeApp } from "firebase/app";
// import { getFirestore } from "firebase/firestore";
// import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCcjwT581yO7_QctpPSgxdDOFPciY5sCn8",
  authDomain: "project-portfolio-550a7.firebaseapp.com",
  projectId: "project-portfolio-550a7",
  storageBucket: "project-portfolio-550a7.appspot.com",
  messagingSenderId: "990205147776",
  appId: "1:990205147776:web:6b16474057fbe61c5ac465",
  measurementId: "G-R47YWSBHD5"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
// const analytics = getAnalytics(app);
// const db = getFirestore(app);
export default app;
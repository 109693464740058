const Btn = `.btn {
    background-color: #cea135;
    color: #11122b;
    font-size: 1.25em;
    padding: 10px 25px;
    border: 1px solid #cea135;
    border-radius: 2px;
    outline: none;
    transition: 0.3s;
}

.btn:active {
    box-shadow: 5px 5px 30px rgba(0, 0, 0, 0.4);
}

.center-btn-wrapper {
    display: flex;
    justify-content: center;
}`

export default Btn;
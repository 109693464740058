const chat_Desktop = `body {
    margin: 0;
    font-family: Arial, sans-serif;
}

.customer-chat-container {
    position: fixed;
    bottom: 20px;
    right: 20px;
    width: 300px;
    /* height: 300px; */
    display: flex;
    flex-direction: row-reverse;
    /* box-shadow: 0 0 10px rgba(0, 0, 0, 0.2); */
}

.chat-icon-btn-wrapper {
    background-color: #007bff;
    border-radius: 50%;
    padding: 10px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: background-color 0.3s;
}

.chat-icon-btn-wrapper:hover {
    background-color: #0056b3;
}

.chat-window-wrapper {
    display: none;
    background-color: #fff;
    border-radius: 8px;
    padding: 15px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    position: relative;
}

.chat-window-wrapper.active {
    display: block;
    width: 100%;
}

.chat-header-text {
    margin: 0 0 10px;
}

textarea {
    width: 100%;
    height: 100px;
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 3px;
    resize: none;
}

.fa-xmark {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
}

.fa-xmark:hover {
    color: #797979;
}

.chat-window-wrapper button {
    cursor: pointer;
    color: rgb(244, 244, 244);
    background-color: rgb(22, 119, 22);
    border: 0px solid transparent;
    border-radius: 5px;
    padding: 10px;
}

.chat-window-wrapper button:hover {
    background-color: rgb(76, 163, 76);

}
`
export default chat_Desktop;
const Cart = `body {
    font-family: Arial, sans-serif;
    margin: 0;
    padding: 20px;
    background-color: #f9f9f9;
}

.cart-pg-container {
    max-width: 800px;
    margin: auto;
    background: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    margin-top: 25px;
}

h1 {
    text-align: center;
}

table {
    width: 100%;
    border-collapse: collapse;
}

th, td {
    padding: 10px;
    border-bottom: 1px solid #ddd;
    text-align: left;
}

.product-image {
    width: 50px; /* Adjust size as needed */
    height: auto;
    margin-right: 10px;
}

input[type="number"] {
    width: 60px;
}

button {
    background-color: #28a745;
    color: white;
    border: none;
    padding: 10px;
    cursor: pointer;
    border-radius: 5px;
}

button:hover {
    background-color: #218838;
}

.total {
    text-align: right;
    margin-top: 20px;
}



.row-item {
    cursor: pointer;
}


.row-item:hover {
    padding: 3px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    position: relative;
    color: #28a745;
    

}


/* Mobile styles */
@media (max-width: 600px) {
    table {
        font-size: 14px;
    }
    
    button {
        width: 100%;
        padding: 15px;
    }
}
`

export default Cart
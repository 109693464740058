import IndexHtml from "static/dev_fries/index";
import AboutHtml from "static/dev_fries/about";
import ContactHtml from "static/dev_fries/contact";
import MenuHtml from "static/dev_fries/menu";

import BtnCss from "static/dev_fries/styles/buttons";
import CommonCss from "static/dev_fries/styles/common";
import ContactCss from "static/dev_fries/styles/contact";
import FooterCss from "static/dev_fries/styles/footer";
import FormCss from "static/dev_fries/styles/form";
import HelpersCss from "static/dev_fries/styles/helpers";
import HomeCss from "static/dev_fries/styles/homepage";

const routes = [
    {
            name: "HTML",
            collapse: [
              {
                name: "index.html",
                route: "/index.html",
                component: IndexHtml,
              },
              {
                name: "about.html",
                route: "/about.html",
                component: AboutHtml,
              },
              {
                name: "contact.html",
                route: "/contact.html",
                component: ContactHtml,
              },
              {
                name: "menu.html",
                route: "/menu.html",
                component: MenuHtml,
              },
             
            ],
        },
        {
                name: "CSS",
                collapse: [
                  {
                    name: "button.css",
                    route: "/styles/buttons",
                    component: BtnCss,
                  },
                  {
                    name: "common.css",
                    route: "/styles/common",
                    component: CommonCss,
                  },
                  {
                    name: "contact.css",
                    route: "/styles/contact",
                    component: ContactCss,
                  },
                  {
                    name: "footer.css",
                    route: "/styles/footer",
                    component: FooterCss,
                  },
                  {
                    name: "form.css",
                    route: "/styles/form",
                    component: FormCss,
                  },
                  {
                    name: "helpers.css",
                    route: "/styles/helpers",
                    component: HelpersCss,
                  },
                  {
                    name: "home.css",
                    route: "/styles/home",
                    component: HomeCss,
                  },
                 
                ],
            },

        

]

export default routes;
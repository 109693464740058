import Icon from "@mui/material/Icon";

// Pages
// import SingleArticle from "layouts/pages/blogs/single-article";
// import Author from "layouts/pages/blogs/author";
// import AboutUs from "layouts/pages/company/about-us";
// import ContactUs from "layouts/pages/support/contact-us";

// Sections
// import Applications from "layouts/sections/page-sections/applications";
// import ContentSections from "layouts/sections/page-sections/content-sections";
// import ContactSections from "layouts/sections/input-areas/contact-sections";

// import PageHeaders from "layouts/sections/page-sections/page-headers";
// import Features from "layouts/sections/page-sections/featuers";
// import PricingSection from "layouts/sections/page-sections/pricing";
// import FaqSection from "layouts/sections/page-sections/faq";
// import Testimonials from "layouts/sections/page-sections/testimonials";
// import Teams from "layouts/sections/page-sections/teams";
// import Stats from "layouts/sections/page-sections/stats";
// import Cta from "layouts/sections/page-sections/cta";
// import Applications from "layouts/sections/page-sections/applications";
// import LogoAreas from "layouts/sections/page-sections/logo-areas";
// import Footers from "layouts/sections/page-sections/footers";
// import GeneralCards from "layouts/sections/page-sections/general-cards";
// import ContentSections from "layouts/sections/page-sections/content-sections";
// import Navbars from "layouts/sections/navigation/navbars";
// import NavTabs from "layouts/sections/navigation/nav-tabs";
// import Pagination from "layouts/sections/navigation/pagination";
// import Newsletters from "layouts/sections/input-areas/newsletters";
// import ContactSections from "layouts/sections/input-areas/contact-sections";
// import Inputs from "layouts/sections/input-areas/inputs";
// import Forms from "layouts/sections/input-areas/forms";
// import Alerts from "layouts/sections/attention-catchers/alerts";
// import Notifications from "layouts/sections/attention-catchers/notifications";
// import Modals from "layouts/sections/attention-catchers/modals";
// import TooltipsPopovers from "layouts/sections/attention-catchers/tooltips-popovers";
// import Avatars from "layouts/sections/elements/avatars";
// import Badges from "layouts/sections/elements/badges";
// import BreadcrumbsEl from "layouts/sections/elements/breadcrumbs";
// import Buttons from "layouts/sections/elements/buttons";
// import Dropdowns from "layouts/sections/elements/dropdowns";
// import ProgressBars from "layouts/sections/elements/progress-bars";
// import SocialButtons from "layouts/sections/elements/social-buttons";
// import Tables from "layouts/sections/elements/tables";
// import Toggles from "layouts/sections/elements/toggles";
// import Typography from "layouts/sections/elements/typography";

// Created
import HomePage from "layouts/pages/home";
import About from "layouts/pages/about";
import BlogPosts from "layouts/pages/myblogposts";
import Projects from "layouts/pages/projects";
import ContactForm from "layouts/pages/contact";

const routes = [
    {
        // name: "pages",
        icon: <Icon color="primary">menu</Icon>,
        columns: 1,
        dropdown: false,
        rowsPerColumn:10,
        collapse: [
            {
                name: "",
                collapse: [
                    {
                        name: "home",
                        route: "/pages/home",
                        component: <HomePage/>
                    },
                    {
                      name: "about",
                      route: "/about",
                      component: <About />,
                    },
                    {
                      name: "blog posts",
                      route: "/blog-posts",
                      component: <BlogPosts />,
                    },
                    {
                        name: "projects",
                        route: "/projects",
                        component: <Projects />,
                    },
                    {
                      name: "contact",
                      route: "/contact",
                      component: <ContactForm />,
                    },
                ]
            },
            // {
            //     name: "",
            //     collapse: [
            //       {
            //         name: "about me",
            //         route: "/about",
            //         component: <About />,
            //       },
            //     ],
            // },
            // {
            //     name: "",
            //     collapse: [
            //       {
            //         name: "blog posts",
            //         route: "/blog-posts",
            //         component: <BlogPosts />,
            //       },
            //     ],
            // },
            // {
            //     name: "",
            //     collapse: [
            //       {
            //           name: "projects",
            //           route: "/projects",
            //           component: <Projects />,
            //       },
            //     ]
            // },
            // {
            //   name: "",
            //   collapse: [
            //     {
            //       name: "contact me",
            //       route: "/contact",
            //       component: <ContactForm />,
            //     },
            //   ],
            // },
            // {
            //   name: "page sections",
            //   description: "See all 55 sections",
            //   dropdown: true,
            //   collapse: [
            //     {
            //       name: "page headers",
            //       route: "/sections/page-sections/page-headers",
            //       component: <PageHeaders />,
            //     },
            //     {
            //       name: "features",
            //       route: "/sections/page-sections/features",
            //       component: <Features />,
            //     },
            //     {
            //       name: "pricing",
            //       route: "/sections/page-sections/pricing",
            //       component: <PricingSection />,
            //     },
            //     {
            //       name: "faq",
            //       route: "/sections/page-sections/faq",
            //       component: <FaqSection />,
            //     },
            //     {
            //       name: "blog posts",
            //       route: "/sections/page-sections/blog-posts",
            //       component: <BlogPosts />,
            //     },
            //     {
            //       name: "testimonials",
            //       route: "/sections/page-sections/testimonials",
            //       component: <Testimonials />,
            //     },
            //     {
            //       name: "teams",
            //       route: "/sections/page-sections/teams",
            //       component: <Teams />,
            //     },
            //     {
            //       name: "stats",
            //       route: "/sections/page-sections/stats",
            //       component: <Stats />,
            //     },
            //     {
            //       name: "call to actions",
            //       route: "/sections/page-sections/cta",
            //       component: <Cta />,
            //     },
            //     {
            //       name: "applications",
            //       route: "/sections/page-sections/applications",
            //       component: <Applications />,
            //     },
            //     {
            //       name: "logo areas",
            //       route: "/sections/page-sections/logo-areas",
            //       component: <LogoAreas />,
            //     },
            //     {
            //       name: "footers",
            //       route: "/sections/page-sections/footers",
            //       component: <Footers />,
            //     },
            //     {
            //       name: "general cards",
            //       route: "/sections/page-sections/general-cards",
            //       component: <GeneralCards />,
            //     },
            //     {
            //       name: "content sections",
            //       route: "/sections/page-sections/content-sections",
            //       component: <ContentSections />,
            //     },
            //   ],
            // },
            // {
            //   name: "navigation",
            //   description: "See all 3 navigations",
            //   dropdown: true,
            //   collapse: [
            //     {
            //       name: "navbars",
            //       route: "/sections/navigation/navbars",
            //       component: <Navbars />,
            //     },
            //     {
            //       name: "nav tabs",
            //       route: "/sections/navigation/nav-tabs",
            //       component: <NavTabs />,
            //     },
            //     {
            //       name: "pagination",
            //       route: "/sections/navigation/pagination",
            //       component: <Pagination />,
            //     },
            //   ],
            // },
            // {
            //   name: "input areas",
            //   description: "See all 8 input areas",
            //   dropdown: true,
            //   collapse: [
            //     {
            //       name: "newsletters",
            //       route: "/sections/input-areas/newsletters",
            //       component: <Newsletters />,
            //     },
            //     {
            //       name: "contact sections",
            //       route: "/sections/input-areas/contact-sections",
            //       component: <ContactSections />,
            //     },
            //     {
            //       name: "inputs",
            //       route: "/sections/input-areas/inputs",
            //       component: <Inputs />,
            //     },
            //     {
            //       name: "forms",
            //       route: "/sections/input-areas/forms",
            //       component: <Forms />,
            //     },
            //   ],
            // },
            // {
            //   name: "attention catchers",
            //   description: "See all 5 examples",
            //   dropdown: true,
            //   collapse: [
            //     {
            //       name: "alerts",
            //       route: "/sections/attention-catchers/alerts",
            //       component: <Alerts />,
            //     },
            //     {
            //       name: "notifications",
            //       route: "/sections/attention-catchers/notifications",
            //       component: <Notifications />,
            //     },
            //     {
            //       name: "modals",
            //       route: "/sections/attention-catchers/modals",
            //       component: <Modals />,
            //     },
            //     {
            //       name: "tooltips & popovers",
            //       route: "/sections/attention-catchers/tooltips-popovers",
            //       component: <TooltipsPopovers />,
            //     },
            //   ],
            // },
            // {
            //   name: "elements",
            //   description: "See all 32 examples",
            //   dropdown: true,
            //   collapse: [
            //     {
            //       name: "avatars",
            //       route: "/sections/elements/avatars",
            //       component: <Avatars />,
            //     },
            //     {
            //       name: "badges",
            //       route: "/sections/elements/badges",
            //       component: <Badges />,
            //     },
            //     {
            //       name: "breadcrumbs",
            //       route: "/sections/elements/breadcrumbs",
            //       component: <BreadcrumbsEl />,
            //     },
            //     {
            //       name: "buttons",
            //       route: "/sections/elements/buttons",
            //       component: <Buttons />,
            //     },
            //     {
            //       name: "dropdowns",
            //       route: "/sections/elements/dropdowns",
            //       component: <Dropdowns />,
            //     },
            //     {
            //       name: "progress bars",
            //       route: "/sections/elements/progress-bars",
            //       component: <ProgressBars />,
            //     },
            //     {
            //       name: "social buttons",
            //       route: "/sections/elements/social-buttons",
            //       component: <SocialButtons />,
            //     },
            //     {
            //       name: "tables",
            //       route: "/sections/elements/tables",
            //       component: <Tables />,
            //     },
            //     {
            //       name: "toggles",
            //       route: "/sections/elements/toggles",
            //       component: <Toggles />,
            //     },
            //     {
            //       name: "typography",
            //       route: "/sections/elements/typography",
            //       component: <Typography />,
            //     },
            //   ],
            // },
        ],
    }
]

export default routes;
import React, { Component, useState, useEffect } from 'react'
import BaseLayout from "layouts/sections/components/BaseLayout";
import View from "layouts/sections/components/CodeView";

// Applications page components
// import ApplicationOne from "layouts/sections/page-sections/applications/components/ApplicationOne";
// import ApplicationTwo from "layouts/sections/page-sections/applications/components/ApplicationTwo";

import Application from "pages/ProjectView/displayView"
// Applications page components code
// import applicationOneCode from "layouts/sections/page-sections/applications/components/ApplicationOne/code";
// import applicationTwoCode from "layouts/sections/page-sections/applications/components/ApplicationTwo/code";

function Applications(item) {

  const [codeSelected, setSelectedCode] = useState("");

    let getCode = localStorage.getItem("selected_code");
  useEffect(() => {
    let code = JSON.parse(getCode)
    // console.log("CODE SELECTED:  ===> ", code)
    setSelectedCode(code)
   
  }, [getCode]);
  return (
    <BaseLayout
      title={item.item.name}
      breadcrumb={[
        { label: "Prototypes", route: "/projects" },
        { label: `${item.item.name}` },
      ]}
    >
      {/* <View title="Application 1" code={applicationOneCode}>
        <ApplicationOne />
      </View> */}
      <View title="Application 2" code={codeSelected} item={item}>
        <Application item={item.item}/>
      </View>
    </BaseLayout>
  );
}



export default class ProjectView extends Component {
    constructor (props) {
        super(props)
        this.state = {
          prototype:{
            active:true,
            categories: [],
            details:"",
            id: "",
            image: "",
            name: "",
            page: "",
            url: "",
            year: ""
          }
        }
      }


  componentDidMount() {
    this.GetCode()

  } 

  componentDidUpdate() {
    let prototype = this.state.prototype
    if(prototype.id === ""){
    // console.log("PROTOTYPE STATE ID ----> ", prototype.id);
      this.GetCode()
    }

  }

  GetCode() {
    let getPrototype = localStorage.getItem("selected_proto");
    let prototype = JSON.parse(getPrototype);
    console.log("PROTOTYPE SELECTED ----> ", prototype);
    if(prototype !== undefined && prototype !== null){
        this.setState({prototype})
    }
  }

  render() {
    return (
      <Applications item={this.state.prototype}/>
    )
  }
}


const styles = `body {
    /* font-family: 'Oxygen', sans-serif; */
    /* font-family: 'Architects Daughter', cursive; */
    /* font-family: 'Caveat', cursive; */
    /* font-family: 'Chakra Petch', sans-serif; */
    /* font-family: 'Cormorant Infant', serif; */
    /* font-family: 'Grandstander', cursive; */
    /* font-family: 'Handlee', cursive; */
    /* font-family: 'Oregano', cursive; */
    font-family: 'Rajdhani', sans-serif;
    /* font-family: 'Stint Ultra Condensed', cursive; */
}

.rps-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    margin-left: 45px;
    width: 80vw;

}

.page-header-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 18vh;
    padding: 5px;
    /* border: 1px solid blue; */
}

.page-header-wrapper h3 {
    color: rgb(0, 55, 37);
}
.page-header-wrapper h3:hover {
    color: rgb(109, 178, 155);
}
.page-header-wrapper h3 span {
    color: rgb(250, 148, 89);
}
.page-header-wrapper h3:hover span {
    color: rgb(217, 100, 46);
}
.page-header-wrapper ul li:hover {
    font-weight: bold;
}


.win {
    color: rgb(84, 200, 144, 0.99);
}
.lose {
    color: rgb(233, 94, 86, 0.7);
}
.draw {
    color: blue;
}

.game-wrapper {
    /* border: 1px solid blue */
    font-size: 15px;
    font-weight: bold;
    margin-top: 10px;
    margin-bottom: 10px;
}

#shoot {
    height: 45px;
    width: 270px;
    border: 1px solid rgb(108, 158, 234);
    border-radius: 5px;
    background-color: transparent;
    font-family: 'Rajdhani', sans-serif;
    font-size: 18px;
    font-weight: bold;
    color: rgb(29, 134, 175);
}

#shoot:hover {
    cursor: pointer;
    border: 1px solid rgb(239, 145, 6, 0.4);
    /* background-color: rgb(6, 194, 100); */
    color: rgb(239, 145, 6, 0.7);
}

.score-wrapper {
    /* border: 1px solid rgb(0, 132, 255); */

}
.players-score-wrapper {
    display: flex;
    justify-content: space-evenly;
    /* width: 30vw; */
    /* height: 50vh; */
    font-size: 20px;
    font-weight: bold;
    /* border: 1px solid rgb(22, 158, 47); */

}


#reset {
    height: 45px;
    width: 270px;
    border: 1px solid rgb(108, 158, 234);
    border-radius: 5px;
    background-color: transparent;
    font-family: 'Rajdhani', sans-serif;
    font-size: 18px;
    font-weight: bold;
    color: rgb(29, 134, 175);
}

#reset:hover {
    cursor: pointer;
    border: 1px solid rgb(239, 29, 6, 0.4);
    /* background-color: rgb(6, 194, 100); */
    color: rgba(239, 29, 6, 0.7);
}

.first-col-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* border: 1px solid red; */
}

#last_play {
    /* height: 5px; */
}

#points_col {
    /* border: 1px solid rgb(0, 30, 255); */

}

#last_col {
    /* border: 1px solid rgb(9, 23, 128); */

}`

export default styles;
const index = `<!DOCTYPE html>
<html lang="en">
<head>
    <meta charset="UTF-8">
    <meta http-equiv="X-UA-Compatible" content="IE=edge">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
    <link href="https://fonts.googleapis.com/css2?family=Architects+Daughter&family=Caveat:wght@400;500;600;700&family=Chakra+Petch:ital,wght@0,300;0,400;1,300;1,400&family=Cormorant+Infant:wght@300;400;500&family=Grandstander:ital,wght@0,100;0,200;0,300;0,400;1,100;1,200;1,300&family=Handlee&family=Oregano:ital@0;1&family=Rajdhani:wght@300;400;500;600;700&family=Stint+Ultra+Condensed&display=swap" rel="stylesheet">
    <title>Rock Paper Scissors</title>
    <script src="./classChallenge.js"></script>
    <link rel="stylesheet" href="style.css">
    
</head>
<body>
    <div class="rps-wrapper">
        <div class="page-header-wrapper">
            <h3>Rock Paper Scissors ☄️ <span>Shoot</span></h3>
            <ul>
                <li>SHOOT to play game</li>
                <li class="">RESTART to reset game</li>
                <li class="win">Win</li>
                <li class="lose">Lose</li>
                <li class="draw">Draw</li>
                <li class="">HAVE FUN!!!!!</li>
            </ul>
        </div>
        <div class="game-wrapper">
            <div class="players-wrapper">
                <div class="player-wrapper" id="player1">
                </div>
                <div class="player-wrapper" id="player2">
                </div>           
            </div>
            <div class="btn-wrapper">
                <button type="submit" id="shoot">SHOOT</button>
            </div> 
        </div>
        <div class="score-wrapper">
            <div class="players-score-wrapper">
                <div class="first-col-wrapper">
                    <div class="player-score-wrapper" id="points_score">
                    </div> 
                    <div class="player-score-wrapper" id="last_play">
                    </div> 
                </div>
                <div class="player-score-wrapper" id="player1_score">
                </div>
                <div class="player-score-wrapper" id="player2_score">
                </div>           
            </div>
            <div class="btn-wrapper">
                <button type="submit" id="reset" onclick="startChallenge.resetGame()">RESTART</button>
            </div> 
        </div>
    </div>
</body>
<script>
    // startChallenge.rps();
    const btn = document.getElementById('shoot');
    btn.addEventListener('click', (e) => {
        const player1 = document.getElementById("player1");
        const player2 = document.getElementById("player2");

        player1.innerHTML = '';
        player2.innerHTML = '';

        let player1P = document.createElement('p');
        player1P.id = 'p1p';
        let player2P = document.createElement('p');
        player2P.id = 'p2p'

        let p1p = document.querySelectorAll('p')
        // console.log("P1P : ", p1p)


        // const players_wrapper = document.querySelectorAll('.player-wrapper');
        // players_wrapper.forEach(player => {
        //     const pTag = player.children;
        //     // const pTag = player.childNodes[3];
        //     console.log("P? ", pTag)
        //     console.log("PLAYER DIV  ---> ", player);
        //     // pTag.remove();
        // })

        player1P.innerHTML = '';
        player2P.innerHTML = '';
        

        // console.log("BTN CLICK!")
        let _play1 = $ {startChallenge.rps()};
        let _play2 = $ {startChallenge.rps()};
        let play1 = Player 1 ---- > $ {_play1};
        let play2 = Player 2 ---- > $ {_play2};

        startChallenge.rps_winner(_play1,_play2)


        player1P.innerHTML = play1;
        player2P.innerHTML = play2;

        player1.appendChild(player1P);
        player2.appendChild(player2P);

    })


// startChallenge.rps();
// startChallenge.rps();
// startChallenge.rps();
// startChallenge.rps();
// startChallenge.rps();
// startChallenge.fifty50();
// startChallenge.fifty50();
// startChallenge.fifty50();
// startChallenge.fifty50();
// startChallenge.fifty50();
// startChallenge.fifty50();
// startChallenge.fifty50();

        // player1.innerHTML = 'Click SHOOT'; 
        // player2.innerHTML = 'To Begin';
        // player1.appendChild(player1P);
        // player2.appendChild(player2P);
</script>
</html>`

export default index;
// @mui material components
import Card from "@mui/material/Card";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";

// Material Kit 2 PRO React examples
import DefaultNavbar from "examples/Navbars/DefaultNavbar";

// Author page sections
import Profile from "pages/About/sections/Profile";
import Resume from "pages/About/sections/Resume";
import Posts from "pages/About/sections/Posts";
import Footer from "components/Footer";


import View from "layouts/sections/components/TabView";

// Routes
import routes from "routes";

// Images
import randomJA from "operators/randomImages";
import bgImage from "assets/JA/yves-alarie-BXk-N2fPLYo-unsplash.jpg";

function About() {

  console.log("ABOUT PAGE --- BG IMAGE ---> ", bgImage);
  console.log("ABOUT PAGE --- Random IMAGE ---> ", randomJA);
  return (
    <>
      <DefaultNavbar
        routes={routes}
        transparent
        light
        brand={"SJA"}
      />
      <MKBox bgColor="white">
        <MKBox
          minHeight="25rem"
          width="100%"
          sx={{
            backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
              `${linearGradient(
                rgba(gradients.dark.main, 0.8),
                rgba(gradients.dark.state, 0.8)
              )}, url(${randomJA})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            display: "grid",
            placeItems: "center",
          }}
        />
        <Card
          sx={{
            p: 2,
            mx: { xs: 2, lg: 3 },
            mt: -8,
            mb: 4,
            backgroundColor: ({ palette: { white }, functions: { rgba } }) => rgba(white.main, 0.8),
            backdropFilter: "saturate(200%) blur(30px)",
            boxShadow: ({ boxShadows: { xxl } }) => xxl,
          }}
        >
          <Profile />
        </Card>
          <View 
            title="" 
            tab1={<Posts />} 
            tab2={<Resume />} 
            label1="Blogs" 
            label2="Resume"
          />  
        <Footer />
      </MKBox>
    </>
  );
}

export default About;
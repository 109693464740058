const chat_mobile = `@media (max-width: 615px) {
    body {
        margin: 0;
        font-family: Arial, sans-serif;
    }
    
    .customer-chat-container {
        position: fixed;
        bottom: 20px;
        right: 15px;
        width: 90%;
        max-width: 300px;
        background-color: transparent;
        display: flex;
        flex-direction: row-reverse;

    }
    
    .chat-icon-btn-wrapper {
        background-color: #007bff;
        border-radius: 50%;
        padding: 10px;
        /* max-width: 50px; */
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        transition: background-color 0.3s;
    }
    
    .chat-icon-btn-wrapper:hover {
        background-color: #0056b3;
    }
    
    .chat-window-wrapper {
        display: none;
        background-color: #fff;
        border-radius: 8px;
        padding: 15px;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
        position: relative;
    }
    
    .chat-window-wrapper.active {
        display: block;
    }
    
    .chat-header-text {
        margin: 0 0 10px;
    }
    
    textarea {
        width: 100%;
        height: 100px;
        border: 1px solid #ccc;
        border-radius: 4px;
        padding: 3px;
        resize: none;
    }
    
    .fa-xmark {
        position: absolute;
        top: 10px;
        right: 10px;
        cursor: pointer;
    }
    
}`

export default chat_mobile;
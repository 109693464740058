import { useEffect, useRef } from "react";

// rellax
import Rellax from "rellax";

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Link from "@mui/material/Link";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

import DefaultNavbar from "examples/Navbars/DefaultNavbar";

// About Us page sections
import Information from "pages/Home/sections/Information";
import Steps from "pages/Home/sections/Steps";
import Features from "pages/Home/sections/Features";
import Footer from "components/Footer";

// Routes
import routes from "routes";

// Images
import bgImage from "assets/swd/radowan-nakif-rehan-cYyqhdbJ9TI-unsplash.jpg";



function Home() {
    const headerRef = useRef(null);

    const contactmeBtnStyles = {
      opacity: 1,
      cursor: "pointer",
    
      "&:hover, &:focus": {
        opacity: 0.75,
      },
    
    }

    // Setting up rellax
    useEffect(() => {
      const parallax = new Rellax(headerRef.current, {
        speed: -6,
      });
  
      return () => parallax.destroy();
    }, []);
  
    return (
      <>
        <MKBox bgColor="white" py={0.25}>
          <DefaultNavbar
            routes={routes}            
            transparent={true}
            relative
            brand={"SJA"}
          />
        </MKBox>

      <MKBox
        ref={headerRef}
        minHeight="85vh"
        width="100%"
        sx={{
          backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
            `${linearGradient(
              rgba(gradients.dark.main, 0.8),
              rgba(gradients.dark.state, 0.8)
            )}, url(${bgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          display: "grid",
          placeItems: "center",
        }}
      >
        <Container>
          <Grid container item xs={12} lg={7} justifyContent="center" flexDirection="column">
            <MKTypography
              variant="h1"
              color="white"
              sx={({ breakpoints, typography: { size } }) => ({
                [breakpoints.down("md")]: {
                  fontSize: size["3xl"],
                },
              })}
            >
              Steven Anyansi
            </MKTypography>
            <MKTypography
              variant="body1"
              color="white"
              opacity={0.8}
              mb={2}
              mr={{ xs: 0, sm: 6 }}
              pr={{ xs: 0, sm: 6 }}
            >
              Software Developer
            </MKTypography>
            <MKTypography 
              variant="h5" color="white" mt={2} mb={1} 
              sx={contactmeBtnStyles}
              component={Link}
              href="/contact"
              >
              Connect with me
            </MKTypography>
            <MKBox display="flex" alignItems="center">
              <MKTypography component="a" variant="body1" color="white" href="#" mr={3}>
                <i className="fab fa-facebook" />
              </MKTypography>
              <MKTypography component="a" variant="body1" color="white" href="#" mr={3}>
                <i className="fab fa-instagram" />
              </MKTypography>
              <MKTypography component="a" variant="body1" color="white" href="#" mr={3}>
                <i className="fab fa-twitter" />
              </MKTypography>
              <MKTypography component="a" variant="body1" color="white" href="#">
                <i className="fab fa-google-plus" />
              </MKTypography>
            </MKBox>
          </Grid>
        </Container>
      </MKBox>
      <Card
        sx={{
          p: 2,
          mx: { xs: 2, lg: 3 },
          mt: -8,
          mb: 4,
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
        }}
      >
        <Information />
        <Features />
        <Steps />
      </Card>
        <Footer />
    </>
  )
}

export default Home
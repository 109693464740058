const index = `<!DOCTYPE html>
<html lang="en">
<head>
    <meta charset="UTF-8">
    <meta http-equiv="X-UA-Compatible" content="IE=edge">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>NavBar Mobile - Media Queries</title>
    <script src="https://kit.fontawesome.com/e935d53bc9.js" crossorigin="anonymous"></script>
    <link rel="preconnect" href="https://fonts.googleapis.com">
    <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
    <link href="https://fonts.googleapis.com/css2?family=Akronim&family=Comic+Neue:ital,wght@0,700;1,400&family=Rubik+Maze&display=swap" rel="stylesheet">
    <link href="https://fonts.googleapis.com/css2?family=Architects+Daughter&family=Caveat:wght@400;500;600;700&family=Chakra+Petch:ital,wght@0,300;0,400;1,300;1,400&family=Cormorant+Infant:wght@300;400;500&family=Grandstander:ital,wght@0,100;0,200;0,300;0,400;1,100;1,200;1,300&family=Handlee&family=Oregano:ital@0;1&family=Rajdhani:wght@300;400;500;600;700&family=Stint+Ultra+Condensed&display=swap" rel="stylesheet">
    <link rel="stylesheet" href="./styles/navbar.css">
    <link rel="stylesheet" href="./styles/media-queries_810.css">
    <link rel="stylesheet" href="./styles/media-queries-menu.css">

</head>
<body>
    <div class="container">
        <div class="nav-bar-container" id="navbar">
            <div class="mobile-nav-button-container toggle" id="menu">
                <i class="fa-solid fa-bars"></i>                
            </div>
            <div class="logo-container">
                <div class="logo">
                    LOGO
                </div>
            </div>
            <div class="links-container">
                <div class="link-a-container">
                    <div class="link link-type-a">
                        <a href="#">
                            <i class="fa-solid fa-house"></i>
                            <div>Home</div>
                        </a>
                    </div>
                    <div class="link link-type-a">
                        <a href="#">
                            <i class="fa-solid fa-droplet"></i>
                            <div>About</div>
                        </a>
                    </div>
                    <div class="link link-type-a">
                        <a href="#">
                            <i class="fa-regular fa-address-book"></i>
                            <div>Contact</div>
                        </a>
                    </div>
                </div>
                <div class="link-b-container">
                    <div class="link link-type-b">
                        <a href="#">Sign In</a>
                    </div>
                    <div class="link link-type-b">
                        <a href="#">Sign Up</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</body>
<script>
    const navBar = document.querySelector(".container");
    navBar.addEventListener("resize", (e) => {
        console.log("RESIZE EVENT!")
        const width = window.innerWidth;
        console.log("WIDTH ---> ", width)
        if(width > 810){
            navBar.className = "nav-bar-container"
        }
    })
    const menu = document.querySelector('#menu');
    menu.addEventListener('click', (e) => {
        console.log("CLICK EVENT!")
        // menu.parentElement.classList.toggle('active');
        const navElement = document.getElementById('navbar')
        if(navElement.className === "nav-bar-container"){
            navElement.className = "nav-bar-mobile-container"
        }else{
            navElement.className = "nav-bar-container"
        }
    })


</script>
</html>`

export default index;

const Cart = `<!DOCTYPE html>
<html lang="en">
<head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <script src="https://kit.fontawesome.com/e935d53bc9.js" crossorigin="anonymous"></script>

    <link rel="stylesheet" href="../css/home.css">
    <link rel="stylesheet" href="../css/navbar_desktop.css">
    <link rel="stylesheet" href="../css/navbar_mobile.css">
    <link rel="stylesheet" href="../css/footer_desktop.css">
    <link rel="stylesheet" href="../css/footer_mobile.css">
    <link rel="stylesheet" href="../css/chat_desktop.css">
    <link rel="stylesheet" href="../css/chat_mobile.css">
    <link rel="stylesheet" href="../css/slide_img_desktop.css">
    <link rel="stylesheet" href="../css/slide_img_mobile.css">
    <link rel="stylesheet" href="../css/grid_list_desktop.css">
    <link rel="stylesheet" href="../css/grid_list_mobile.css">
    <link rel="stylesheet" href="../css/cart.css">

    <link rel="preconnect" href="https://fonts.googleapis.com">
    <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
    <link href="https://fonts.googleapis.com/css2?family=Sunflower:wght@300;500;700&display=swap" rel="stylesheet">

    <script type="module" src="../javascript/chat.js"></script>
    <script type="module" src="../javascript/slide_img.js"></script>
    <script type="module" src="../javascript/grid-list.js"></script>
    <script type="module" src="../javascript/nav-bar.js"></script>
    <script type="module" src="../javascript/search.js"></script>
    <script type="module" src="../javascript/cart.js"></script>
    <title>Cart</title>
</head>
<body>

<div class="body-container">
    
<div class="page-wrapper">
    
    <div class="page-header-container">
        <div class="menu-bar-wrapper">
            <div class="logo-image-wrapper">
                <a href="../index.html">
                    <img src="../assets/images/ASS_01.png" alt="logo" />
                </a>
            </div>
            <div class="search-bar-wrapper">
                <div class="search-container">
                    <input type="text" class="search-input" placeholder="Search">
                    <i class="fa-solid fa-magnifying-glass"></i>
                </div>
            </div>
            <div class="menu-btns-wrapper">
                <div class="btn-container">
                    <a href="../html/about.html" class="menu-link">About</a>
                    <a href="../html/contact.html" class="menu-link">Contact</a>
                </div>
            </div>
            <div class="menu-icons-wrapper">
                <div class="icon-container">
                   <i class="fa-solid fa-earth-africa" id="menu-icon-lan"></i>
                    <a href="../html/login.html"><i class="fa-regular fa-user" id="menu-icon-user"></i></a>
                    <a href="../html/cart.html"><i class="fa-solid fa-cart-shopping" id="menu-icon-cart"></i></a>
                </div>
            </div>
        </div>
        <div class="hamburger" id="hamburger">
            <div class="logo-image-wrapper">
                <a href="../index.html">
                    <img src="../assets/images/ASS_01.png" alt="logo" />
                </a>
            </div>
                <i class="fa-solid fa-bars"></i>
        </div>
        <div class="mobile-menu" id="mobileMenu">
            
            <div class="close-btn" id="closeBtn">
                <i class="fa-solid fa-xmark"></i>
            </div>
            <div class="mobile-links">
                <a href="../html/about.html" class="menu-link">About</a>
                <a href="../html/contact.html" class="menu-link">Contact</a>
                <a href="../html/search.html" class="menu-link">Search Inventory</a>
                <a href="../html/login.html" class="menu-link">Login</a>
                <a href="../html/cart.html" class="menu-link">Cart</a>
            </div>
        </div>
    </div>

    <div class="page-title-section">
        <div class="page-title-wrapper">
            <p>Shopping Cart</p>
        </div>
        <div class="breadcrumbs-wrapper">
        <nav aria-label="Breadcrumb" class="breadcrumb">
            <ul>
              <li><a href="../index.html">Home</a></li>
              <li><span aria-current="page">Shopping Cart</span></li>
            </ul>
          </nav>
        </div>
    </div>

    <div class="cart-pg-container">
        <table id="cart" class="shop_cart">
            <thead>
                <tr>
                    <th>Product</th>
                    <th>Quantity</th>
                    <th>Price</th>
                    <th>Actions</th>
                </tr>
            </thead>
            <tbody id="cart-items">
               
            </tbody>
        </table>
        <div class="total">
            <strong>Total: $<span id="total-price">0.00</span></strong>
        </div>
        <a href="./payment.html">
            <button id="checkout-button">Proceed to Checkout</button>
        </a>
    </div>
    
    <div class="customer-chat-container">
        <div class="chat-icon-btn-wrapper">
            <i class="fa-regular fa-message"></i>
        </div>
        <div class="chat-window-wrapper">
            <i class="fa-regular fa-comment"></i>
            <i class="fa-solid fa-xmark" id="chat-close-icon"></i>
            <h4 class="chat-header-text">Chat with us</h4>
            <textarea name="chat" id="chat_textarea" placeholder="Start typing here..."></textarea>
            <button type="submit">Send</button>
        </div>
    </div>
    <div class="page-footer-wrapper">
        <div class="logo-image-wrapper">
            <img src="../assets/images/ASS_01.png" alt="logo" />
        </div>
        
        <div class="footer-links-wrapper">
            <div class="link-container">
                <a href="../html/about.html" class="footer-link">About</a>
                <a href="../html/contact.html" class="footer-link">Contact</a>
                <a href="../html/search.html" class="footer-link">Search Inventory</a>
                <a href="../html/login.html" class="footer-link">Login</a>
                <a href="../html/cart.html" class="footer-link">Cart</a>
            </div>
            <div class="copyright-container">
                &copy; <span class="footer-year">2024</span> Aleph Solar Solution &#124; All rights reserved
            </div>
        </div>
    </div>            

</div>
</div>
    
</body>
</html>`

export default Cart
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

function Resume() {
  return (
    <MKBox component="section" py={{ xs: 6, sm: 12 }}>
      <Container>
        <Grid container item xs={12} justifyContent="center" mx="auto">
          
          <Grid container justifyContent="center" py={6}>
            <Grid item xs={12} md={12} mx={{ xs: "auto", sm: 6, md: 1 }}>
              <MKBox 
                display="flex" 
                justifyContent="space-between" 
                alignItems="center" 
                mb={1}
                sx={{
                  borderBottom: ({ borders: { borderWidth, borderColor } }) =>
                    `${borderWidth[1]} solid ${borderColor}`,
                }}
                >
                <MKTypography variant="h3">Steven Anyansi</MKTypography>
              </MKBox>
              <br/>
                <br/>
              <MKTypography variant="h4">Profile</MKTypography>
              <MKTypography variant="body2" fontWeight="light" color="text">
                I am a well-organized professional. 
                I can thrive in either an independent or collaborative role. 
                I constantly read and I am eager to learn. <br />
              </MKTypography>
                <br/>
              <MKTypography variant="h4">Soft Skills</MKTypography>
              <MKTypography variant="body2" fontWeight="light" color="text">
              ● Remarkable ability to interact effectively with peers as well as with the general public. 
                <br />● Self-motivated, detail oriented, strong technical skills, strong organizational and planning skills.
                <br />● Exceptional communication skills, work well independently, dependable leader, and a great team player. 
                <br />
              </MKTypography>
                <br/>
              <MKTypography variant="h4">Tecnical Skills</MKTypography>
              <MKTypography variant="body2" fontWeight="light" color="text">
              ● Technological - Proficient in Microsoft Word, Excel, Power Point, Outlook, 
                Microsoft Windows, and internet navigation. Knowledge of PC (Windows/Mac) 
                setup and installation, as well as various peripherals, and data entry. 
                Experience in PC troubleshooting and repair. 
                <br /><br />● Development - WordPress, HTML, CSS, SCSS, PHP, Bootstrap, Angular, 
                JavaScript, TypeScript, React, React Native, Expo, NativeScript, Swift, Node.js, 
                Ajax/Axios, Java, Python, C#, SQL, Firebase, MongoDB, 
                Swagger-UI, Rest-API design, GitHub, AppStore/PlayStore deployment.
                <br />
              </MKTypography>
                <br/>
              <MKTypography variant="h4">Prototype Project</MKTypography>
              <MKTypography 
                variant="body2" 
                fontWeight="light" 
                color="text"
                sx={{"&:hover": {color: "info"}}}
                component={Link}
                href="/projects"
                target="_blank"
                rel="noreferrer">
                  ● Open Projects in new tab.
                  <br/>
              </MKTypography>
                <br/>
              <MKTypography variant="h4">Work Experience</MKTypography>
              <MKTypography 
                variant="body2" 
                fontWeight="light" 
                color="text"
                sx={{"&:hover": {color: "info"}}}
                component={Link}
                href="/pages/support/contact-us"
                target="_blank"
                rel="noreferrer">
                  ● Contact Me
                  <br/>
              </MKTypography>
                <br/>
              <MKTypography variant="h4">Education</MKTypography>
              <MKTypography 
                variant="body2" 
                fontWeight="light" 
                color="text">
          <ul>Bachelor of Science IT Programming and Software Development		</ul>
          <li>Purdue University Global, Indiana.</li>
          <li>Information Technology, Programming and Software Development.</li>
          <MKTypography ml={5}
                variant="body2" 
                fontWeight="light" 
                color="text" >
            <li>Described distributed and collaborative development concepts.</li>
            <li>Implemented a database schema with security and optimization.</li>
            <li>Planed system testing and quality assurance activities.</li>
            <li>Implemented algorithms that allow analysis and optimization.</li>
            <li>Determined agile development practices.</li>
            <li>Engaged in agile project teamwork and communication.</li>
            <li>Created a software application from a concept to a finished product.</li>
            <li>Integrated and administered secure computing technologies to accomplish user goals.</li>
            <li>4.00 GPA</li>
          </MKTypography>
      <br/>
      <ul> Full Stack Development Certificate</ul> 
      <li>Bottega University, Utah</li>
      <MKTypography ml={5}
                variant="body2" 
                fontWeight="light" 
                color="text" >
        <li>Front end development, including HTML, CSS, Sass, JavaScript, jQuery, and UI/UX.</li>
        <li>Developed in the Python programming language.</li>
        <li>Leveraged basic and advanced object-oriented programming techniques to build modern systems.</li>
        <li>Comprehensive database management that includes both SQL and NoSQL database systems.</li>
        <li>Built multiple applications, worked with modern JavaScript libraries like React.</li>
        <li>4.00 GPA</li>
        </MKTypography>

                  <br/>
              </MKTypography>
                <br/>
              <MKTypography variant="h4">Associations</MKTypography>
              <MKTypography variant="body2" fontWeight="light" color="text">
              ●	The National Society of Leadership and Success (NSLS)
              <br/>●	The National Society of Collegiate Scholars (NSCS)
              <br/>●	BK Youth Foundation - Vice President
              <br/>●	African Student Association

              </MKTypography>
                <br/>
              <MKTypography variant="h4">Hobbies</MKTypography>
              <MKTypography variant="body2" fontWeight="light" color="text">
                  ●	Chess
              <br/>●	Code Challenges
              <br/>●	Community Basketball Leagues

              </MKTypography>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Resume;

import AboutHtml from "static/AlephSolarSolution/html/about";
import CartHtml from "static/AlephSolarSolution/html/cart";
import CategoriesHtml from "static/AlephSolarSolution/html/categories";
import CompleteTransactionHtml from "static/AlephSolarSolution/html//complete_transaction";

import AboutCss from "static/AlephSolarSolution/css/about";
import AboutMobileCss from "static/AlephSolarSolution/css/about_mobile";
import CartCss from "static/AlephSolarSolution/css/cart";
import ChatDesktopCss from "static/AlephSolarSolution/css/chat_desktop";
import ChatMobileCss from "static/AlephSolarSolution/css/chat_mobile";
import CompleteCss from "static/AlephSolarSolution/css/complete";
import CompleteMobileCss from "static/AlephSolarSolution/css/complete_mobile";

import CartJs from "static/AlephSolarSolution/javascript/cart";
import CategoriesJs from "static/AlephSolarSolution/javascript/categories";
import ChatJs from "static/AlephSolarSolution/javascript/chat";

const routes = [
        {
            name: "HTML",
            collapse: [
              {
                name: "about.html",
                route: "/about.html",
                component: AboutHtml,
              },
              {
                name: "cart.html",
                route: "/cart.html",
                component: CartHtml,
              },
              {
                name: "categories.html",
                route: "/categories.html",
                component: CategoriesHtml,
              },
              {
                name: "complete transaction.html",
                route: "/complete_transaction.html",
                component: CompleteTransactionHtml,
              },
            ],
        },
        {
            name: "CSS",
            collapse: [
              {
                name: "about.css",
                route: "/about.css",
                component: AboutCss,
              },
              {
                name: "about mobile.css",
                route: "/about_mobile.css",
                component: AboutMobileCss,
              },
              {
                name: "cart.css",
                route: "/cart.css",
                component: CartCss,
              },
              {
                name: "chat desktop.css",
                route: "/chat_desktop.css",
                component: ChatDesktopCss,
              },
              {
                name: "chat mobilep.css",
                route: "/chat_mobile.css",
                component: ChatMobileCss,
              },
              {
                name: "complete.css",
                route: "/complete.css",
                component: CompleteCss,
              },
              {
                name: "complete mobile.css",
                route: "/complete_mobile.css",
                component: CompleteMobileCss,
              },
            ],
        },

        {
            name: "JavaScript",
            collapse: [
              {
                name: "cart.js",
                route: "/cart.js",
                component: CartJs,
              },
              {
                name: "categories.js",
                route: "/categories.js",
                component: CategoriesJs,
              },
              {
                name: "chat.js",
                route: "/chat.js",
                component: ChatJs,
              },
            ],
        },

]

export default routes;
import React, { Component } from 'react'
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from 'components/MKTypography';

// Material Kit 2 PRO React components
import TransparentBlogCard from "examples/Cards/BlogCards/OGTBlogCard";

import BlogPosts from "pages/MyBlogs/blogPosts/mock/blogobj";

import { getArticles } from "api/firebase-api";



function MyPostsFunction(items) {    
  let blog = [];
  let blogItems = items.blog
//    console.log("POSTS FUNCS -- BLOG ITEMS ====> ", blogItems);
  blogItems.forEach((post) => {   
   let category = post.category[0];
   let color = "primary";  
   if(category === "Software Development" ){
       color = "info"
   }else{
       if(category === "Database Management" ){
           color = "warning"
       }else{
           if(category === "Project Management" ){
               color = "secondary"
           }
       }
   }  
   let content = post.content;
   let contentArr = content.split("\n");
   post.content_array = contentArr;
   // console.log("CONTENT ARRAY ---------> ", contentArr.length);
   let BlogPost =             
           <Grid item xs={12} sm={6} lg={3}>
           <TransparentBlogCard
             image={post.featured_image}
             title={post.title}
             description={post.description}
             action={{
               type: "internal",
               route: "/blog-post",
               color: {color},
               label: "open article",
             }}
             item={post}
           />
         </Grid>
       
       
   blog.push(BlogPost)    
   })
   return (
   <>
   <MKBox coloredShadow={"none"} py={2}>
      <Container>
        <Grid container item xs={12} lg={6}>
          <MKTypography variant="h4" mb={6}>
            Latest Blog Posts
          </MKTypography>
        </Grid>
       <Grid container spacing={3}>
   {blog}
   </Grid>
   </Container>
   </MKBox>
   </>
   )
}

export default class MyPosts extends Component {

  constructor (props) {
    super(props)
    this.state = {
      blog: [],
      counter: 0,
    }
  }

  componentDidMount () {
    this.getBlog()
  }
  componentDidUpdate () {
      let blog = this.state.blog;
      let counter = this.state.counter;
      if(blog.length === 0 && counter < 6){
          this.getBlog();
          counter++
          this.setState({counter})
          // console.log("Blog Not Found ..... Trying Again......\n", blog);
      }else{
          if(blog.length === 0 && counter > 5){
              blog = BlogPosts;
              this.setState({blog})
              counter = 0
              this.setState({counter});
              // console.log("Blog Not Found ..... Loading Local Posts......\n", blog);
          }
      }

  }

  async getBlog() {
      let getPosts = await getArticles();
      let blog = [];
      // getPosts.forEach(post => {
      //     blog.push(post);
      // })
    
      for (let i=0; i<4; i++){
        blog.push(getPosts[i]);
      }
      this.setState({blog});
  }

render() {
  return (
    <MyPostsFunction blog={this.state.blog}/>
  )
}
}




// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";
// import Icon from "@mui/material/Icon";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKAvatar from "components/MKAvatar";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";

// Images
import profilePicture from "assets/about/small.jpg";

function Profile() {
  return (
    <MKBox component="section" py={{ xs: 6, sm: 12 }}>
      <Container>
        <Grid container item xs={12} justifyContent="center" mx="auto">
          <MKBox mt={{ xs: -16, md: -20 }} textAlign="center">
            <MKAvatar src={profilePicture} alt="Burce Mars" size="xxl" shadow="xl" />
          </MKBox>
          <Grid container justifyContent="center" py={6}>
            <Grid item xs={12} md={7} mx={{ xs: "auto", sm: 6, md: 1 }}>
              <MKBox display="flex" justifyContent="space-between" alignItems="center" mb={1}>
                <MKTypography variant="h3">Steven Anyansi</MKTypography>
                <MKButton 
                  variant="outlined" 
                  color="info" 
                  size="small"
                  component={Link}
                  href="/contact"
                  >
                  Contact
                </MKButton>
              </MKBox>
              <MKTypography variant="body1" fontWeight="light" color="text">
              {`I currently reside in Illinois, but I grew up on the beautiful island of Jamaica. My interests include reading, coding, swimming, and playing a variety of sports. While I consider myself quite athletic, I also embrace my inner geek! I proudly served in the military as an Aircraft Electrician in the Army National Guard.`}

              <br /><br />               
              </MKTypography>

              <MKTypography variant="body1" fontWeight="light" color="text">
              {`As awell-organized professional, I thrive in both independent and collaborative environments. I have a passion for continuous learning and am always eager to expand my knowledge.`}

              <br /><br />               
              </MKTypography>

              <MKTypography variant="body1" fontWeight="light" color="text">             
              {`I am self-motivated and detail-oriented, with strong technical, planning, and organizational skills. Excellent communication is one of my key strengths; I interact effectively with both colleagues and the general public. As a dependable leader and dedicated team player, I always strive to foster collaboration and contribute positively to any team I'm part of. My goal is to support others while driving success  as a unit.`}
              <br /><br />               
              </MKTypography>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Profile;



// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
// import Icon from "@mui/material/Icon";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKAvatar from "components/MKAvatar";

import jsImage from "assets/code_svg/programming languages/javascript.svg";
import htmlImage from "assets/code_svg/others/html.svg";
import cssImage from "assets/code_svg/others/css.svg";
import npmImage from "assets/code_svg/others/npm.svg";
import tsImage from "assets/code_svg/programming languages/typescript.svg";
import pyImage from "assets/code_svg/programming languages/python.svg";
import javaImage from "assets/code_svg/programming languages/java.svg";
import csImage from "assets/code_svg/programming languages/csharp-logo.svg";
import phpImage from "assets/code_svg/programming languages/php.png";
import nodeImage from "assets/code_svg/frameworks/nodejs.svg";
import reactImage from "assets/code_svg/frameworks/react.svg";
import reduxImage from "assets/code_svg/frameworks/redux.svg";
import jqueryImage from "assets/code_svg/frameworks/jquery.svg";
import bootstrapImage from "assets/code_svg/frameworks/boostrap.svg";
import flaskImage from "assets/code_svg/frameworks/flask.svg";
import mysqlImage from "assets/code_svg/databases/mysql.svg";
import mongodbImage from "assets/code_svg/databases/mongodb.svg";
import firebaseImage from "assets/code_svg/cloud/firebase.svg";
import gcloudImage from "assets/code_svg/cloud/gcloud.svg";
import gitImage from "assets/code_svg/others/git.svg";
import githubImage from "assets/code_svg/cloud/github.svg";


function Features() {
  const data = [
    {
      icon: jsImage,
      name: "JavaScript",
    },
    {
      icon: htmlImage,
      name: "HTML",
    },
    {
      icon: cssImage,
      name: "CSS",
    },
    {
      icon: npmImage,
      name: "NPM",
    },
    {
      icon: tsImage,
      name: "TypeScript",
    },
    {
      icon: pyImage,
      name: "Phython",
    },
    {
      icon: javaImage,
      name: "Java",
    },
    {
      icon: csImage,
      name: "C#",
    },
    {
      icon: phpImage,
      name: "PHP",
    },
    {
      icon: nodeImage,
      name: "Node",
    },
    {
      icon: reactImage,
      name: "React",
    },
    {
      icon: reduxImage,
      name: "Redux",
    },
    {
      icon: jqueryImage,
      name: "JQuery",
    },
    {
      icon: bootstrapImage,
      name: "BootStrap",
    },
    {
      icon: flaskImage,
      name: "Flask",
    },
    {
      icon: mysqlImage,
      name: "MySql",
    },
    {
      icon: mongodbImage,
      name: "MongoDB",
    },
    {
      icon: firebaseImage,
      name: "Firebase",
    },
    {
      icon: gcloudImage,
      name: "Google Cloud",
    },
    {
      icon: gitImage,
      name: "Git",
    },
    {
      icon: githubImage,
      name: "GitHub",
    },
  ];

  return (
    <MKBox component="section" py={8}>
      <Container>
        <Grid container spacing={3} item xs={12} lg={8} mx="auto">
        <MKTypography variant="h2" mb={3}>
            Experience
          </MKTypography>
          </Grid>
        <Grid container item xs={12} md={9} mx="auto">
          {data.map(({ icon, name }) => (
            <Grid key={name} item xs={12} md={4} my={2}>
              <MKBox p={2} textAlign="center" borderRadius="lg">
                <Grid container justifyContent={"center"}>
                  <MKAvatar src={icon} alt={name} size="sm" />
                </Grid>
                <MKTypography variant="h6">{name}</MKTypography>
              </MKBox>
            </Grid>
          ))}
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Features;

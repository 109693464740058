const complete_mobile = `@media(max-width: 800px) {
    .split-pg-view {
        position: relative;
        display: flex;
        flex-direction: column;
    }
    
    .checkout-pg-containter {
        position: relative;
        display: block;
        width: 100%;
        min-height: 500px;
        max-height: 600px;
        padding: 40px 20px 10px 10px;
        margin-right: 5px;
        border-radius: 10px;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
        background: white;
    }

    .shipping-display-block div{
        width: 80%;
    }

    .card-img-wrapper {
        margin-left: 30%;
        margin-top: 35px;
        /* border: 1px solid purple; */
    }

    .card-img-wrapper img {
        width: 50px;
    }

    .submit-btn-wrapper {
        margin-top: 25px;
        width: 100%;
    }

    .submit-btn-wrapper button {
        width: 100%;
    }
    table {
        font-size: 14px;
    }
    
    button {
        width: 100%;
        padding: 15px;
    }
}

@media (max-width: 600px) {
}
`
export default complete_mobile
const Index = `<!DOCTYPE html>
<html lang="en">
<head>
    <meta charset="UTF-8">
    <meta http-equiv="X-UA-Compatible" content="IE=edge">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>Front End Search Implementation</title>
    <script 
    src="https://kit.fontawesome.com/a6874c76aa.js" 
    crossorigin="anonymous"></script>
    <link href="https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700;900&display=swap" rel="stylesheet">
    <link rel="preconnect" href="https://fonts.googleapis.com">
    <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
    <link rel="stylesheet" href="./main.css">
</head>
<body>
    <div class="container container-homepage">

        <div class="homepage-logo">
            <img src="./images/ds_circle_logo.png" alt="Logo">
        </div>
        
        <div class="search-bar homepage-search-bar">
            <input type="text" placeholder="&#xf002; Search DailySmarty">
            <p>Press return to search </p>
        </div>
        
        
        <div class="recent-posts-wrapper">

            <div class="recent-posts-heading">
                Recent Posts
            </div>

            <div class="recent-posts">
                <div class="recent-post">
                    <div class="recent-post-title">
                        Guide to rendering points on a Google Map
                    </div>
                    <div class="recent-post-category">
                        Javascript
                    </div>
                </div>

                <div class="recent-post">
                    <div class="recent-post-title">
                        Guide to rendering points on a Google Map
                    </div>
                    <div class="recent-post-category">
                        Javascript
                    </div>
                </div>

                <div class="recent-post">
                    <div class="recent-post-title">
                        Guide to rendering points on a Google Map
                    </div>
                    <div class="recent-post-category">
                        Javascript
                    </div>
                </div>
            </div>

        </div>
    </div>
</body>
</html>`

export default Index;
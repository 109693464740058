import Aleph from "layouts/sections/components/CodeView/routes/aleph";
import Codepen from "layouts/sections/components/CodeView/routes/codepen";
import CssGrid from "layouts/sections/components/CodeView/routes/css_grid";
import DailySmartySearch from "layouts/sections/components/CodeView/routes/daily_smarty_search";
import DevFries from "layouts/sections/components/CodeView/routes/dev_fries";
import ErrorPg from "layouts/sections/components/CodeView/routes/error_pg";
import FlexBoxShapes from "layouts/sections/components/CodeView/routes/flexbox_shapes";
import HtmlCalendar from "layouts/sections/components/CodeView/routes/html_calendar";
import JsMenu from "layouts/sections/components/CodeView/routes/js_menu";
import MessageGen from "layouts/sections/components/CodeView/routes/message_generator";
import Navbar from "layouts/sections/components/CodeView/routes/navbar";
import RPS from "layouts/sections/components/CodeView/routes/rock_paper_scissors";
import TimeListen from "layouts/sections/components/CodeView/routes/time_listener";
import Todo from "layouts/sections/components/CodeView/routes/todo_html";
import Toggle from "layouts/sections/components/CodeView/routes/toggle_overlay";


const GetCode = (item) => {
  // console.log("CODE ITEM SELECTED ---> ", item);
  let page = item.page;
  let pages = [
    {
      "code": Aleph,
      "page": "AlephSolarSolution"
    },
    {
      "code": Codepen,
      "page": "code_pen_replica"
    },
    {
      "code": CssGrid,
      "page": "css_grid"
    },
    {
      "code": DailySmartySearch,
      "page": "daily_smarty_search"
    },
    {
      "code": DevFries,
      "page": "dev_fries"
    },
    {
      "code": ErrorPg,
      "page": "error_page_project"
    },
    {
      "code": FlexBoxShapes,
      "page": "flexbox_shapes"
    },
    {
      "code": HtmlCalendar,
      "page": "html_calendar"
    },
    {
      "code": JsMenu,
      "page": "js_menu"
    },
    {
      "code": MessageGen,
      "page": "message_generator"
    },
    {
      "code": Navbar,
      "page": "navbar_media_queries"
    },
    {
      "code": RPS,
      "page": "rock_paper_scissors"
    },
    {
      "code": TimeListen,
      "page": "time_listener"
    },
    {
      "code": Todo,
      "page": "todo_html"
    },
    {
      "code": Toggle,
      "page": "toggle_overlay"
    },
  ]

  function findPg(obj){
    return obj.page === page
  }
  let getPg = pages.find(findPg);
  // console.log("************ GET PG === ", getPg)
  if(getPg === undefined || getPg === null || getPg === -1){
    var usePg = pages[0].code
    var sel_code = usePg[0].collapse[0]
  }else{
    usePg = getPg.code;
    sel_code = usePg[0].collapse[0]
  }

  // console.log("USE PG CODE ROUTES ==.. ---->", usePg);
  // console.log("SELECTED CODE ROUTES ==.. ---->", sel_code);
  let getSavedCode = localStorage.getItem("selected_code");
  // console.log("SAVED CODE?? ---> ", getSavedCode);
  if(getSavedCode === "null" || getSavedCode === undefined || getSavedCode === null){
    localStorage.setItem("selected_code", JSON.stringify(sel_code));
    // let new_getSavedCode = localStorage.getItem("selected_code");
    // console.log("****NEW SAVED CODE?? ---> ", JSON.parse(new_getSavedCode).name);
  }

  // console.log("GOT PAGE !! ====> ", usePg);
  return usePg;
}

export default GetCode
import IndexHtml from "static/navbar_media_queries/index";
import MQ810 from "static/navbar_media_queries/styles/media-queries_810";
import MQMenu from "static/navbar_media_queries/styles/media-queries-menu";
import Navbar from "static/navbar_media_queries/styles/navbar";

const routes = [
        {
            name: "HTML",
            collapse: [
              {
                name: "index.html",
                route: "/index.html",
                component: IndexHtml,
              },
             
            ],
        },
        {
            name: "CSS",
            collapse: [
              {
                name: "media queries_810.css",
                route: "/stlyes/media-queries_810",
                component: MQ810,
              },
              {
                name: "media queries_menu.css",
                route: "/stlyes/media-queries-menu",
                component: MQMenu,
              },
              {
                name: "navbar.css",
                route: "/stlyes/navbar",
                component: Navbar,
              },             
             
            ],
        },

        

]

export default routes;
const index = `<!DOCTYPE html>
<html lang="en">
<head>
    <meta charset="UTF-8">
    <meta http-equiv="X-UA-Compatible" content="IE=edge">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>Toggle Overlay</title>
    
<style>
    img {
      width: 400px;
    }
    .site-img {
        border: 0px 40px 0px 0px solid transparent;
    }
    .container {
      position: relative;
      text-align: center;
      color: white;
    }
    .show-img-overlay {
      position: relative;
      bottom: 60px;
      font-size: 21px;
      font-family: Arial, Helvetica, sans-serif;
    }
    .hide-img-overlay {
      position: relative;
      bottom: 60px;
      font-size: 21px;
      color: transparent;
    }
  .hidden {
    display: none;
  }
  </style>
  </head>
  <body>
  
    <div class="container">
      <img src="https://s3.amazonaws.com/bottega-devcamp/browser-js/laptop.jpg" class="site-img" alt="Laptop">
      <div class="hide-img-overlay">Image Description</div>
    </div>
  
    <div class="container">
      <img src="https://s3.amazonaws.com/bottega-devcamp/browser-js/another-laptop.jpg" class="site-img" alt="Laptop">
      <div class="hide-img-overlay">Some Other Image Description</div>
    </div>

    <div class="btn-container">
        <button id="toggleBtn">Toggle</button>
    </div>
  
  </body>
  
  <!-- ***************** HOVER OVER TEXT ****************** -->
  <script>
        const imgObjs = document.querySelectorAll(".site-img");

        imgObjs.forEach(obj => {
            obj.addEventListener('mouseover', (e) => {
                let parentObj = e.target.parentElement.children[1];
                parentObj.className = "show-img-overlay";
            })
        })

        imgObjs.forEach(obj => {
            obj.addEventListener('mouseout', (e) => {
                let parentObj = e.target.parentElement.children[1];
                parentObj.className = "hide-img-overlay";
            })
        })
  </script>


  <!-- ***************** HOVER OVER - Change Style ****************** -->
  <script>
    const laptop = document.querySelectorAll('.site-img')[0];
    laptop.addEventListener('mouseover', (e)=> {
        e.target.style.borderRight = '40px solid red';
    })
    laptop.addEventListener('mouseout', (e)=> {
        e.target.style.borderRight = '40px solid transparent';
    })
  </script>


  <!-- ***************** Hide Show Toggle.  FIX##### ****************** -->

  <script>
        const containerDiv = document.querySelectorAll(".container");
        const btn = document.getElementById("toggleBtn");
        btn.addEventListener('click', (e) => {
            containerDiv.forEach(div => {
            div.classList.toggle('hidden');                
            })
        })

  </script>

</html>`

export default index;
import React, { Component } from 'react'
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
// import MKInput from "components/MKInput";
// import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";
import Footer from "components/Footer";

// Material Kit 2 PRO React examples
import DefaultNavbar from "examples/Navbars/DefaultNavbar";

// Routes
import routes from "routes";

// Image
// import bgImage from "assets/images/illustrations/illustration-reset.jpg";

function ContactForm() {
  return (
    <>
        <DefaultNavbar
          routes={routes}
          transparent
          brand={"SJA"}
        />
      <Grid container spacing={3} alignItems="center">
        <Grid
          item
          xs={12}
          sm={10}
          md={12}
          lg={12}
          xl={12}
          ml={{ xs: "auto", lg: 6 }}
          mr={{ xs: "auto", lg: 6 }}
        >
          <MKBox
            bgColor="white"
            borderRadius="xl"
            shadow="lg"
            display="flex"
            flexDirection="column"
            justifyContent="center"
            mt={{ xs: 20, sm: 18, md: 20 }}
            mb={{ xs: 20, sm: 18, md: 20 }}
            mx={3}
          >
            <MKBox
              variant="gradient"
              bgColor="secondary"
              coloredShadow="info"
              borderRadius="lg"
              p={2}
              mx={2}
              mt={-3}
            >
              <MKTypography variant="h3" color="white" >
                Contact Me
              </MKTypography>
            </MKBox>
            <MKBox p={3}>
              <MKTypography variant="body2" color="text" mt={5} mb={3}>
                
                <MKTypography variant="h6" color="info" > anyansi@estegroup.org</MKTypography>
                <MKTypography variant="h6" color="info" > https://linkedin.com/in/TheVenAny</MKTypography>
                <MKTypography variant="h6" color="info" > https://github.com/TheVenAny</MKTypography>
                <MKTypography variant="h6" color="info" > https://github.com/StevenA-IT481</MKTypography>
              </MKTypography>
              {/* <MKBox width="100%" component="form" method="post" autoComplete="off">
                <Grid container spacing={3}>
                  <Grid item xs={12} md={6}>
                    <MKInput
                      variant="standard"
                      label="Full Name"
                      InputLabelProps={{ shrink: true }}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <MKInput
                      type="email"
                      variant="standard"
                      label="Email"
                      InputLabelProps={{ shrink: true }}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <MKInput
                      variant="standard"
                      id="contact-message"
                      label="What can we help you?"
                      placeholder="Describe your problem in at least 250 characters"
                      InputLabelProps={{ shrink: true }}
                      multiline
                      fullWidth
                      type="text"
                      maxRows={8}
                    />
                  </Grid>
                </Grid>
                <Grid container item justifyContent="center" xs={12} mt={5} mb={2}>
                  <MKButton type="submit" variant="gradient" color="info">
                    Send Message
                  </MKButton>
                </Grid>
              </MKBox> */}
            </MKBox>
          </MKBox>
        </Grid>
      </Grid>
      <MKBox pt={6} px={1} mt={6}>
        <Footer />
      </MKBox>
    </>
  );
}



export default class index extends Component {

  componentDidMount() {
    // this.formControl()
  }
  formControl (){ 
    let message = document.getElementById("contact-message");
    message.addEventListener("input", e => {
      let val = e.target.value;
      // console.log("Value ==> ", val, "\nTarget Value --> ", e.target.value);
      let valCount = val.length;
      // console.log("MESSAGE LENGTH TYPE ===> ", valCount);
      if(valCount > 499) {
        console.log("VAL COUNT GREATER THAN 499 ---> ", valCount)
        message.innerHTML = "";
        message.innerHTML = val.substring(0,499);
      }
    })
    message.addEventListener("paste", e => {
      let val = e.target.value;
      // console.log("Value ==> ", val, "\nTarget Value --> ", e.target.value);
      let valCount = val.length;
      // console.log("MESSAGE LENGTH PASTE ===> ", valCount);
      if(valCount > 499) {
        console.log("PASTE VAL COUNT GREATER THAN 499 ---> ", valCount)
        message.innerHTML = "";
        // message.innerHTML = val.substring(0,499);
      }
    })
  }

  render() {
    return (
      <ContactForm />
    )
  }
}


import { persistCombineReducers } from "redux-persist";
import storage from 'redux-persist/lib/storage';


import data_base from './reducers.js';

const rootPersistConfig = {
    key: 'root',
    storage: storage,
    blacklist: ['post'],
}

export default persistCombineReducers(rootPersistConfig, {
    data_base
});


const About = `<!DOCTYPE html>
<html lang="en">
<head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <script src="https://kit.fontawesome.com/e935d53bc9.js" crossorigin="anonymous"></script>

    <link rel="stylesheet" href="../css/home.css">
    <link rel="stylesheet" href="../css/navbar_desktop.css">
    <link rel="stylesheet" href="../css/navbar_mobile.css">
    <link rel="stylesheet" href="../css/footer_desktop.css">
    <link rel="stylesheet" href="../css/footer_mobile.css">
    <link rel="stylesheet" href="../css/chat_desktop.css">
    <link rel="stylesheet" href="../css/chat_mobile.css">
    <link rel="stylesheet" href="../css/slide_img_desktop.css">
    <link rel="stylesheet" href="../css/slide_img_mobile.css">
    <link rel="stylesheet" href="../css/grid_list_desktop.css">
    <link rel="stylesheet" href="../css/grid_list_mobile.css">
    <link rel="stylesheet" href="../css/about.css">
    <link rel="stylesheet" href="../css/about_mobile.css">

    <link rel="preconnect" href="https://fonts.googleapis.com">
    <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
    <link href="https://fonts.googleapis.com/css2?family=Sunflower:wght@300;500;700&display=swap" rel="stylesheet">

    <script type="module" src="../javascript/chat.js"></script>
    <script type="module" src="../javascript/slide_img.js"></script>
    <script type="module" src="../javascript/grid-list.js"></script>
    <script type="module" src="../javascript/nav-bar.js"></script>
    <script type="module" src="../javascript/search.js"></script>
    <title>About Aleph Solar</title>
</head>
<body>

    <div class="body-container"></div>
        <div class="page-wrapper">
            
            <div class="page-header-container">
                <div class="menu-bar-wrapper">
                    <div class="logo-image-wrapper">
                        <a href="../index.html">
                            <img src="../assets/images/ASS_01.png" alt="logo" />
                        </a>
                    </div>
                    <div class="search-bar-wrapper">
                        <div class="search-container">
                            <input type="text" class="search-input" placeholder="Search">
                            <i class="fa-solid fa-magnifying-glass"></i>
                        </div>
                    </div>
                    <div class="menu-btns-wrapper">
                        <div class="btn-container">
                            <a href="../html/about.html" class="menu-link">About</a>
                            <a href="../html/contact.html" class="menu-link">Contact</a>
                        </div>
                    </div>
                    <div class="menu-icons-wrapper">
                        <div class="icon-container">
                           <i class="fa-solid fa-earth-africa" id="menu-icon-lan"></i>
                            <a href="../html/login.html"><i class="fa-regular fa-user" id="menu-icon-user"></i></a>
                            <a href="../html/cart.html"><i class="fa-solid fa-cart-shopping" id="menu-icon-cart"></i></a>
                        </div>
                    </div>
                </div>
                <div class="hamburger" id="hamburger">
                    <div class="logo-image-wrapper">
                        <a href="../index.html">
                            <img src="../assets/images/ASS_01.png" alt="logo" />
                        </a>
                    </div>
                        <i class="fa-solid fa-bars"></i>
                </div>
                <div class="mobile-menu" id="mobileMenu">
                    
                    <div class="close-btn" id="closeBtn">
                        <i class="fa-solid fa-xmark"></i>
                    </div>
                    <div class="mobile-links">
                        <a href="../html/about.html" class="menu-link">About</a>
                        <a href="../html/contact.html" class="menu-link">Contact</a>
                        <a href="../html/search.html" class="menu-link">Search Inventory</a>
                        <a href="../html/login.html" class="menu-link">Login</a>
                        <a href="../html/cart.html" class="menu-link">Cart</a>
                    </div>
                </div>
            </div>
            <div class="page-title-section">
                <div class="page-title-wrapper">
                    <p>About Us</p>
                </div>
                <div class="breadcrumbs-wrapper">
                <nav aria-label="Breadcrumb" class="breadcrumb">
                    <ul>
                      <li><a href="../index.html">Home</a></li>
                      <li><span aria-current="page">About</span></li>
                    </ul>
                  </nav>
                </div>
            </div>

            <div class="page-container">
                <div class="content-wrapper">
                    <img src="../assets/images/products/Designer (2).jpeg" alt="Solar equipments and applications" id="about_ass_img">
                    <p>Welcome to Aleph Solar Solution, where our commitment to sustainable energy meets innovative technology. Founded in 2018 we emerged from a passion for renewable energy and a vision to make solar power accessible to everyone. In a world increasingly focused on sustainability, we believe that harnessing the sun’s energy not only benefits the environment but also empowers individuals and businesses to take control of their energy needs. As a new player in the solar equipment market, we have quickly established ourselves as a trusted name, thanks to our high-quality products, dedicated service, and a forward-thinking approach to renewable energy solutions.</p>
                    <p>At Aleph Solar Solution, we offer a comprehensive range of solar products designed to meet the diverse needs of our customers. From residential solar panels to commercial energy systems, we are committed to providing solutions that enhance energy efficiency and reduce carbon footprints. Our products are carefully selected from industry-leading manufacturers, ensuring that our customers receive only the best in performance and reliability. Beyond just selling equipment, we see ourselves as partners in your journey toward sustainable energy. Our expert team is here to guide you through every step, from initial consultation to installation and maintenance, ensuring that you find the right solutions tailored to your specific needs.</p>
            
                </div>
            </div>
            <div class="spacer60"></div>
            <div class="squares-wrapper">
                <div class="squares">
                    <div class="square">
                        <div class="img-wrapper">
                            <img src="../assets/images/products/Designer (4)_3.jpeg" alt="about_ass_img_sqr1">
                        </div>
                        <div class="square-text-wrapper">
                            <h3>What's Special About Aleph Solar Solution?</h3>
                            <p>What sets Aleph Solar Solution apart in a competitive marketplace is our unwavering dedication to customer satisfaction. We understand that transitioning to solar energy can be a significant decision, and we strive to make the process as seamless and transparent as possible. Our knowledgeable staff is always available to answer your questions, provide insights, and offer personalized recommendations. We prioritize building long-term relationships with our customers, grounded in trust and transparency. By putting you at the center of everything we do, we aim to create a community that not only supports sustainable practices but also educates and empowers individuals to make informed choices about their energy consumption.</p>
                        </div>
                    </div>
                    <div class="square">
                        <div class="square-text-wrapper">
                            <h3>Future Plans.</h3>
                            <p>As we look to the future, our vision is clear: we want to lead the charge toward a cleaner, greener world. Establishing a robust online presence is a critical step in this journey. A dedicated website will allow us to showcase our extensive range of products, share success stories, and provide valuable resources to our customers. It will also enable us to reach a wider audience, making our solar solutions available to those who may not yet know about us. We recognize that in today's digital age, an online platform is essential for growth and engagement. By investing in our online presence, we aim to not only expand our customer base but also foster a community that values sustainability and innovation. Join us as we pave the way for a brighter, solar-powered future!</p>
                        </div>
                        <div class="img-wrapper">
                            <img src="../assets/images/products/Designer (4)_2.jpeg" alt="about_ass_img_sqr2">
                        </div>
                    </div>
                </div>
            </div>

                        
            <div class="customer-chat-container">
                <div class="chat-icon-btn-wrapper">
                    <i class="fa-regular fa-message"></i>
                </div>
                <div class="chat-window-wrapper">
                    <i class="fa-regular fa-comment"></i>
                    <i class="fa-solid fa-xmark" id="chat-close-icon"></i>
                    <h4 class="chat-header-text">Chat with us</h4>
                    <textarea name="chat" id="chat_textarea" placeholder="Start typing here..."></textarea>
                    <button type="submit">Send</button>
                </div>
            </div>
            <div class="page-footer-wrapper">
                <div class="logo-image-wrapper">
                    <img src="../assets/images/ASS_01.png" alt="logo" />
                </div>
                
                <div class="footer-links-wrapper">
                    <div class="link-container">
                        <a href="../html/about.html" class="footer-link">About</a>
                        <a href="../html/contact.html" class="footer-link">Contact</a>
                        <a href="../html/search.html" class="footer-link">Search Inventory</a>
                        <a href="../html/login.html" class="footer-link">Login</a>
                        <a href="../html/cart.html" class="footer-link">Cart</a>
                    </div>
                    <div class="copyright-container">
                        &copy; <span class="footer-year">2024</span> Aleph Solar Solution &#124; All rights reserved
                    </div>
                </div>
            </div>            

        </div>
    </div>
    
</body>
</html>`

export default About
const index = `<!DOCTYPE html>
<html lang="en">
<head>
    <meta charset="UTF-8">
    <meta http-equiv="X-UA-Compatible" content="IE=edge">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title></title>
    <style>
        body {
            margin: 0;
            padding: 0;
        }
        .container {
            margin: 0;
            height: calc(100vh - 20px);
            width: calc(100vw - 20px);
            border: 10px solid rgb(15, 49, 143);
            display: flex;
            justify-content: center;
            align-items: center;
        }
        /* .container > .img_blocks {
            display: flex;
            justify-content: space-around;
        } */

        .blocks {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 1000px;
        }
        .block {
            background-color: cadetblue;
            height: 200px;
            margin: 20px;
            display: flex;
            justify-content: center;
            align-items: center;
        }
        .block1 {
            flex-basis: 400px;
            /* flex-grow: 4; */
            flex-shrink: 2;
            /* order: 3; */
        }
        .block2 {
            flex-basis: 100px;
            /* flex-grow: 1; */
            /* flex-shrink: 2; */
            /* order: 2; */
        }
        .block3 {
            flex-basis: 400px;
            /* flex-grow: 2; */
            /* flex-shrink: 1; */
            /* order: 1; */
        }

    </style>
</head>
<body>
    <div class="container">
        <div class="blocks">
            <div class="block block1">1</div>
            <div class="block block2">2</div>
            <div class="block block3">3</div>
        </div>
        <!-- <div class="img_blocks">
            <img src="https://source.unsplash.com/random/150x150" alt="random">
            <img src="https://source.unsplash.com/random/150x150" alt="random">
            <img src="https://source.unsplash.com/random/150x150" alt="random">
        </div> -->
    </div>
</body>
</html>`

export default index;
import IndexHtml from "static/daily_smarty_search/index";
import ResultHtml from "static/daily_smarty_search/result";
import MainCss from "static/daily_smarty_search/main";
import MQ625Css from "static/daily_smarty_search/media-queries";

const routes = [
        {
            name: "HTML",
            collapse: [
              {
                name: "index.html",
                route: "/index.html",
                component: IndexHtml,
              },
              {
                name: "resultt.html",
                route: "/result.html",
                component: ResultHtml,
              },
             
            ],
        },
        {
            name: "CSS",
            collapse: [
              {
                name: "main.css",
                route: "/main.css",
                component: MainCss,
              },
              {
                name: "media queries_625.css",
                route: "/media_queries_625.css",
                component: MQ625Css,
              },
             
            ],
        },

        

]

export default routes;
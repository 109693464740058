const Result = `<!DOCTYPE html>
<html lang="en">
<head>
    <meta charset="UTF-8">
    <meta http-equiv="X-UA-Compatible" content="IE=edge">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>Front End Search Results</title>
    <script 
    src="https://kit.fontawesome.com/a6874c76aa.js" 
    crossorigin="anonymous"></script>
    <link href="https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700;900&display=swap" rel="stylesheet">
    <link rel="preconnect" href="https://fonts.googleapis.com">
    <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
    <link rel="stylesheet" href="./main.css">
    <link rel="stylesheet" href="./media-queries.css">
</head>
<body> <div class="container container-results">

    <div class="results-logo">
        <img src="./images/ds_circle_logo.png" alt="Logo">
    </div>
    
    <div class="search-bar results-search-bar">
        <input type="text" placeholder="&#xf002; Search DailySmarty">
    </div>
    
    
    <div class="results-posts-wrapper">
        <div class="post">
            <div class="category-name">
                Javascript
            </div>
            <div class="result-post-title">
                <a href="#">
                    Guide to rendering post on a Google Map in Rails
                </a>
            </div>
            <div class="result-post-links-wrapper">
                <div class="result-post-link-wrapper">
                    <a href="#">Lorem ipsum dolor sit amet consectetur adipisicing elit.</a>
                </div>
                <div class="result-post-link-wrapper">
                    <a href="#">Lorem ipsum dolor sit amet consectetur adipisicing elit.</a>
                </div>
                <div class="result-post-link-wrapper">
                    <a href="#">Lorem ipsum dolor sit amet consectetur adipisicing elit.</a>
                </div>
                <div class="result-post-link-wrapper">
                    <a href="#">Lorem ipsum dolor sit amet consectetur adipisicing elit.</a>
                </div>
            </div>
        </div>
        <div class="post">
            <div class="category-name">
                Javascript
            </div>
            <div class="result-post-title">
                <a href="#">
                    Guide to rendering post on a Google Map in Rails
                </a>
            </div>
        </div>
        <div class="post">
            <div class="category-name">
                Javascript
            </div>
            <div class="result-post-title">
                <a href="#">
                    Guide to rendering post on a Google Map in Rails
                </a>
            </div>
        </div>
        <div class="post">
            <div class="category-name">
                Javascript
            </div>
            <div class="result-post-title">
                <a href="#">
                    Guide to rendering post on a Google Map in Rails
                </a>
            </div>
        </div>
        <div class="post">
            <div class="category-name">
                Javascript
            </div>
            <div class="result-post-title">
                <a href="#">
                    Guide to rendering post on a Google Map in Rails
                </a>
            </div>
        </div>

    </div>
</div>
    
</body>
</html>`
export default Result;
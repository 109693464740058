const MQ810 = `@media (max-width: 810px) {
    .container > .nav-bar-container {
        display: flex;
        justify-content: space-between;
        /* flex-direction: column; */
        height: 75px;
        /* border: 1px solid purple; */
        margin: -5px -7px;
        background-color: rgb(6, 14, 106);
    }
    /* .container > .nav-bar-container.toggle {
        background-color: azure;
        color: black;
    } */
    
    .container > .nav-bar-container .links-container {
        display: flex;
        flex: 0;
        justify-content: center;
        display: none;
        opacity: 0;
        visibility: hidden;
        width: 0px;
    }

    .container > .nav-bar-container .mobile-nav-button-container {
        display: flex;
        flex: 1;
        justify-content: flex-start;
        align-items: center;
        opacity: 1;
        visibility: visible;
        /* border: 1px solid rgba(47, 232, 56, 0.482); */
        padding-left: 20px;
    }
    .container > .nav-bar-container .mobile-nav-button-container:hover {
        cursor: pointer;
        opacity: 0.8;
    }

    .container > .nav-bar-container .logo-container {
        display: flex;
        flex: 0.2;
        justify-content: center;
        align-items: center;
        padding-right: 10px;
        /* border: 1px solid rgba(132, 52, 52, 0.482); */
    }
}


@media (max-width: 810px) {
    .nav-bar-container.toggle {
        background-color: #223047;
        color: white;
    }
    .container > .nav-bar-container .logo-container {
        /* text-align: left; */
    }
    .nav-bar-container {
        display: block;
    }
    
}`
export default MQ810;

       const NavBar = `.container {
            margin: 0px;
            color: aliceblue;
        }
        .container > .nav-bar-container {
            display: flex;
            height: 75px;
            /* border: 1px solid purple; */
            margin: -5px -7px;
            background-color: rgb(6, 14, 106);
            border-bottom-right-radius: 5px;
            border-bottom-left-radius: 5px;
            /* background-color: black; */
        }
        /* .container > .nav-bar-container.toggle {
            display: block;
            padding: 1em;
            color: red;
        } */
        .container > .nav-bar-container .mobile-nav-button-container {
            display: flex;
            flex: 0;
            justify-content: center;
            align-items: center;
            display: none;
            /* opacity: 0;
            visibility: hidden; */
        }

        .container > .nav-bar-container .logo-container {
            display: flex;
            flex: 0.3;
            justify-content: center;
            align-items: center;
        }
        .container > .nav-bar-container .logo-container > .logo {
            display: flex;
            flex: 0.8;
            justify-content: center;
            /* height: 100%; */
            font-size: 40px;
            /* font-family: 'Akronim', cursive; */
            /* font-family: 'Comic Neue', cursive; */
            font-family: 'Rubik Maze', cursive;
        }
        .container > .nav-bar-container .logo-container > .logo:hover {
            cursor: pointer;
            opacity: 0.8;
            /* border: 1px solid rgba(32, 71, 162, 0.482); */
        }


        .container > .nav-bar-container .links-container {
            display: flex;
            flex: 1;
            justify-content: space-between;
            /* border: 1px solid rgba(132, 52, 52, 0.482); */
        }
        .container > .nav-bar-container .links-container > .link-a-container {
            display: flex;
            flex: 0.4;
            justify-content: center;
            align-items: center;
            /* border: 1px solid green; */
        }
        .container > .nav-bar-container .links-container > .link-b-container {
            flex: 0.3;
            display: flex;
            justify-content: center;
            align-items: center;
            /* border: 1px solid yellow; */
            /* margin-right: 10px; */
        }
        .link {
            display: flex;
            flex-direction: column;
            flex: 1;
            justify-content: center;
            text-align: center;
            height: 100%;
        }

        .link a {
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            text-decoration: none;
            color: aliceblue;       
            /* font-family: 'Akronim', cursive;   */
            font-family: 'Comic Neue', cursive;
            font-size: 25px;
            /* border: 1px solid rgb(123, 23, 181);    */
        }
        .link a i {
            font-size: 18px;
            margin-right: 3px;
            margin-top: 0px;
            margin-bottom: 6px;
            filter: drop-shadow(inset 3px 3px rgba(0,0,0,0.3));
            /* border: 1px solid rgb(123, 23, 181);   */
        }
        .link-type-a {
            flex: 1;
            /* border: 1px solid rgb(23, 168, 181);             */
        }
        .link-type-a:hover {
            opacity: 0.6;
            /* background-color: rgba(60, 57, 57, 0.3); */
        }

        .link-type-b {
            /* border: 1px solid rgb(23, 168, 181);             */
        }
        .link-type-b:hover {
            opacity: 0.6;
            /* background-color: rgba(60, 57, 57, 0.3); */
        }
        .link-type-a a {
            display: flex;
            justify-content: center;
        }
        .link-type-b a {
            font-size: 18px;
        }

        .link-type-a a:hover {
            color: rgb(23, 168, 181);
            cursor: pointer;
        }
        .link-type-b a:hover {
            color: rgb(23, 168, 181);
            cursor: pointer;
        } `

        export default NavBar;

import { 
    // getFirestore, 
    collection, 
    doc,  
    query, 
    where, 
    getDocs,
    getDoc,
    addDoc,
    updateDoc,
    orderBy,
    onSnapshot
} from "firebase/firestore";

// import "regenerator-runtime/runtime.js";

import {db} from 'api/firebase-cache'

// import app from 'api/firebase-app'

// const db = getFirestore(app);






export const getStaticSites = async() => {
    const q = query(collection(db, "projects"), where("active", "==", true));

    const staticLinks = [];
    try {
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
    // doc.data() is never undefined for query doc snapshots
    // console.log(doc.id, " => ", doc.data());
    staticLinks.push(doc.data())
    });
    // console.log("STATIC LINKS COUNT ---> ", staticLinks.length);
    return staticLinks
    }catch(e){
        console.log("CATCH ERROR - getStaticSite() ---> ", e );
    }
} 

export const getStaticSitesCache = async() => {
    const q = query(collection(db, "projects"), where("active", "==", true));

    try {
    // const staticLinks = [];
            let doc_arr = [];
       onSnapshot(q, (querySnapshot) => {
            querySnapshot.forEach((doc) => {
               doc_arr.push(doc.data())
            })
            // console.log("DOC Arr  ... ---> ", doc_arr);
        })
        let docPromise = new Promise ((resolve, reject) => {
            setTimeout(() => {
                resolve(doc_arr)
            },500)
            setTimeout(() => {
                reject(doc_arr)
            },500)
        })
        return docPromise
    }catch(e){
        console.log("CATCH ERROR - getStaticSiteCache() ---> ", e );
    }
} 

export const getStaticSite = async(id) => {
    const docRef = doc(db, "projects", id);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
        // console.log("Document data:", docSnap.data());
        return docSnap.data();     
    } else {
        // doc.data() will be undefined in this case
        console.log("No such document with ID =====> ", id);
        return `No such document with ID =====>  ${id})`
    }
}


export const addArticle = async(article) => {
    console.log("ADDING ARTICLE ----> ", article.title);
    const docRef = await addDoc(collection(db, "articles"), article);
    console.log("Document written with ID: ", docRef.id);
    let id = docRef.id;

    const updateRef = doc(db, "articles", id);

    await updateDoc(updateRef, {
    "id": id
    })
    console.log("Document UPDATED  --- ID --->  ", id);
}


export const getArticles = async() => {
    const q = query(collection(db, "blogPosts"), where("blog_status", "==", "published"), orderBy("date_created", "desc"));

    const articles = [];
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
    // doc.data() is never undefined for query doc snapshots
    // console.log(doc.id, " => ", doc.data());
    articles.push(doc.data())
    });
    // console.log("ARTICLE COUNT ---> ", articles.length);
    return articles
} 


export const getArticle = async(id) => {
    const docRef = doc(db, "blogPosts", id);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
        // console.log("Document data:", docSnap.data());
        return docSnap.data();     
    } else {
        // doc.data() will be undefined in this case
        // console.log("No such document with ID =====> ", id);
        return `No such document with ID =====>  ${id})`
    }
}


const challenge = `const score_array = []

class Challenge {


    rps = (num=null) => {
        // console.log("RPS.... CHALLENGE!!!");
        let list = ['rock', 'paper', 'scissors'];   
        function getRandom(max){
            return Math.floor(Math.random() * max)
        }
        const useNum = num===null? getRandom(3) : num;
        const shoot = list[useNum];
        // console.log("SHOOT -->", shoot)
        return shoot;
        
    }

    rps_winner = (play1,play2) => {
        // console.log("PLAY 1 --> ", play1);
        // console.log("PLAY 2 --> ", play2);

        let p1_points = 0;
        let p2_points = 0;
        
        if(play1 === play2){
            var winner = "None";
            p1_points = 0;
            p2_points = 0;
        }else{
            if(play1 === "rock"){
                if(play2 === "paper"){
                    var winner = "Player 2";
                    p1_points = 0;
                    p2_points = 1;
                }else{
                    var winner = "Player 1";  
                    p1_points = 1;
                    p2_points = 0;              
                }            
            }
            if(play1 === "paper"){
                if(play2 === "scissors"){
                    var winner = "Player 2";
                    p1_points = 0;
                    p2_points = 1;
                }else{
                    var winner = "Player 1";  
                    p1_points = 1;
                    p2_points = 0;              
                }            
            }
            if(play1 === "scissors"){
                if(play2 === "rock"){
                    var winner = "Player 2";
                    p1_points = 0;
                    p2_points = 1;
                }else{
                    var winner = "Player 1";  
                    p1_points = 1;
                    p2_points = 0;              
                }            
            }
        }

        let scoreObj = {
            winner: winner,
            points: {
                player_1: p1_points,
                player_2: p2_points,
            },
            plays: {
                player_1: play1,
                player_2: play2,
            },
        }
        this.updateScore(scoreObj);

        // console.log("THE WINNER IS ........... --> ", winner)

        this.highlightWinner(scoreObj)
    }


    updateScore = (scoreObj) => {
        score_array.push(scoreObj)
        // console.log("THE SCORE OBJ IS ........... --> ", scoreObj)
        // console.log("THE SCORE ARRAY IS ........... --> ", score_array)

        
        const initialValue = 0;
        const sumPlayer1Score = score_array.reduce(
              (accumulator, currentValue) => accumulator + currentValue.points.player_1,
              initialValue
            )

        const sumPlayer2Score = score_array.reduce(
                (accumulator, currentValue) => accumulator + currentValue.points.player_2,
                initialValue
            )

        // console.log("PLAYER 1 SCORE --------> ", sumPlayer1Score);
        // console.log("PLAYER 2 SCORE --------> ", sumPlayer2Score);


        const player1 = document.getElementById("player1_score");
        const player2 = document.getElementById("player2_score");

        const pointsScore = document.getElementById("points_score");
        const lastPlay = document.getElementById("last_play");

        player1.innerHTML = '';
        player2.innerHTML = '';
        pointsScore.innerHTML = '';
        lastPlay.innerHTML = '';

        let player1P = document.createElement('p');
        player1P.id = 'p1s';
        let player2P = document.createElement('p');
        player2P.id = 'p2s'

        let pointsCol = document.createElement('p');
        pointsCol.id = 'points_col';
        let lastCol = document.createElement('p');
        lastCol.id = 'last_col';
        

        player1P.innerHTML = '';
        player2P.innerHTML = '';

        pointsCol.innerHTML = '';
        lastCol.innerHTML = '';
        



        // console.log("BTN CLICK!")
        let play1 = Player 1  <br/><h1>$ {sumPlayer1Score}</h1> <br/> <br/><br/>$ {scoreObj.plays.player_1};
        let play2 = Player 2 <br/><h1>$ {sumPlayer2Score}</h1> <br/><br/><br/> $ {scoreObj.plays.player_2};

        let points_col = <br/><br/>Points:;
        let last_col = <br/><br/><br/>Play:;



        player1P.innerHTML = play1;
        player2P.innerHTML = play2;

        pointsCol.innerHTML = points_col;
        lastCol.innerHTML = last_col;

        player1.appendChild(player1P);
        player2.appendChild(player2P);

        pointsScore.appendChild(pointsCol);
        lastPlay.appendChild(lastCol);


    }


    resetGame = () => {
        const player1 = document.getElementById("player1");
        const player2 = document.getElementById("player2");

        const player1_score = document.getElementById("player1_score");
        const player2_score = document.getElementById("player2_score");

        const pointsScore = document.getElementById("points_score");
        const lastPlay = document.getElementById("last_play");


        player1.innerHTML = '';
        player2.innerHTML = '';

        player1_score.innerHTML = '';
        player2_score.innerHTML = '';

        pointsScore.innerHTML = '';
        lastPlay.innerHTML = '';

        score_array.splice(0,score_array.length);
    }


    highlightWinner = (scoreObj) => {
        const player1 = document.getElementById("player1");
        const player2 = document.getElementById("player2");

        const player1_score = document.getElementById("player1_score");
        const player2_score = document.getElementById("player2_score");

        const pointsScore = document.getElementById("points_score");
        const lastPlay = document.getElementById("last_play");

        let winner = scoreObj.winner
        if(winner === "Player 1"){
            player1.style.color = "rgb(84, 200, 144, 0.99)";
            player2.style.color = "rgb(233, 94, 86, 0.7)";
        }else{
            if(winner === "Player 2"){
                player2.style.color = "rgb(84, 200, 144, 0.99)";
                player1.style.color = "rgb(233, 94, 86, 0.7)";
            }else{
                player2.style.color = "blue";
                player1.style.color = "blue";
            }
        }

        let player1_points = Number(scoreObj.points.player_1);
        let player2_points = Number(scoreObj.points.player_2);
        // console.log("PLAYER 1 POINTS ---> ", player1_points);
        // console.log("PLAYER 2 POINTS ---> ", player2_points);
        // console.log("SCORE OBJ = ", scoreObj)
        if(player1_points > player2_points){
            player1_score.style.color = "rgb(84, 200, 144, 0.99)";
            player2_score.style.color = "rgb(233, 94, 86, 0.7)";
        }else{
            if(player1_points < player2_points){
                player2_score.style.color = "rgb(84, 200, 144, 0.99)";
                player1_score.style.color = "rgb(233, 94, 86, 0.7)";
            }else{
                    player1_score.style.color = "blue";
                    player2_score.style.color = "blue";
            }
        }

        // scoreObj = {
        //     winner: winner,
        //     points: {
        //         player_1: p1_points,
        //         player_2: p2_points,
        //     },
        //     plays: {
        //         player_1: play1,
        //         player_2: play2,
        //     },
        // }
    }


    fifty50 = (selected=null) => {
        // console.log("Pick 2.... CHALLENGE!!!");
        let list = ['Left', 'Right'];   
        function getRandom(max){
            return Math.floor(Math.random() * max)
        }
        const selection = selected === null? list[getRandom(2)] : selected;

        // console.log("Corect Choice ---> ", selection);

    }


}

const startChallenge = new Challenge

`

export default challenge;



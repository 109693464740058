import Bvd1 from "assets/blogs/Breadth_vs_Depth01.jpeg";
import Bvd2 from "assets/blogs/Breadth_vs_Depth02.jpeg";
import Bvd3 from "assets/blogs/Breadth_vs_Depth03.jpeg";
import Bvd4 from "assets/blogs/Breadth_vs_Depth04.jpeg";

import SaQ1 from "assets/blogs/Stacks_and_Queues01.jpeg";
import SaQ2 from "assets/blogs/Stacks_and_Queues02.jpeg";
import SaQ3 from "assets/blogs/Stacks_and_Queues03.jpeg";
import SaQ4 from "assets/blogs/Stacks_and_Queues04.jpeg";

import LaG1 from "assets/blogs/LinkedLists_and_Graphs01.jpeg";
import LaG2 from "assets/blogs/LinkedLists_and_Graphs02.jpeg";
import LaG3 from "assets/blogs/LinkedLists_and_Graphs03.jpeg";
import LaG4 from "assets/blogs/LinkedLists_and_Graphs04.jpeg";

import {BFS_vs_DFS, Stacks_and_Queues, LinkedList_and_Graphs} from "pages/MyBlogs/blogPosts/mock/content";
// import Stacks_and_Queues from "pages/MyBlogs/blogPosts/mock/content";
// import LinkedList_and_Graphs from "pages/MyBlogs/blogPosts/mock/content";


const blog = [
    {
        "active": true,
        "blog_status": "published",
        "category": ["Software Development"], 
        "content": BFS_vs_DFS,
        "date_created": "01/05/2024",
        "description": "When it comes to searching through data structures like trees and graphs, two primary techniques often come into play: Breadth-First Search (BFS) and Depth-First Search (DFS). Understanding the differences between these two methods is crucial for selecting the right approach based on the context of your problem. Let’s dive into what each technique entails, when to use them, and explore additional search strategies that might enhance your search capabilities.",
        "featured_image": Bvd1,
        "id": "01",
        "images": [Bvd1,Bvd2,Bvd3,Bvd4],
        "title": "Understanding Breadth vs. Depth in Search Techniques",

    },
    {
        "active": true,
        "blog_status": "published",
        "category": ["Software Development"], 
        "content": Stacks_and_Queues,
        "date_created": "12/14/2023",
        "description": "In the realm of computer science, data structures play a crucial role in organizing and managing data efficiently. Two fundamental data structures that often come up are stacks and queues. While they may seem similar at first glance, they serve distinct purposes and operate on different principles. In this article, we will explore the differences between stacks and queues, their use cases, and when to choose one over the other.",
        "featured_image": SaQ1,
        "id": "02",
        "images": [SaQ1,SaQ2,SaQ3,SaQ4],
        "title": "Understanding Stacks and Queues: Key Differences and Practical Applications",

    },
    {
        "active": true,
        "blog_status": "published",
        "category": ["Database Management"], 
        "content": LinkedList_and_Graphs,
        "date_created": "12/20/2023",
        "description": "Data structures are foundational concepts in computer science that dictate how data is organized, managed, and stored. This week, we explored various data structures, including linked lists and graphs. In this article, we'll delve into the key differences between these two structures and highlight specific scenarios where each would excel.",
        "featured_image": LaG1,
        "id": "03",
        "images": [LaG1,LaG2,LaG3,LaG4],
        "title": "Understanding Data Structures: Linked Lists and Graphs",

    },
]




export default blog;
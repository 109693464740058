const Index = `<!DOCTYPE html>
<html lang="eng">
<head>
    <meta charset="UTF-8">
    <meta http-equiv="X-UA-Compatible" content="IE=edge">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <script 
    src="https://kit.fontawesome.com/a6874c76aa.js" 
    crossorigin="anonymous"></script>
    <link rel="preconnect" href="https://fonts.googleapis.com">
    <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
    <link href="https://fonts.googleapis.com/css2?family=Roboto&display=swap" rel="stylesheet">
    <link href="https://fonts.googleapis.com/css2?family=Open+Sans&display=swap" rel="stylesheet">
    <link href="https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700;800&display=swap" rel="stylesheet">
    <link rel="stylesheet" href="./styles/basic.css">
    <link rel="stylesheet" href="./styles/navbar.css">
    <link rel="stylesheet" href="./styles/buttons.css">
    <link rel="stylesheet" href="./styles/landing-pg.css">
    <link rel="stylesheet" href="./styles/media-queries-1400.css">
    <link rel="stylesheet" href="./styles/media-queries-1290.css">
    <link rel="stylesheet" href="./styles/media-queries-1150.css">
    <link rel="stylesheet" href="./styles/media-queries-1090.css">
    <link rel="stylesheet" href="./styles/media-queries-975.css">
    <link rel="stylesheet" href="./styles/media-queries-mobile.css">
    <link rel="stylesheet" href="./styles/landing-mobile.css">

    <title>CodePen - Landing</title>
</head>

<body>
    <div class="container">
        <div class="nav-bar-container">
            <div class="logo-container">
                <img src="./images/logo_white.png" alt="Logo">
            </div>
            <div class="nav-bar-links-container">
                <div class="explore-link-container">
                    <a href="#">
                        <h3>EXPLORE</h3>
                    </a>
                </div>
                <div class="nav-links-container">
                    <div class="nav-link">
                        <a href="#">
                            <h3>Pens</h3>
                        </a>
                    </div>
                    <div class="nav-link">
                        <a href="#">
                            <h3>Projects</h3>
                        </a>
                    </div>
                    <div class="nav-link">
                        <a href="#">
                            <h3>Posts</h3>
                        </a>
                    </div>
                    <div class="nav-link">
                        <a href="#">
                            <h3>Collections</h3>
                        </a>
                    </div>

                    <div class="nav-dropdown">
                        <a href="#">
                        <h3>Spark</h3>
                        <i class="fa-solid fa-chevron-down"></i>
                    </a>
                    </div>
                </div>
            </div>
                <div class="nav-btns-container">
                    <div class="drop-dwn-btn-wrapper">
                        <button class="drop-dwn-btn" type="menu">
                            <h3>Create</h3>
                            <i class="fa-solid fa-chevron-down"></i>
                        </button>
                    </div>
                    <div class="icon-btn-wrapper">
                        <button class="icon-btn" type="button">
                            <i class="fa-solid fa-magnifying-glass"></i>
                        </button>
                    </div>
                    <div class="btn-wrapper">
                        <button class="btn" type="button">
                        <h3>Log In</h3>
                        </button>
                    </div>
                    <div class="btn-wrapper">
                        <button class="btn" type="button">
                        <h3>Sign Up</h3>
                        </button>
                    </div>
                </div>


        </div>
        <div class="landing-pg-container">
            <div class="two-column-container">
                <div class="info-content">
                    <div>
                        CodePen is a <span>social development environment</span> for front-end designers and developers. &#128075;
                    </div>
                    <div>
                        It's the best place to build and deploy a website, show off your work, build test cases, and find inspiration.
                    </div>
                    <div class="info-btn-wrapper">
                        <button class="btn" type="submit">Sign Up for Free</button>
                        <button class="btn" type="button">Learn More</button>
                    </div>
                </div>
                <div class="img-content">
                    <div class="spacer100"></div>
                    <div class="img-caption">
                        <p>This is <span>CodePen Projects</span>!</p>
                    </div>
                    <div class="img-container">
                        <img src="./images/landing-pg-img.png" alt="Landing-Page-Image">
                    </div>
                    
                </div>
            </div>
            <div class="links-ribbon">
                <div class="link-btn">
                    <a href="#">CodePen for <span>TEAMS</span></a>
                </div>
                <div class="link-btn">
                    <a href="#">CodePen for <span>EDUCATION</span></a>
                </div>
                <div class="link-btn">
                    <a href="#">CodePen for <span>PRIVACY</span></a>
                </div>
                <div class="link-btn">
                    <a href="#">CodePen for <span>WRITING</span></a>
                </div>
            </div>
            <div class="footer-spacer">

            </div>
        </div>
    </div>


</body>


</html>`

export default Index;
import IndexHtml from "static/html_calendar/index";
import Styles from "static/html_calendar/styles_css";

const routes = [
        {
            name: "HTML",
            collapse: [
              {
                name: "index.html",
                route: "/index.html",
                component: IndexHtml,
              },
             
            ],
        },
        {
            name: "CSS",
            collapse: [
              {
                name: "styles.css",
                route: "/styles.css",
                component: Styles,
              },
             
            ],
        },

        

]

export default routes;
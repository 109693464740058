const Chat = `document.addEventListener('DOMContentLoaded', function () {
    const chatIcon = document.querySelector('.chat-icon-btn-wrapper');
    const chatWindow = document.querySelector('.chat-window-wrapper');
    // const closeIcon = document.querySelector('.fa-xmark');
    const closeIcon = document.getElementById('chat-close-icon');

    chatIcon.addEventListener('click', function () {
        chatWindow.classList.toggle('active');
        chatIcon.style.display = chatWindow.classList.contains('active') ? 'none' : 'flex';
    });

    closeIcon.addEventListener('click', function () {
        chatWindow.classList.remove('active');
        chatIcon.style.display = 'flex';
    });
});
`

export default Chat
/* Helper styles */

const helper = `.spacer60 {
    height: 60px;
    width: 100%;
}

.spacer10 {
    height: 10px;
    width: 100%;
}
.anchor-link {
    text-decoration: none;
}

.anchor-link:focus {
    outline: none;
}

/* .anchor-link h1::before {
content: "I'm before - ";
} */

.anchor-link h1::after {
    font-family: "Font Awesome 6 Free";
    content: " \f0c1";
    font-weight: 900;
    }`

    export default helper;
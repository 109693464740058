const index = `<!DOCTYPE html>
<html lang="en">
<head>
    <meta charset="UTF-8">
    <meta http-equiv="X-UA-Compatible" content="IE=edge">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>🗓 Calendar</title>
    <!-- <link rel="icon" href="favicon.ico" type="image/x-icon"> -->
    <link rel="preconnect" href="https://fonts.googleapis.com">
    <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
    <link href="https://fonts.googleapis.com/css2?family=Architects+Daughter&family=Caveat:wght@400;500;600;700&family=Chakra+Petch:ital,wght@0,300;0,400;1,300;1,400&family=Cormorant+Infant:wght@300;400;500&family=Grandstander:ital,wght@0,100;0,200;0,300;0,400;1,100;1,200;1,300&family=Handlee&family=Oregano:ital@0;1&family=Rajdhani:wght@300;400;500;600;700&family=Stint+Ultra+Condensed&display=swap" rel="stylesheet">
    <script src="https://kit.fontawesome.com/e935d53bc9.js" crossorigin="anonymous"></script>
    <link rel="preconnect" href="https://fonts.googleapis.com">
    <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
    <link rel="stylesheet" href="styles.css">
</head>
<body class="" id="body">
    <div class="container">

        <div class="calendar-year-header-wrapper">
            <i class="fa-solid fa-chevron-left" id="prev_year_icon" onclick="on_left_year_click()"></i>
            <div class="year-wrapper" id="year_wrapper">2022</div>
            <i class="fa-solid fa-chevron-right" onclick="on_right_year_click()" ></i>
        </div>
        <div class="calendar-header-wrapper">
            <i class="fa-solid fa-chevron-left" id="prev_month_icon" onclick="on_left_month_click()"></i>
            <div class="month-name-wrapper" id="month_name_wrapper"></div>
            <i class="fa-solid fa-chevron-right" onclick="on_right_month_click()"></i>
        </div>
        <div class="week-days-wrapper" id="week_days_wrapper">
            <div class="week-day week-end">Sunday</div>
            <div class="week-day">Monday</div>
            <div class="week-day">Tuesday</div>
            <div class="week-day">Wednesday</div>
            <div class="week-day">Thursday</div>
            <div class="week-day">Friday</div>
            <div class="week-day week-end">Saturday</div>
        </div>

        <div class="month-wrapper" id="month_wrapper"></div>

        <div class="day-schedual-wrapper" id="day_schedual_wrapper">
            <div class="schedual-header">
                <i class="fa-solid fa-xmark" onclick="on_day_click()"></i>
            </div>
            <div class="view-schedual-view">
                Nothing on agenda
            </div>
        </div>

        <div class="spacer25"></div>
        <div class="theme-icon-wrapper">
            <i class="fa-solid fa-circle-half-stroke" onclick="on_theme_icon_click()"></i>
        </div>
    </div>

</body>
<script>   
    const selected_month_index = 0
    const week_days_long = [
        "Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"
    ];
    const week_days_short = [
        "Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"
    ];

    var week_days = week_days_short

    const width = window.innerWidth;
        if(width > 740){
            week_days = week_days_long
        }else{
            week_days = week_days_short
        }
        let week_div = ''
        week_days.forEach(day => {
            let use_class = day.substring(0,1)==="S"?"week-day":"week-day week-end"; 
            let day_div = <div class={use_class}>{day}</div>;
            week_div = week_div + day_div
        })
        let week_wrapper = document.getElementById("week_days_wrapper");
        week_wrapper.innerHTML = week_div

    window.addEventListener("resize", (e) => {
        const width = window.innerWidth;
        // console.log("RESIZE EVENT!  WIDTH ---> ", width)
        if(width > 740){
            week_days = week_days_long
        }else{
            week_days = week_days_short
        }
        let week_div = ''
        week_days.forEach(day => {
            let use_class = day.substring(0,1)==="S"?"week-day":"week-day week-end"; 
            let day_div = <div class={use_class}>{day}</div>;
            week_div = week_div + day_div
        })
        // console.log("WEEK DIV ---> ", week_div);
        let week_wrapper = document.getElementById("week_days_wrapper");
        week_wrapper.innerHTML = week_div
    })

    const year_2022 = [
        {
            days:31,
            name: "Janurary",
            starts: "Saturday",
            start_index: 6,
            prev_end: 31,
            notes: [
                {
                    name: "New Years Day",
                    day: 1,
                },
                {
                    name: "MLK Day",
                    day: 17,
                },
            ]
        },
        {
            days:28,
            name: "Feburary",
            starts: "Tuesday",
            start_index: 2,
            prev_end: 31,
            notes: [
                {
                    name: "Fisrt Day of Black History Month",
                    day: 1,
                },
                {
                    name: "Valentine's Day",
                    day: 14,
                },
            ]
        },
        {
            days:31,
            name: "March",
            starts: "Tuesday",
            start_index: 2,
            prev_end: 28,
            notes: [
                {
                    name: "Birthday!",
                    day: 1,
                },
                {
                    name: "Daylight Saving Time Starts",
                    day: 13,
                },
                {
                    name: "St Patrick's Day",
                    day: 17,
                },
            ]
        },
        {
            days:30,
            name: "April",
            starts: "Friday",
            start_index: 5,
            prev_end: 31,
            notes: [
                {
                    name: "Tax Day",
                    day: 18,
                },
            ]
        },
        {
            days:31,
            name: "May",
            starts: "Sunday",
            start_index: 0,
            prev_end: 30,
            notes: [
                {
                    name: "Cinco de Mayo",
                    day: 5,
                },
                {
                    name: "Mother's Day",
                    day: 8,
                },
                {
                    name: "Memorial Day",
                    day: 30,
                },
            ]
        },
        {
            days:30,
            name: "June",
            starts: "Wednesday",
            start_index: 3,
            prev_end: 31,
            notes: [
                {
                    name: "Father's Day",
                    day: 19,
                },
            ]
        },
        {
            days:31,
            name: "July",
            starts: "Friday",
            start_index: 5,
            prev_end: 30,
            notes: [
                {
                    name: "USA Independance Day",
                    day: 4,
                },
            ]
        },
        {
            days:31,
            name: "August",
            starts: "Monday",
            start_index: 1,
            prev_end: 31,
            notes: [
                {
                    name: "JA Emancipation Day",
                    day: 1,
                },
                {
                    name: "JA Independance Day",
                    day: 6,
                },
            ]
        },
        {
            days:30,
            name: "September",
            starts: "Thursday",
            start_index: 4,
            prev_end: 31,
            notes: [
                {
                    name: "Labor Day",
                    day: 5,
                },
            ]
        },
        {
            days:31,
            name: "October",
            starts: "Saturday",
            start_index: 6,
            prev_end: 30,
            notes: [
                {
                    name: "Nigeria Independance Day",
                    day: 1,
                },
                {
                    name: "Indigenous People Day",
                    day: 10,
                },
                {
                    name: "Holloween",
                    day: 31,
                },
            ]
        },
        {
            days:30,
            name: "November",
            starts: "Tuesday",
            start_index: 2,
            prev_end: 31,
            notes: [
                {
                    name: "Daylight Saving Time End",
                    day: 6,
                },
                {
                    name: "Election Day",
                    day: 8,
                },
                {
                    name: "Veterans Day",
                    day: 11,
                },
                {
                    name: "Thanksgiving Day",
                    day: 24,
                }
            ]
        },
        {
            days:31,
            name: "December",
            starts: "Thursday",
            start_index: 4,
            prev_end: 30,
            notes: [
                {
                    name: "Christmas Eve Day",
                    day: 24,
                },
                {
                    name: "Christmas Day",
                    day: 25,
                },
            ]
        },
    ]

    const year_count = year_2022.length

    var last_year_obj = [];
    

    const today = new Date()
    console.log("TODAY's DATE : ", today)
    const year = today.getUTCFullYear()
    console.log("THIS YEAR ---> ", year);
    const this_year = loadYear(year)
    const isLeap = getLeap(year);
    // console.log("IS LEAP ---> ", isLeap);
    const current_month_index = today.getMonth()
    // console.log("Current Month Index : ", current_month_index)
    const todays_date_num = today.getDate()
    // const todays_date_num = 10
    // console.log("TODAY's DATE Num : ", todays_date_num)


    function loadYear(year){
        let new_year = [];
        let baseYear = year_2022.copyWithin(0,12)

        let last_known_year = 2022;
        let difference = year - last_known_year;

        let isLeap = getLeap(year);

        let leap_days_float = Number.parseFloat((year-2020) / 4);
        let split_days = ({leap_days_float}).split('.');
        let leap_days = split_days[0]

        let days_past = difference+Number(leap_days);
        // console.log("TOP SIDE PAST DAYS ===> ", days_past)


        baseYear.forEach(month => {
            let days = month.days
            let name = month.name
            let starts = month.starts
            let start_index = month.start_index
            let prev_end = month.prev_end
            let notes = month.notes
            

            if(isLeap){
                // console.log("YEAR _> ", year, " - LEAP --> ", isLeap)
                if(name === "Janurary" || name === "Feburary"){
                    var past_days = days_past - 1;
                }else{
                    var past_days = days_past;                    
                }
            }else{
                    var past_days = days_past;   
                }

                // console.log("YEAR -> ", year, "Month --> ", name, " --- days PAST ??? --> ", past_days)
            let _month = month    
            // console.log("LOAD MONTH TO TRANSFORM -------> ", _month)
            let new_start_index = loadMonthStartIndex(_month, past_days);
            let new_starts_day = week_days[new_start_index];

            if(isLeap){
                if(name === "Feburary"){
                    _month.days = 29;
                }
                if(name === "March"){
                    _month.prev_end = 29;
                }
            }

            _month.start_index = new_start_index;
            _month.starts = new_starts_day;

            if(name === "Janurary"){
                let mlk = {
                    name: "MLK Day",
                    day: findMKL(_month),
                }

                function findNote(Notes){
                    return Notes.name === "MLK Day"
                }
                let prevMLK = notes.find(findNote)
                // console.log("LAST MLK ====> ", prevMLK)
                let noteIndex = notes.indexOf(prevMLK)
                // console.log("DEX OF MLK ---> ", noteIndex)
                let newNotes = _month.notes.splice(noteIndex,1,mlk)
                // _month.notes = newNotes

                // console.log("SHOW MONTH ---> ", _month)
            }

            new_year.push(_month)
        })

        return new_year;

    }

    function loadMonthStartIndex(month, days_past){
            // console.log("## LOAD MONTH TO TRANSFORM -------> ", month)
            let start_day = month.start_index+1;
            // console.log("LOAD MONTH START DAY  ===> ", start_day)
            let total_days = (start_day + days_past);
            if(total_days < 0){
                total_days = 7 + total_days
            }
            let day_float = Number.parseFloat(total_days/7)
            // console.log("FLOAT DAYS ---> ", day_float);
            let split_float = ({day_float}).split('.');
            if(split_float.length === 1){
                var day_num = 7
            }else{
                let fraction_of_week = Number(0.{split_float[1]})
                // console.log("FRAC OF WEEK ??? ", fraction_of_week);
                var day_num = Number(fraction_of_week / (1/7)).toFixed(0)
            }

            // console.log("***** DAY NUMBER ------ > ", day_num)
            // console.log("START DAY --- > ", week_days[day_num-1])

            return day_num-1

    }

    function findMKL(month){
        let start_day = month.start_index+1;

        if(start_day === 1){
            var first_mon = 2
        }else{
            if(start_day === 2){
                var first_mon = 1
            }else{
                if(start_day > 2){
                    let week_difference = 7 - start_day;
                    let rest_of_week = 1 + week_difference;
                    var first_mon = rest_of_week+2
                }
            }
        }
        // console.log("MONTH :" , month);
        // console.log("Start DAY ???" , start_day);
        // console.log("FRST MON DAY ???" , first_mon);
        // console.log("MLK DAY ???" , first_mon+14);
        return first_mon+14;
    }    

    
    function loadNextYear(year, current_year){
        let new_year = [];
        let baseYear = year_2022.copyWithin(0,12)

        let last_known_year = current_year;
        let difference = year - last_known_year;

        let isLeap = getLeap(year);

        let _leap_days_float = Number.parseFloat((year-2020) / 4);

        let lastKnownLeap = 2020 + (Number.parseInt(_leap_days_float)*4)
        // console.log("LAST KNOWN LEAP YEAR ----- > ", lastKnownLeap);

        let leap_days_float = Number.parseFloat((year-lastKnownLeap) / 4);

        let leap_days = year-lastKnownLeap===1?1:0


        let days_past = difference+leap_days;
        // console.log("TOP SIDE DIFFERENCE ===> ", difference)
        // console.log("TOP SIDE LEAP DAYS ===> ", leap_days)
        // console.log("TOP SIDE PAST DAYS ===> ", days_past)


        year_2022.forEach(month => {
            let days = month.days
            let name = month.name
            let starts = month.starts
            let start_index = month.start_index
            let prev_end = month.prev_end
            let notes = month.notes

            if(isLeap){
                // console.log("YEAR _> ", year, " - LEAP --> ", isLeap)
                if(name === "Janurary" || name === "Feburary"){
                    var past_days = days_past;
                }else{
                    var past_days = days_past + 1;                    
                }
            }else{
                if(name !== "Janurary" && name !== "Feburary"){
                    var past_days = year-lastKnownLeap===1?days_past - 1:days_past;
                }else{
                    var past_days = days_past;   
                }
                }

                // console.log("YEAR -> ", year, "Month --> ", name, " --- days PAST ??? --> ", past_days)
            let _month = month    
            // console.log("LOAD MONTH TO TRANSFORM -------> ", _month)
            let new_start_index = loadMonthStartIndex(_month, past_days);
            let new_starts_day = week_days[new_start_index];

            if(isLeap){
                if(name === "Feburary"){
                    _month.days = 29;
                }
                if(name === "March"){
                    _month.prev_end = 29;
                }
            }else{
                if(name === "Feburary"){
                    _month.days = 28;
                }
                if(name === "March"){
                    _month.prev_end = 28;
                }
            }

            _month.start_index = new_start_index;
            _month.starts = new_starts_day;


            if(name === "Janurary"){
                let mlk = {
                    name: "MLK Day",
                    day: findMKL(_month),
                }

                function findNote(Notes){
                    return Notes.name === "MLK Day"
                }
                let prevMLK = notes.find(findNote)
                // console.log("LAST MLK ====> ", prevMLK)
                let noteIndex = notes.indexOf(prevMLK)
                // console.log("DEX OF MLK ---> ", noteIndex)
                let newNotes = _month.notes.splice(noteIndex,1,mlk)
                // _month.notes = newNotes

                // console.log("SHOW MONTH ---> ", _month)
            }

            new_year.push(_month)
        })

        return new_year;

    }


    function loadLastYear(year, current_year){
        let new_year = [];

        let last_known_year = current_year;
        let difference = year - last_known_year;

        let isLeap = getLeap(year);

        let _leap_days_float = Number.parseFloat((year-2020) / 4);

        let lastKnownLeap = 2020 + (Number.parseInt(_leap_days_float)*4)
        console.log("LAST KNOWN LEAP YEAR ----- > ", lastKnownLeap);
        console.log("CURRENT YEAR ??  ----- > ", year);
        console.log("COMING FROM YEAR ??  ----- > ", last_known_year);

        let leap_days_float = Number.parseFloat((year-lastKnownLeap) / 4);

        let leap_days = lastKnownLeap+4===Number(last_known_year)?-1:0


        let days_past = difference+leap_days;
        console.log("TOP SIDE DIFFERENCE ===> ", difference)
        console.log("TOP SIDE LEAP DAYS ===> ", leap_days)
        console.log("TOP SIDE PAST DAYS ===> ", days_past)


        year_2022.forEach(month => {
            let days = month.days
            let name = month.name
            let starts = month.starts
            let start_index = month.start_index
            let prev_end = month.prev_end
            let notes = month.notes

            if(isLeap){
                // console.log("YEAR _> ", year, " - LEAP --> ", isLeap)
                if(name === "Janurary" || name === "Feburary"){
                    var past_days = -2;
                }else{
                    var past_days = -1 ;                    
                }
            }
            if(!isLeap){
                if(name === "Janurary" || name === "Feburary"){
                    var past_days = -1;  
                    
                }else{ 
                    var past_days = lastKnownLeap+4===Number(current_year)?-2:-1;
                    console.log("!!!!!!! NOT LEAP..... PAST DAYS ---- > ", past_days)
                }
            }

                // console.log("YEAR -> ", year, "Month --> ", name, " --- days PAST ??? --> ", past_days)
            let _month = month    
            // console.log("LOAD MONTH TO TRANSFORM -------> ", _month)
            let new_start_index = loadMonthStartIndex(_month, past_days);
            let new_starts_day = week_days[new_start_index];

            if(isLeap){
                if(name === "Feburary"){
                    _month.days = 29;
                }
                if(name === "March"){
                    _month.prev_end = 29;
                }
            }else{
                if(name === "Feburary"){
                    _month.days = 28;
                }
                if(name === "March"){
                    _month.prev_end = 28;
                }
            }

            _month.start_index = new_start_index;
            _month.starts = new_starts_day;


            if(name === "Janurary"){
                let mlk = {
                    name: "MLK Day",
                    day: findMKL(_month),
                }

                function findNote(Notes){
                    return Notes.name === "MLK Day"
                }
                let prevMLK = notes.find(findNote)
                // console.log("LAST MLK ====> ", prevMLK)
                let noteIndex = notes.indexOf(prevMLK)
                // console.log("DEX OF MLK ---> ", noteIndex)
                let newNotes = _month.notes.splice(noteIndex,1,mlk)

                // console.log("SHOW MONTH ---> ", _month)
            }

            new_year.push(_month)
        })

        return new_year;

    }


    function getLeap(year){
        let last_known_leap = 2020;
        let difference = year - last_known_leap;
        if(difference >= 4){
            let split_difference = ({difference/4}).split('.');
            // console.log("SPLIT DIFFERENCE  --> ", split_difference);
            let split_lenth = split_difference.length;
            if(split_lenth > 1){
                var leap = false;
            }else{
                var leap = true
            }
        }else{
            var leap = false
        }
        return leap
    }

    function loadMonth(month_obj=this_year[current_month_index]){
        const month_name = <p>{month_obj.name}</p>
        document.getElementById("month_name_wrapper").innerHTML = month_name
    }

    function returnMonthName(){
        var month_name_ele = document.getElementById("month_name_wrapper").innerHTML
        // console.log("MONTH NAME ELEMENT: ", month_name_ele)
        var element_array = month_name_ele.split('>')
        var element_array2 = element_array[1]
        var name_array = element_array2.split('<')
        var monthName = name_array[0]
        // console.log("MONTH NAME DISPLAY : ", monthName)
        return(monthName)
    }

    function get_month_index(month_obj){
        var current_name = returnMonthName()
        var month_name = month_obj.name;
        return current_name === month_name;
    }

    async function on_left_month_click(){
        const month_index = this_year.indexOf(this_year.find(get_month_index))
        // console.log("MONTH INDEX : ", month_index)
        if(month_index === 0){
            var next_indx = year_count-1
            // console.log("Next Index : ", next_indx)
            // loadMonth(this_year[next_indx])
        }else{
            var next_indx = month_index-1
            // console.log("Next Index : ", next_indx)
        }

            loadMonth(this_year[next_indx])
            document.getElementById("month_wrapper").innerHTML = monthWeeksWrapper(this_year[next_indx])
    }

    async function on_right_month_click(){
        const month_index = this_year.indexOf(this_year.find(get_month_index))
        // console.log("MONTH INDEX : ", month_index)
        if(month_index === year_count-1){
            var next_indx = 0
            // console.log("Next Index : ", next_indx)
            // loadMonth(this_year[next_indx])
        }else{
            var next_indx = month_index+1
            // console.log("Next Index : ", next_indx)
        }

            loadMonth(this_year[next_indx])
            document.getElementById("month_wrapper").innerHTML = monthWeeksWrapper(this_year[next_indx])
    }


    function on_theme_icon_click(){
        var element = document.getElementById('body');
        if(element.className === ''){
            element.className = 'body-class'
        }else{
            element.className = ''
        }

    }

    function on_day_click(){
        var element = document.getElementById('day_schedual_wrapper');
        if(element.className === 'day-schedual-wrapper'){
            var body_element = document.getElementById('body');
            // element.className = 'day-schedual-wrapper-show'
            console.log("BODY ELEMENT ====> ", body_element)
            if(body_element.className === 'body-class'){
                element.className = 'day-schedual-wrapper-show-dark'
            }else{
                element.className = 'day-schedual-wrapper-show'
            }
        }else{
            element.className = 'day-schedual-wrapper'
        }
    }

    async function on_left_year_click() {
        let current_year = document.getElementById("year_wrapper").innerHTML
        if(current_year === "2022"){
            alert("WASN'T MADE TO GO LESS THAN 2022")
        }else{
        let year_num = Number(current_year) - 1;
         document.getElementById("year_wrapper").innerHTML = year_num;
         let load_year = loadLastYear(year_num, current_year)
         let month_index = load_year.indexOf(load_year.find(get_month_index))
        //  console.log("NEXT YEAR CURRENT MONTH INDEX ****> ", load_year[month_index])
         loadMonth(load_year[month_index])
         document.getElementById("month_wrapper").innerHTML = monthWeeksWrapper(load_year[month_index]);
        }        
    }

    async function on_right_year_click() {
        let current_year = document.getElementById("year_wrapper").innerHTML
        let year_num = Number(current_year) + 1;
         document.getElementById("year_wrapper").innerHTML = year_num;
        //  console.log("LOADING THIS YEAR --------> ", year_num);
        //  console.log("LOADING PREVIOUS YEAR? --------> ", current_year);
         
         let load_year = loadNextYear(year_num, current_year)
         let month_index = load_year.indexOf(load_year.find(get_month_index))
        //  console.log("NEXT YEAR CURRENT MONTH INDEX ****> ", load_year[month_index])
         document.getElementById("month_wrapper").innerHTML = monthWeeksWrapper(load_year[month_index]);   
         loadMonth(load_year[month_index]);    
    }


    function monthWeeksWrapper(month_obj) {
        // console.log("MONTH SOW OBJS : ---- ", month_obj)
        const week_days = [1,2,3,4,5,6,7]
        const month_weeks = [1,2,3,4,5,6]
        var month_wrapper = ''
        var week_wrapper = ''
        var day = 1;
        var days = month_obj.days;
        var start = month_obj.start_index+1;
        var prev_end = month_obj.prev_end;
        var screen_days = 7 * 6
        var next_begin = screen_days - (screen_days - (days + start))
        // console.log("SCREEN DAYS : ", screen_days, " _ Next Begin: ", next_begin)
        var month_array = []
        var month_index = this_year.indexOf(month_obj)
        // console.log("SELECTED MONTH INDEX : ", month_index)
        var month_notes = month_obj.notes;
        while(day <= screen_days){
            if(month_index === current_month_index && day-(start-1) === todays_date_num){
                var todays_class = "todays-date"
                // console.log("WHILE DAY : ", day, " MONTH : ", month_index)
            }else{
                var todays_class = ''
            }
            function findNote(_noteObj){
                note_day = _noteObj.day
                return note_day === day-(start-1)                
            }    
            var getNotes = month_notes.find(findNote)
            if(getNotes !== undefined && getNotes !== null && getNotes !== -1){
                // console.log("NOTE FOUND ***** : ", getNotes)
                var day_note = getNotes.name
            }else{
                var day_note = ""
            }

            const m7 = Number(day) % 7

            if(day < start){
                const use_day = (prev_end - (start-1)) + day
                if(day === 1 || m7-1 === 0){
                    // console.log("DAY: ", day, "- M7 _Week Start - ", m7)
                    var month_day_obj = {
                        html:
                        <div class="month-week-wrapper" id="month_week_wrapper">
                            <div class="month-day-wrapper month_over-flow"  onclick="on_day_click()">
                                <div class="day-num-wrapper">{use_day}</div>
                                <div class="day-notes-wrapper"></div>
                            </div>
                    }
                    month_array.push(month_day_obj)
                }else{
                    if(m7 === 0){
                    // console.log("DAY: ", day, "- M7 _Week End - ", m7)
                        var month_day_obj = {
                            html:
                                <div class="month-day-wrapper month_over-flow" onclick="on_day_click()">
                                    <div class="day-num-wrapper">{use_day}</div>
                                    <div class="day-notes-wrapper"></div>
                                </div>
                            </div>
                        }
                        month_array.push(month_day_obj)
                    }else{
                    var month_day_obj = {
                        html:
                            <div class="month-day-wrapper month_over-flow" onclick="on_day_click()">
                                <div class="day-num-wrapper">{use_day}</div>
                                <div class="day-notes-wrapper"></div>
                            </div>
                    }
                    month_array.push(month_day_obj)
                }
                    }


            }else{


                if(day >= start && day >= next_begin){
                    const use_day = (day - next_begin) + 1
                    if(day === 1 || m7-1 === 0){
                        // console.log("DAY: ", day, "- M7 _Week Start - ", m7)
                        var month_day_obj = {
                            html:
                            <div class="month-week-wrapper" id="month_week_wrapper">
                                <div class="month-day-wrapper month_over-flow" onclick="on_day_click()">
                                    <div class="day-num-wrapper">{use_day}</div>
                                    <div class="day-notes-wrapper"></div>
                                </div>
                        }
                        month_array.push(month_day_obj)
                    }else{
                        if(m7 === 0){
                            // console.log("DAY: ", day, "- M7 _Week End - ", m7)
                            var month_day_obj = {
                                html:
                                    <div class="month-day-wrapper month_over-flow" onclick="on_day_click()">
                                        <div class="day-num-wrapper">{use_day}</div>
                                        <div class="day-notes-wrapper"></div>
                                    </div>
                                </div>
                            }
                            month_array.push(month_day_obj)
                        }else{
                        var month_day_obj = {
                            html:
                                <div class="month-day-wrapper month_over-flow" onclick="on_day_click()">
                                    <div class="day-num-wrapper">{use_day}</div>
                                    <div class="day-notes-wrapper"></div>
                                </div>
                        }
                        month_array.push(month_day_obj)
                    }
                        }
            }else{ 
                
                    if(day >= start && day < next_begin){
                        const use_day = (day - start) + 1
                        if(day === 1 || m7-1 === 0){
                            // console.log("DAY: ", day, "- M7 _Week Start - ", m7)
                            var month_day_obj = {
                                html:
                                <div class="month-week-wrapper" id="month_week_wrapper">
                                    <div class="month-day-wrapper" onclick="on_day_click()">
                                        <div class="day-num-wrapper {todays_class}">{use_day}</div>
                                        <div class="day-notes-wrapper">{day_note}</div>
                                    </div>
                            }
                            month_array.push(month_day_obj)
                        }else{
                            if(m7 === 0){
                                // console.log("DAY: ", day, "- M7 _Week End - ", m7)
                                var month_day_obj = {
                                    html:
                                        <div class="month-day-wrapper" onclick="on_day_click()">
                                            <div class="day-num-wrapper {todays_class}">{use_day}</div>
                                            <div class="day-notes-wrapper">{day_note}</div>
                                        </div>
                                    </div>
                                }
                                month_array.push(month_day_obj)
                            }else{
                            var month_day_obj = {
                                html:
                                    <div class="month-day-wrapper" onclick="on_day_click()">
                                        <div class="day-num-wrapper {todays_class}">{use_day}</div>
                                        <div class="day-notes-wrapper">{day_note}</div>
                                    </div>
                            }
                            month_array.push(month_day_obj)
                        }
                            }
            }
        }


            }


            day++;
        }

        const month_display_count = month_array.length
        // console.log("MONTH DISPLAY DAYS COUNT : ", month_display_count)

        if(month_display_count === screen_days){
            month_array.forEach(dayObj => {
                const html_string = dayObj.html
                month_wrapper = month_wrapper + "\n" + html_string
            })
        }

        return(month_wrapper)

    }

    document.getElementById("month_wrapper").innerHTML = monthWeeksWrapper(this_year[current_month_index])
    document.getElementById("year_wrapper").innerHTML = year
    loadMonth()

</script>
</html>

`
export default index;